import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Card, Row, Col, Space, Button, Spin, Table, Input, Tooltip, Modal, Form, InputNumber, Typography } from 'antd'
import {
    UserAddOutlined,
    DownloadOutlined,
    SearchOutlined,
    ReloadOutlined,
    DeleteOutlined,
    HistoryOutlined
} from '@ant-design/icons'
import _ from 'lodash'
import * as services from 'services'
import { filterTableV2 } from '@/search'
import { usePermission } from 'hooks'
import Logs from 'components/Logs'
import ExportLayout from 'components/exports'
import FormLayout from 'components/FormLayout'

export default function BlackList() {
    const product = 'hotel'

    const resource = 'blacklist'

    const [loading, setLoading] = useState(false)
    const [query, setQuery] = useState('')
    const [blackList, setBlackList] = useState([])
    const [formState, setFormState] = useState({ action: 'hide' })
    const [selectedHotel, setSelectedHotel] = useState(null)
    const [selectedHotels, setSelectedHotels] = useState([])
    const [isOpenExport, setIsOpenExport] = useState({ open: false })

    const { canCreate, canRemove, canExport } = usePermission({ resource })

    const onDebounceChangeQuery = _.debounce(value => setQuery(value), 200)

    const filterHotels = useMemo(() => filterTableV2(query, blackList), ['hotel_id'], [])

    const onRemove = useCallback(hotelIds => setBlackList(prev => prev.filter(h => !hotelIds.includes(h.hotel_id))), [])

    const onFetch = useCallback(
        async () =>
            services.hotel
                .getHotels({
                    product,
                    resource
                })
                .then(blackList => setBlackList(blackList)),
        [resource]
    )

    const onDelete = useCallback(async () => {
        const hotelIds = selectedHotels.map(h => h.hotel_id)

        return services.hotel
            .remove({
                resource,
                data: {
                    hotelIds
                }
            })
            .then(() => onRemove(hotelIds))
    }, [resource])

    const onFetchLog = useCallback(
        async () =>
            services.logs.getLogs({
                product,
                resource,
                objectId: selectedHotel.hotel_id
            }),
        [product, resource]
    )

    const onSave = useCallback(
        async data =>
            services.hotel.add({
                product,
                resource,
                data
            })[(product, resource)]
    )

    const handlers = useMemo(
        () => ({
            load: onFetch,
            delete: onDelete
        }),
        [onFetch, onRemove]
    )

    const dispatchEvent = useCallback(
        (event, record) => {
            setLoading(true)

            const promise = handlers[event](record).finally(() => setLoading(false))

            return promise
        },
        [handlers]
    )

    const onReload = () => {
        dispatchEvent('load')
    }

    const onOpenCreateForm = () => {
        setFormState({ action: 'create' })
    }

    const onHideForm = () => {
        setFormState({ action: 'hide' })
    }

    const onOpenExport = type => {
        setIsOpenExport({ open: true, type })
    }

    const onCloseExport = () => {
        setIsOpenExport({ open: false })
    }

    const onChangeSelectedHotels = hotels => {
        setSelectedHotels(hotels)
    }

    const onClearSelectedHotel = () => {
        setSelectedHotel(null)
    }

    const onShowDeleteConfirm = record => {
        Modal.confirm({
            maskClosable: true,
            title: `Are you sure to remove hotel ${record.hotel_id}`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                dispatchEvent('delete', record)
            }
        })
    }

    const columns = [
        {
            key: 'hotel_id',
            title: 'Hotel Id',
            dataIndex: 'hotel_id'
        },
        {
            key: 'block_reason',
            title: 'Block Reason',
            dataIndex: 'block_reason'
        },
        {
            key: 'action',
            title: 'Action',
            render: (_, record) => (
                <Row gutter={[8, 8]}>
                    <Col sm={24} md={12}>
                        <Tooltip title="Remove">
                            <Button
                                ghost
                                type="primary"
                                icon={<DeleteOutlined />}
                                disabled={!canRemove}
                                onClick={() => onShowDeleteConfirm(record)}
                            />
                        </Tooltip>
                    </Col>
                    <Col sm={24} md={12}>
                        <Tooltip title="Show Log">
                            <Button type="dashed" icon={<HistoryOutlined />} onClick={() => setSelectedHotel(record)} />
                        </Tooltip>
                    </Col>
                </Row>
            )
        }
    ]

    useEffect(() => {
        dispatchEvent('load')
    }, [dispatchEvent])

    return (
        <React.Fragment>
            <Card title={<Typography.Title level={3}>Blocked Hotels</Typography.Title>}>
                <Row>
                    <Col sm={18}>
                        <Space>
                            <Button
                                disabled={!canCreate}
                                type="primary"
                                icon={<UserAddOutlined />}
                                onClick={onOpenCreateForm}>
                                Create
                            </Button>
                            <Button
                                disabled={!canExport}
                                type="info"
                                icon={<DownloadOutlined />}
                                onClick={() => onOpenExport('SELECTED')}>
                                Export (<span>{selectedHotels.length}</span>)
                            </Button>
                            <Button
                                disabled={!canExport}
                                type="info"
                                icon={<DownloadOutlined />}
                                onClick={() => onOpenExport('ALL')}>
                                Export All
                            </Button>
                        </Space>
                    </Col>
                    <Col sm={6}>
                        <div className="w-100 d-flex justify-content-between align-items-center">
                            <Input
                                className="mx-2"
                                allowClear
                                size="middle"
                                placeholder="Search"
                                prefix={<SearchOutlined />}
                                onChange={e => onDebounceChangeQuery(e.target.value)}
                            />
                            <Tooltip title="Reload">
                                <Button disabled={loading}>
                                    <ReloadOutlined role="button" onClick={onReload} />
                                </Button>
                            </Tooltip>
                        </div>
                    </Col>
                </Row>
                <br />
                <Spin spinning={loading}>
                    <Table
                        bordered
                        rowKey={r => r.hotel_id}
                        columns={columns}
                        dataSource={filterHotels}
                        rowSelection={{
                            onChange: (_, rows) => onChangeSelectedHotels(rows)
                        }}
                    />
                </Spin>
            </Card>

            {!!selectedHotel && (
                <Modal
                    width={840}
                    title={`Logs of block hotel: ${selectedHotel.hotel_id}`}
                    open={!!selectedHotel}
                    onCancel={onClearSelectedHotel}>
                    <Logs onFetchLog={onFetchLog} />
                </Modal>
            )}

            {canExport && (
                <ExportLayout
                    resource={resource}
                    data={isOpenExport.type === 'ALL' ? selectedHotel : selectedHotels}
                    isOpenExport={isOpenExport.open}
                    onCloseExport={onCloseExport}
                />
            )}

            <BlacklistForm formState={formState} onHideForm={onHideForm} onSave={onSave} onReload={onReload} />
        </React.Fragment>
    )
}

function BlacklistForm({ formState, onHideForm, onSave, onReload }) {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        form.resetFields()

        if (formState.action === 'edit') {
            const fields = ['hotel_id', 'block_reason']
            const values = _.pick(formState.payload, fields)
            form.setFieldsValue(values)
        }
    }, [formState])

    const onSubmit = () => {
        form.validateFields().then(fieldsValue => {
            setLoading(true)

            onSave(fieldsValue)
                .then(() => {
                    setLoading(false)
                    onHideForm()
                    onReload()
                })
                .catch(() => {
                    setLoading(false)
                })
        })
    }

    return (
        <FormLayout
            type="blacklist"
            action={formState.action}
            loading={loading}
            onCloseForm={onHideForm}
            onSubmit={onSubmit}>
            <Form form={form} colon={false} layout="vertical">
                <Form.Item
                    label="Hotel Id"
                    name="hotel_id"
                    validateTrigger="onBlur"
                    hasFeedback
                    required
                    rules={[
                        {
                            required: true,
                            message: 'Hotel id is required'
                        }
                    ]}>
                    <InputNumber />
                </Form.Item>

                <Form.Item
                    label="Block Reason"
                    name="block_reason"
                    validateTrigger="onBlur"
                    hasFeedback
                    required
                    rules={[
                        {
                            required: true,
                            message: 'Block reason is required'
                        }
                    ]}>
                    <Input />
                </Form.Item>
            </Form>
        </FormLayout>
    )
}
