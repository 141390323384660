import React from 'react'
import { List, Button, Tooltip, Typography } from 'antd'
import { DeleteOutlined, HighlightOutlined, LockOutlined } from '@ant-design/icons'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

export default function BookingColumn({ column, onRemoveColumn, onChangeColumnTitle }) {
    const { attributes, listeners, transform, transition, isDragging, setNodeRef } = useSortable({
        id: column.index
    })

    const onDelete = e => {
        e.stopPropagation()
        onRemoveColumn(column.id)
    }

    return (
        <List.Item
            ref={setNodeRef}
            {...attributes}
            {...listeners}
            style={{
                transition,
                transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
                cursor: 'move',
                ...(!isDragging
                    ? {}
                    : {
                          position: 'relative',
                          zIndex: 999
                      })
            }}>
            <List.Item.Meta
                avatar={column.required && <LockOutlined role="button" className="fs-5 text-warning" />}
                title={
                    <Typography.Paragraph
                        editable={{
                            icon: <HighlightOutlined role="button" />,
                            triggerType: 'icon',
                            tooltip: 'Click to edit text',
                            onChange: title => onChangeColumnTitle(column.id, title)
                        }}>
                        {column.title}
                    </Typography.Paragraph>
                }
            />
            <Tooltip key="__delete__" title="Delete">
                <Button
                    className="rounded"
                    size="small"
                    disabled={column.required}
                    type="danger"
                    icon={<DeleteOutlined />}
                    onClick={onDelete}
                />
            </Tooltip>
        </List.Item>
    )
}
