import { getAuth } from '../../@misc'
import { createAction } from '@'
import axios from 'axios'
import querystring from 'querystring'
import { $hotelRegionInfo } from '../stores'
import appConfig from '../../config'
const apiUrl = `${appConfig.apiUrl}`

export default createAction('GetRegionInfo', async regions => {
    const auth = getAuth()
    const hotelInfoMapping = $hotelRegionInfo.getState()

    await Promise.all(
        regions.map(async regionId => {
            const key = `${regionId}`

            if (hotelInfoMapping && hotelInfoMapping[key]) {
                return
            }

            const qs = {
                region_id: regionId
            }

            try {
                const { data } = await axios({
                    headers: { 'Content-Type': 'application/json', ...auth },
                    url: `${apiUrl}/hotel/mappings/region-check?${querystring.stringify(qs)}`,
                    method: 'GET'
                })
                const regionData = (data && data[0]) || {
                    region_id: regionId,
                    region_name: '',
                    country_code: ''
                }
                if (regionData) {
                    $hotelRegionInfo.setState(s => ({
                        ...s,
                        [key]: regionData
                    }))
                }
            } catch (e) {
                $hotelRegionInfo.setState(s => ({
                    ...s,
                    [key]: {
                        region_id: regionId,
                        region_name: '',
                        country_code: ''
                    }
                }))
            }
        })
    )
})
