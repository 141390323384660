import React from 'react'
import { Table as AntdTable } from 'antd'
import _ from 'lodash'
import { DndContext, PointerSensor, useSensors, useSensor } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import Row from './Row'
import Cell from './Cell'

export default function AntdEditTable({ loading, draggable, recordKey, columns, dataSource, handleSave, ...props }) {
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 1
            }
        })
    )

    const antdColumns = columns.map(column => {
        const editable = _.get(column, 'editable', false)
        if (!editable) {
            return column
        }

        return {
            ...column,
            onCell: record => ({
                record,
                column: column.dataIndex,
                type: column.inputType || 'text',
                editable,
                handleSave
            })
        }
    })

    return (
        <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={props.onDragEnd}>
            <SortableContext items={dataSource.map(record => record[recordKey])} strategy={verticalListSortingStrategy}>
                <AntdTable
                    bordered
                    loading={loading}
                    rowKey={record => record[recordKey]}
                    rowClassName="editable-row"
                    columns={antdColumns}
                    dataSource={dataSource}
                    pagination={true}
                    onRow={() => ({ draggable })}
                    components={{
                        body: {
                            row: Row,
                            cell: Cell
                        }
                    }}
                    {...props}
                />
            </SortableContext>
        </DndContext>
    )
}
