import React, { useContext, useState, useMemo } from 'react'
import { Card, Row, Col, Button, Input, Table, Spin, Typography, Space } from 'antd'
import { UserAddOutlined, UploadOutlined, ReloadOutlined, DownloadOutlined, SearchOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { filterTableV2 } from '@/search'
import Breadcrumb from './Breadcrumb'
import { ManageUsersContext } from './ManageUsers'

export default function Users({ loading }) {
    const {
        canCreate,
        canExport,
        canImport,
        users,
        columns,
        selectedUsers,
        onOpenCreateForm,
        onReload,
        onOpenImport,
        onOpenExport,
        onChangeSelectedUsers
    } = useContext(ManageUsersContext)

    const [query, setQuery] = useState('')

    const onDebounceChangeQuery = _.debounce(value => setQuery(value), 200)

    const filterUsers = useMemo(() => filterTableV2(query, users, ['email', 'name']), [query, users])

    return (
        <React.Fragment>
            <Card title={<Typography.Title level={3}>Users</Typography.Title>} extra={<Breadcrumb />}>
                <Row gutter={[0, 8]}>
                    <Col sm={24} md={24} lg={24} xl={12}>
                        <Space className="d-flex justify-content-between justify-content-xl-start align-items-center">
                            <Button
                                disabled={!canCreate}
                                type="primary"
                                icon={<UserAddOutlined />}
                                onClick={onOpenCreateForm}>
                                Create
                            </Button>
                            <Button disabled={!canImport} type="info" icon={<UploadOutlined />} onClick={onOpenImport}>
                                Import
                            </Button>
                            <Button
                                disabled={!canExport}
                                type="info"
                                icon={<DownloadOutlined />}
                                onClick={() => onOpenExport('SELECTED')}>
                                Export (<span>{selectedUsers.length}</span>)
                            </Button>
                            <Button
                                disabled={!canExport}
                                type="info"
                                icon={<DownloadOutlined />}
                                onClick={() => onOpenExport('ALL')}>
                                Export All
                            </Button>
                            <Button disabled={loading} icon={<ReloadOutlined />} onClick={onReload}>
                                Reload
                            </Button>
                        </Space>
                    </Col>
                    <Col sm={24} md={24} lg={24} xl={12}>
                        <div className="d-flex justify-content-end align-items-center">
                            <Input
                                className="w-100"
                                allowClear
                                size="middle"
                                placeholder="Search"
                                prefix={<SearchOutlined />}
                                onChange={e => onDebounceChangeQuery(e.target.value)}
                            />
                        </div>
                    </Col>
                </Row>
                <br />
                <Spin spinning={loading}>
                    <Table
                        bordered
                        rowKey={record => record.id}
                        columns={columns}
                        dataSource={filterUsers}
                        scroll={{ x: 'max-content' }}
                        rowSelection={{
                            onChange: (_, rows) => onChangeSelectedUsers(rows)
                        }}
                    />
                </Spin>
            </Card>
        </React.Fragment>
    )
}
