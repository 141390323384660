import React, { useEffect, useMemo, useState } from 'react'
import { Table, Tag } from 'antd'

export default function Logs({ onFetchLog }) {
    const [loading, setLoading] = useState(false)
    const [logs, setLogs] = useState([])

    useEffect(() => {
        setLoading(true)
        onFetchLog()
            .then(logs => setLogs(logs))
            .finally(() => setLoading(false))
    }, [onFetchLog])

    const columns = useMemo(
        () => [
            {
                key: 'method',
                dataIndex: 'method',
                title: 'Method'
            },
            {
                key: 'path',
                dataIndex: 'path',
                title: 'Path'
            },
            {
                key: 'status',
                dataIndex: 'status',
                title: 'Status',
                render: (_, cell) => {
                    const isSuccess = cell.status >= 200 && cell.status < 300
                    return isSuccess ? <Tag color="green">Success</Tag> : <Tag color="red">Failed</Tag>
                }
            },
            {
                key: 'user',
                dataIndex: 'user',
                title: 'User'
            },
            {
                key: 'created_at',
                dataIndex: 'created_at',
                title: 'Created at'
            }
        ],
        []
    )

    const expandTableColumn = useMemo(
        () => [
            {
                key: 'request',
                dataIndex: 'request',
                title: 'Request',
                ellipsis: true,
                render: cell => <pre>{JSON.stringify(JSON.parse(cell), null, 2)}</pre>
            },
            {
                key: 'response',
                dataIndex: 'response',
                title: 'Response',
                ellipsis: true,
                render: cell => <pre>{cell ? JSON.stringify(JSON.parse(cell), null, 2) : ''}</pre>
            }
        ],
        []
    )

    return (
        <Table
            style={{
                maxHeight: '560px',
                overflow: 'auto'
            }}
            bordered
            loading={loading}
            rowKey={record => record.id}
            dataSource={logs}
            columns={columns}
            pagination={{ size: 'small', simple: true }}
            expandedRowRender={row => (
                <Table rowKey="id" bordered dataSource={[row]} columns={expandTableColumn} pagination={false} />
            )}
        />
    )
}
