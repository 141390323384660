import { v4 as uuid } from 'uuid'
import _ from 'lodash'
import { safeObjectToString } from '../@/common'

const localStorage = {
    disabled: !window.localStorage,

    getViews: async key => {
        return new Promise(resolve => {
            let views = window.localStorage.getItem(key)
            views = JSON.parse(views) || []

            resolve(views)
        })
    },

    saveView: async (key, payload) => {
        return new Promise(resolve => {
            let views = window.localStorage.getItem(key)
            views = JSON.parse(views) || []

            if (payload.id) {
                const viewIndex = _.findIndex(views, v => v.id === payload.id)
                const fieldsValue = _.omit(payload, ['id'])
                views[viewIndex] = _.assign(views[viewIndex], fieldsValue)
            } else {
                const fieldsValue = _.omit(payload, ['id'])

                views.push({
                    id: uuid(),
                    is_favorite: false,
                    ...fieldsValue
                })
            }

            window.localStorage.setItem(key, safeObjectToString(views))

            resolve(views)
        })
    },

    removeView: async (key, viewId) => {
        return new Promise(resolve => {
            let views = window.localStorage.getItem(key)
            views = JSON.parse(views)
            views = views.filter(v => v.id !== viewId)

            window.localStorage.setItem(key, safeObjectToString(views))

            resolve(views)
        })
    }
}

export const { disabled, getViews, saveView, removeView } = localStorage
