import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { Card, Row, Col, Button, Select, Spin, Table, Popconfirm, notification } from 'antd'
import OwnerAdd from './OwnerAdd'
import _ from 'lodash'
import * as service from '../../services'

const modes = [
    { label: 'User', value: 'users' },
    { label: 'Supplier', value: 'suppliers' }
]

const products = [
    { label: 'Flight', value: 'flight', order: 0 },
    { label: 'Hotel', value: 'hotel', order: 1 },
    { label: 'Tour', value: 'tour', order: 2 },
    { label: 'Transfer', value: 'transfer', order: 3 },
    { label: 'Car', value: 'car', order: 4 }
]

export default function Owner() {
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [users, setUsers] = useState([])
    const [owners, setOwners] = useState([])
    const [searchUser, setSearchUser] = useState('')
    const [form, setForm] = useState({
        product: 'flight',
        mode: 'users',
        userId: ''
    })
    const [selectedRowKeys, setSelectedRowKeys] = useState([])

    const onOpenModal = () => {
        if (!form.userId) {
            notification.error({
                message: `Please choose one user`
            })

            return
        }

        setIsOpenModal(true)
    }

    const onAdd = async obj => {
        service.owners
            .addOwner({ product: form.product, ...obj })
            .then(() => {
                setIsOpenModal(false)
                fetchOwners()
            })
            .catch(() => {
                setIsOpenModal(false)
            })
    }

    const onDelete = useCallback(
        async ids => {
            const tasks = ids.map(id =>
                service.owners.deleteOwner({
                    product: form.product,
                    mode: form.mode,
                    ownerId: id
                })
            )

            Promise.all(tasks).then(() => setOwners(owners => owners.filter(o => !ids.includes(o.id))))
        },
        [form.mode, form.product]
    )

    const fetchOwners = useCallback(async () => {
        setLoading(true)
        service.owners
            .getOwners(form)
            .then(owners => setOwners(owners))
            .finally(() => {
                setLoading(false)
            })
    }, [form])

    const columns = useMemo(() => {
        const cols = [
            {
                key: 'object_id',
                dataIndex: 'object_id',
                title: form.mode === 'users' ? 'User Id' : 'Supplier Id',
                className: 'text-center',
                sorter: {
                    compare: (a, b) => a && b && a.object_id - b.object_id
                }
            },
            {
                key: 'object_name',
                dataIndex: 'object_name',
                title: form.mode === 'users' ? 'User Email' : 'Supplier Name',
                className: 'text-center'
            },
            {
                key: 'object_display_name',
                dataIndex: 'object_display_name',
                title: form.mode === 'users' ? 'User Name' : 'Supplier UserName',
                className: 'text-center'
            }
        ]

        if (form.mode === 'suppliers') {
            cols.push(
                ...[
                    {
                        key: 'object_code',
                        dataIndex: 'object_name',
                        title: 'Supplier Code',
                        className: 'text-center'
                    },
                    {
                        key: 'object_source_id',
                        dataIndex: 'object_source_id',
                        title: 'Supplier Source',
                        className: 'text-center'
                    }
                ]
            )
        }

        cols.push({
            key: 'operation',
            title: 'Action',
            render: (_, record) => {
                return (
                    <Popconfirm title="Are u sure to delete" onConfirm={() => onDelete([record.id])}>
                        <Button type="primary" danger>
                            Delete
                        </Button>
                    </Popconfirm>
                )
            }
        })

        return cols
    }, [form.mode, onDelete])

    const userOptions = useMemo(() => {
        const search = _.toLower(searchUser.trim())
        if (!search) {
            return users
        }

        return users.filter(user => _.toLower(user.label).includes(search))
    }, [searchUser, users])

    const fetchAsgardUsers = useCallback(() => {
        service.owners.getAsgardUsers().then(users => {
            const options = users.map(au => ({
                label: au.email,
                value: au.id
            }))

            setUsers(options)
        })
    }, [])

    useEffect(() => {
        fetchAsgardUsers()
    }, [fetchAsgardUsers])

    useEffect(() => {
        if (form.userId) {
            fetchOwners()
        }
    }, [form, fetchOwners])

    return (
        <React.Fragment>
            <Card title="Filter">
                <Row gutter={16}>
                    <Col span={4}>
                        <Select
                            showArrow={true}
                            style={{ width: '150px' }}
                            placeholder="Choose product"
                            options={products}
                            defaultValue={form.product}
                            onChange={product => {
                                setForm(state => ({ ...state, product }))
                            }}
                            filterSort={(a, b) => (a.order < b.order ? -1 : 1)}
                        />
                    </Col>
                    <Col span={4}>
                        <Select
                            showArrow={true}
                            style={{ width: '150px' }}
                            placeholder="Choose mode"
                            options={modes}
                            defaultValue={form.mode}
                            onChange={mode => {
                                setForm(state => ({ ...state, mode }))
                            }}
                        />
                    </Col>
                    <Col span={4}>
                        <Select
                            showSearch
                            showArrow={true}
                            filterOption={false}
                            style={{ width: '250px' }}
                            placeholder="Choose user"
                            onChange={userId => {
                                setForm(state => ({ ...state, userId }))
                            }}
                            options={userOptions}
                            searchValue={searchUser}
                            onSearch={value => setSearchUser(value)}
                            filterSort={(a, b) => (a.label < b.label ? -1 : 1)}
                        />
                    </Col>
                    <Col flex={1}>
                        <Row justify={'end'}>
                            <Col md={4}>
                                <Button type="primary" onClick={onOpenModal}>
                                    Add New
                                </Button>
                            </Col>
                            <Col md={4}>
                                <Button
                                    danger
                                    disabled={!selectedRowKeys.length}
                                    onClick={() => onDelete(selectedRowKeys)}>
                                    Delete
                                </Button>
                            </Col>
                            <Col md={4}>
                                <Button disabled={!form.userId} onClick={() => fetchOwners()}>
                                    Refresh
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
            <br />
            <Card>
                <Spin spinning={loading}>
                    <Table
                        rowKey={record => record.id}
                        bordered
                        columns={columns}
                        dataSource={owners}
                        rowSelection={{
                            type: 'checkbox',
                            selectedRowKeys,
                            onChange: selectedRowKeys => {
                                setSelectedRowKeys(selectedRowKeys)
                            }
                        }}
                        pagination={{
                            defaultPageSize: 10,
                            position: 'bottomRight'
                        }}
                    />
                </Spin>
            </Card>
            {isOpenModal && (
                <OwnerAdd isOpen={isOpenModal} form={form} onClose={() => setIsOpenModal(false)} onAdd={onAdd} />
            )}
        </React.Fragment>
    )
}
