import { apiAxios } from './api'

export async function getSuppliers({ product }) {
    const { data } = await apiAxios.get(`/${product}/suppliers`)

    return data.suppliers
}

export async function adjustConfig({ product, supplierId, data }) {
    await apiAxios.patch(`/${product}/suppliers/${supplierId}`, data)
}

export async function createSupplier({ product, data }) {
    await apiAxios.post(`/${product}/suppliers`, data)
}

export async function updateSupplier({ product, supplierId, data }) {
    await apiAxios.put(`/${product}/suppliers/${supplierId}`, data)
}

export async function getSuppliersByUser({ product, userId }) {
    const { data } = await apiAxios.get(`/${product}/users/${userId}/suppliers`)

    return data.suppliers
}

export async function addSupplierToUser({ product, userId, data }) {
    await apiAxios.post(`/${product}/users/${userId}/suppliers`, data)
}

export async function removeSupplierFromUser({ product, userId, supplierId }) {
    await apiAxios.delete(`/${product}/users/${userId}/suppliers/${supplierId}`)
}

export async function applyMarkup({ product, userId, data }) {
    await apiAxios.patch(`/${product}/users/${userId}/suppliers`, data)
}

export async function adjustConfigUserSupplier({ product, userId, supplierId, data }) {
    await apiAxios.patch(`/${product}/users/${userId}/suppliers/${supplierId}`, data)
}
