import React, { useState, useEffect, useCallback } from 'react'
import { Card, Row, Col, Tag, Image } from 'antd'
import { HomeOutlined, PhoneOutlined, InboxOutlined, ClockCircleOutlined, CalendarOutlined } from '@ant-design/icons'
import _ from 'lodash'
import * as services from 'services'
import { BOOKING_STATUS_COLORS } from 'constant'

export default function BookingSummary({ row }) {
    const [loading, setLoading] = useState(false)
    const [hotel, setHotel] = useState({})

    const handleFetch = useCallback(() => {
        setLoading(true)

        services.hotel
            .getHotel({
                params: {
                    hotel_id: row.hotel_id,
                    supplier_code: row.supplier_code
                }
            })
            .then(hotel => setHotel(hotel))
            .finally(() => {
                setLoading(false)
            })
    }, [row])

    useEffect(() => {
        handleFetch()
    }, [handleFetch])

    const src = _.get(hotel, 'images[0].url', '')
    const alt = _.get(hotel, 'images[0].caption', '')

    return (
        <Card type="inner" size="small" loading={loading} title={<p className="fw-5 fw-bold">Summary</p>}>
            <Row gutter={[0, 16]} className="d-flex flex-column flex-md-row">
                <Row gutter={8} className="d-flex flex-column justify-content-start flex-grow-1">
                    <Row gutter={8}>
                        <Col sm={6} className="d-flex flex-column">
                            <p className="fw-lighter">Hotel Name</p>
                            <p>{hotel && hotel.name ? hotel.name : '-/-'}</p>
                        </Col>

                        <Col sm={6} className="d-flex flex-column">
                            <div className="d-flex gap-2 align-items-center">
                                <HomeOutlined />
                                <span className="fw-lighter">Address</span>
                            </div>
                            <p>{hotel && hotel.address ? hotel.address : '-/-'}</p>
                        </Col>

                        <Col sm={6} className="d-flex flex-column">
                            <div className="d-flex gap-2 align-items-center">
                                <PhoneOutlined />
                                <span className="fw-lighter">Phone</span>
                            </div>
                            <p className="text-truncate">{hotel && hotel.phone ? hotel.phone : '-/-'}</p>
                        </Col>

                        <Col sm={6} className="d-flex flex-column">
                            <div className="d-flex gap-2 align-items-center">
                                <InboxOutlined />
                                <span className="fw-lighter">Email</span>
                            </div>
                            <p className="text-truncate">{hotel && hotel.email ? hotel.email : '-/-'}</p>
                        </Col>
                    </Row>

                    <Row gutter={8}>
                        <Col sm={24} className="d-flex flex-column">
                            <p className="fw-lighter">Description</p>
                            <p>{hotel && hotel.description ? hotel.description : '-/-'}</p>
                        </Col>
                    </Row>

                    <Row gutter={8}>
                        <Col sm={6} className="d-flex flex-column">
                            <p className="fw-lighter">Booking ID</p>
                            <p className="fw-bold text-truncate">{row.booking_id}</p>
                        </Col>

                        <Col sm={6} className="d-flex flex-column">
                            <div className="d-flex gap-2 align-items-center">
                                <CalendarOutlined />
                                <span className="fw-lighter">Booked Date</span>
                            </div>
                            <p>{row.booking_date}</p>
                        </Col>

                        <Col sm={6} className="d-flex flex-column">
                            <div className="d-flex gap-2 align-items-center">
                                <ClockCircleOutlined />
                                <span className="fw-lighter">Checkin</span>
                            </div>
                            <p>{row.checkin}</p>
                        </Col>

                        <Col sm={6} className="d-flex flex-column">
                            <div className="d-flex gap-2 align-items-center">
                                <ClockCircleOutlined />
                                <span className="fw-lighter">Checkout</span>
                            </div>
                            <p>{row.checkout}</p>
                        </Col>
                    </Row>

                    <Row gutter={8}>
                        <Col sm={6} className="d-flex flex-column">
                            <p className="fw-lighter">Status</p>
                            <p>
                                <Tag color={BOOKING_STATUS_COLORS.HOTEL[row.status]}>{row.status}</Tag>
                            </p>
                        </Col>

                        <Col sm={6} className="d-flex flex-column">
                            <p className="fw-lighter">Amount</p>
                            <p className="text-danger">
                                {!row.currency || !row.amount ? '-/-' : `${row.currency} ${row.amount}`}
                            </p>
                        </Col>

                        <Col sm={6} className="d-flex flex-column">
                            <p className="fw-lighter">Provider</p>
                            <p className="fw-bold">{row.supplier_code}</p>
                        </Col>
                    </Row>
                </Row>

                <Row className="d-flex flex-shrink-0 justify-content-center align-items-center mx-auto">
                    <Image width={250} preview={false} alt={alt} src={src} />
                </Row>
            </Row>
        </Card>
    )
}
