import React, { useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Typography, Row, Button, Modal, Tag } from 'antd'
import { SendOutlined, ClearOutlined, SearchOutlined } from '@ant-design/icons'
import moment from 'moment'
import _ from 'lodash'
import * as services from 'services'
import { BOOKING_ANCILLARIES, BOOKING_STATUS_COLORS } from 'constant'
import BookingSegments from './booking-segments'
import BookingSummary from './booking-summary'
import BookingTravellers from './booking-travellers'

export default function BookingForm({ row }) {
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [booking, setBooking] = useState(null)
    const [paxes, setPaxes] = useState([])
    const [leg, setLeg] = useState({
        outbound: [],
        inbound: []
    })

    const handleFetch = useCallback(() => {
        setLoading(true)

        services.bookings
            .getBooking({
                product: 'flight',
                bookingId: row.id,
                params: {
                    pnr: row.pnr,
                    supplier_code: row.supplier_code
                }
            })
            .then(booking => setBooking(booking))
            .finally(() => {
                setLoading(false)
            })
    }, [row])

    useEffect(() => {
        handleFetch()
    }, [handleFetch])

    useEffect(() => {
        const segments = _.get(booking, 'segments', [])
        let outbound = [...segments]
        let inbound = []

        const index = _.findIndex(segments, s => s.departure_airport.iata_code === row.arrival_airport)
        if (index !== -1) {
            outbound = _.slice(segments, 0, index)
            inbound = _.slice(segments, index)
        }

        const leg = {
            outbound,
            inbound
        }

        setLeg(leg)
    }, [booking])

    useEffect(() => {
        const paxes = _.get(booking, 'paxes', [])

        const segments = _.chain(booking)
            .get('segments', [])
            .keyBy(s => s.flight_no)
            .value()

        const ancillaries = _.chain(booking)
            .get('segments', [])
            .keyBy(s => s.flight_no)
            .mapValues(s => [
                ..._.get(s, 'ancillaries', []),
                ..._.get(s, 'seats', []).map(seat => ({
                    ...seat,
                    type: BOOKING_ANCILLARIES.SEAT
                }))
            ])
            .value()

        _.forEach(paxes, pax => {
            pax.ancillaries = _.chain(ancillaries)
                .keys()
                .map(flightNo => {
                    const baggage = _.find(ancillaries[flightNo], a => a.type === 'Baggage' && a.pax_id === pax.pax_id)
                    const meal = _.find(ancillaries[flightNo], a => a.type === 'Meal' && a.pax_id === pax.pax_id)
                    const seat = _.find(ancillaries[flightNo], a => a.type === 'Seat' && a.pax_id === pax.pax_id)
                    const segment = segments[flightNo]

                    const departureFrom = _.get(segment, 'departure_airport.city', null)
                    const departureTime = moment(segment.departure_date_time, 'YYYY-MM-DD[T]HH:mm').format(
                        'YYYY-MM-DD HH:mm'
                    )

                    const arrivalFrom = _.get(segment, 'arrival_airport.city', null)
                    const arrivalTime = moment(segment.arrival_date_time, 'YYYY-MM-DD[T]HH:mm').format(
                        'YYYY-MM-DD HH:mm'
                    )

                    return {
                        flight_no: flightNo,
                        departure_from: departureFrom,
                        departure_time: departureTime,
                        arrival_from: arrivalFrom,
                        arrival_time: arrivalTime,
                        baggage: _.get(baggage, 'code', null),
                        meal: _.get(meal, 'code', null),
                        seat: _.get(seat, 'seat_number', null)
                    }
                })
                .value()
        })

        setPaxes(paxes)
    }, [booking])

    const onCancel = async booking => {
        Modal.confirm({
            maskClosable: true,
            title: `Are you sure to cancel booking ${booking.client_ref || booking.booking_reference}`,
            onOk: async () => {
                services.bookings.cancelBooking(booking)
            }
        })
    }

    const onSyncTicketToEngine = async booking => {
        Modal.confirm({
            maskClosable: true,
            title: `Are you sure to sync ticket to engine booking ${booking.client_ref || booking.booking_reference}`,
            onOk: async () => {
                await services.bookings.syncTicket(booking)
            }
        })
    }

    const onGotoLog = () => {
        const params = {
            app: 'flight',
            env: process.env.REACT_APP_ENV,
            dates: [moment(booking.booking_date), moment(booking.booking_date)],
            request_id: booking.search_id
        }

        const searchParams = new URLSearchParams(params)

        history.push(`/log/log-loki?${searchParams.toString()}`)
    }

    return (
        <Card
            title={
                <div className="d-flex gap-2 align-items-center">
                    <Typography.Title ellipsis level={3}>
                        {row.booking_reference}
                    </Typography.Title>
                    <Tag closable={false} color={BOOKING_STATUS_COLORS.FLIGHT[row.status]}>
                        {row.status}
                    </Tag>
                </div>
            }
            extra={
                <div className="d-flex gap-2 align-items-center">
                    <Button
                        disabled={!['PKFare', 'Letsfly', 'Via', 'Mystifly', 'FR24'].includes(row.supplier_code)}
                        key="__sync__"
                        type="info"
                        icon={<SendOutlined />}
                        onClick={() => onSyncTicketToEngine(row)}>
                        Sync to Engine
                    </Button>
                    <Button
                        disabled={
                            !(
                                moment().isBefore(moment(row.departure_date, 'yyyy-MM-dd HH:mm')) &&
                                ['Issued', 'PendingForTicketing', 'Pending'].includes(row.status)
                            )
                        }
                        key="__cancel__"
                        type="danger"
                        icon={<ClearOutlined />}
                        onClick={() => onCancel(row)}>
                        Cancel
                    </Button>
                    <Button key="__log__" type="info" icon={<SearchOutlined />} onClick={onGotoLog}>
                        Logs
                    </Button>
                </div>
            }>
            <Card
                type="inner"
                size="small"
                title={
                    <div className="d-flex flex-column">
                        <div className="d-flex gap-2 align-items-center">
                            <p className="fs-5 fw-bold text-uppercase">{row.departure_airport}</p>
                            <p className="fs-5">to</p>
                            <p className="fs-5 fw-bold text-uppercase">{row.arrival_airport}</p>
                        </div>
                        <p className="fw-lighter">{row.is_roundtrip ? 'Round-trip' : 'One-way'}</p>
                    </div>
                }>
                <Row gutter={16} className="d-flex flex-column">
                    <BookingSummary row={row} />

                    <Card type="inner" size="small" title={<p className="fs-5 fw-bold">Segments</p>}>
                        <BookingSegments loading={loading} direction="outbound" status={row.status} leg={leg} />
                        <br />
                        <BookingSegments loading={loading} direction="inbound" status={row.status} leg={leg} />
                    </Card>

                    <Card type="inner" size="small" title={<p className="fs-5 fw-bold">Travelers</p>}>
                        <Card.Meta description="This symbol indicates that the airline has confirmed your request. If your request is not confirmed within 24 hours, please contact us directly." />
                        <br />
                        <BookingTravellers loading={loading} paxes={paxes} />
                    </Card>
                </Row>
            </Card>
        </Card>
    )
}
