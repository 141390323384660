import React, { useState, useRef, useEffect } from 'react'
import { Row, Col, Typography, Select } from 'antd'
import * as services from 'services'

export default function FilterCreatedBy({ product, selectedUsers, onChangeFilter }) {
    const [loading, setLoading] = useState(false)
    const [users, setUsers] = useState([])
    const ref = useRef(null)

    useEffect(() => {
        setLoading(true)

        services.users
            .getUsers({ product })
            .then(users => setUsers(users))
            .finally(() => {
                setLoading(false)
            })
    }, [product])

    const onClickTitle = () => {
        if (ref && ref.current) {
            ref.current.focus()
        }
    }

    const onFilterOption = (query, option) => RegExp('.*' + query, 'gi').test(option.label)

    return (
        <Row gutter={[8, 8]}>
            <Col span={8}>
                <Typography.Text strong ellipsis onClick={onClickTitle}>
                    Users:
                </Typography.Text>
            </Col>
            <Col span={16}>
                <Select
                    ref={ref}
                    className="w-100 select-overflow text-center"
                    placeholder="Choose Users"
                    showSearch
                    allowClear
                    mode="multiple"
                    loading={loading}
                    value={selectedUsers}
                    filterOption={onFilterOption}
                    onChange={onChangeFilter}
                    options={users.map(user => ({
                        label: user.email,
                        value: user.id
                    }))}
                />
            </Col>
        </Row>
    )
}
