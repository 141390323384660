import { apiAxios } from './api'
import qs from 'querystring'

export async function getAsgardUsers() {
    const { data } = await apiAxios.get('/users')
    return data
}

export async function getOwners({ product, mode, userId }) {
    const params = {
        user_id: userId,
        mode: mode
    }

    const { data } = await apiAxios.get(`/${product}/owners?${qs.stringify(params)}`)
    return data.data
}

export async function addOwner({ product, mode, userId, objectId }) {
    const data = {
        user_id: userId,
        object_id: objectId,
        mode: mode
    }

    await apiAxios.post(`/${product}/owners`, data)
}

export async function deleteOwner({ product, mode, ownerId }) {
    await apiAxios.delete(`/${product}/owners/${ownerId}?mode=${mode}`)
}
