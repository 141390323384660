import ProLayout from '@ant-design/pro-layout'
import React from 'react'
import { Link, Redirect, Route, Switch, useHistory } from 'react-router-dom'
// import logolighttext from '../assets/images/logo-text.png'
import logo from '../assets/images/logo-light-icon.png'
import { check } from '../components/can'
import RightHeader from '../components/header/RightHeader'
import routes from '../routes/routing'
import { useLocalStorage } from 'hooks'
import HotelUserSuppliers from 'views/hotel/user-suppliers'
import FlightUserSuppliers from 'views/flight/user-suppliers'
import TourUserSuppliers from 'views/tour/user-suppliers'
import TransferUserSuppliers from 'views/transfer/user-suppliers'
import CarUserSuppliers from 'views/car/user-suppliers'
import InsuranceUserSuppliers from 'views/insurance/user-suppliers'

const menuDataRender = menuList => {
    const result = []
    menuList.forEach(item => {
        const localItem = { ...item }
        if (localItem.navlabel) {
            const hasPermission = localItem.resources.find(resource => check(resource, localItem.perform))
            if (hasPermission) {
                result.push(localItem)
            }

            return
        }

        if (check(localItem.resource, localItem.perform)) {
            if (localItem.collapse) {
                localItem.children = menuDataRender(localItem.routes)
                delete localItem.routes
            }
            result.push(localItem)
        }
    })

    return result
}

const footerRender = () => {
    return (
        <div
            style={{
                padding: '0px 24px 24px',
                textAlign: 'center'
            }}>
            All Rights Reserved by GoQuo (M) Sdn. Bhd. © 2019. Developed by{' '}
            <a href="https://asgard.goquo.com">Gateway Team</a>.
        </div>
    )
}

const Dashboard = props => {
    const ableRoutes = menuDataRender(routes)

    const history = useHistory()
    const [user] = useLocalStorage({ key: 'user' })

    const onLogout = () => {
        localStorage.clear()
        history.push('/login')
    }

    return (
        <ProLayout
            logo={logo}
            title={process.env.REACT_APP_WEBSITE_NAME}
            menuItemRender={(menuItemProps, defaultDom) => {
                if (menuItemProps.navlabel) {
                    return defaultDom
                }

                return <Link to={menuItemProps.path}>{defaultDom}</Link>
            }}
            // itemRender={(route, params, routes, paths) => {
            //     const first = routes.indexOf(route) === 0
            //     return first ? <Link to={paths.join('/')}>{route.name}</Link> : <span>{route.name}</span>
            // }}
            footerRender={footerRender}
            menuDataRender={() => ableRoutes}
            rightContentRender={() => <RightHeader user={user} onLogout={onLogout} />}
            {...props}>
            <Switch>
                {ableRoutes.map((prop, key) => {
                    if (prop.navlabel) {
                        return null
                    }
                    if (prop.collapse) {
                        return prop.children.map((prop2, key2) => {
                            if (prop2.collapse) {
                                return prop2.children.map((prop3, key3) => (
                                    <Route path={prop3.path} component={prop3.component} key={key3} />
                                ))
                            }

                            return (
                                <Route exact={prop2.exact} path={prop2.path} component={prop2.component} key={key2} />
                            )
                        })
                    }

                    if (prop.redirect) {
                        return <Redirect from={prop.path} to={prop.pathTo} key={key} />
                    }

                    return <Route path={prop.path} component={prop.component} key={key} />
                })}
                <Route path="/hotel/users/:id" component={HotelUserSuppliers} />
                <Route path="/flight/users/:id" component={FlightUserSuppliers} />
                <Route path="/tour/users/:id" component={TourUserSuppliers} />
                <Route path="/transfer/users/:id" component={TransferUserSuppliers} />
                <Route path="/insurance/users/:id" component={InsuranceUserSuppliers} />
                <Route path="/car/users/:id" component={CarUserSuppliers} />
            </Switch>
        </ProLayout>
    )
}

export default Dashboard
