import React, { useMemo } from 'react'
import { check } from '../components/can'

export const PermissionContext = React.createContext({})

export default function usePermission({ resource }) {
    const canCreate = useMemo(() => check(resource, 'create'), [resource])

    const canEdit = useMemo(() => check(resource, 'edit'), [resource])

    const canRemove = useMemo(() => check(resource, 'remove'), [resource])

    const canExport = useMemo(() => check(resource, 'export'), [resource])

    const canImport = useMemo(() => check(resource, 'import'), [resource])

    return {
        canCreate,
        canEdit,
        canRemove,
        canExport,
        canImport
    }
}
