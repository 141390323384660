import React, { useState } from 'react'
import { Modal, Input } from 'antd'

function ApplyMarkupToAllSupplier({ onChangeMarkup }) {
    return (
        <React.Fragment>
            <p className="mb-1">Enter the markup value will apply for all suppliers in this user</p>
            <Input type="number" onChange={e => onChangeMarkup(e.target.value)} />
        </React.Fragment>
    )
}

export default function ApplyMarkupModal({ isOpen, onApplyMarkup, onClose }) {
    const [markupPercent, setMarkup] = useState(0)

    const onOk = () => {
        onApplyMarkup(markupPercent)
    }

    return (
        <Modal
            centered
            destroyOnClose
            title="Markup Configuration"
            open={isOpen}
            onOk={onOk}
            onCancel={onClose}
            okButtonProps={{ disabled: false }}>
            <ApplyMarkupToAllSupplier onChangeMarkup={value => setMarkup(value)} />
        </Modal>
    )
}
