import React, { useMemo } from 'react'
import { Table, Image, Card } from 'antd'
import { GlobalOutlined, ArrowUpOutlined, ArrowDownOutlined, ClockCircleOutlined } from '@ant-design/icons'
import _ from 'lodash'
import moment from 'moment'
import { BOOKING_STATUS_COLORS } from 'constant'

export default function BookingSegments({ loading, direction, status, leg }) {
    const columns = [
        {
            key: 'flight_no',
            title: 'Flight Number',
            dataIndex: 'flight_no',
            className: 'text-center',
            render: (_, record) => (
                <div className="d-flex gap-2 align-items-center">
                    <Image
                        width={70}
                        alt="airline_code"
                        preview={false}
                        src={`http://www.gstatic.com/flights/airline_logos/70px/${record.flight_no.slice(0, 2)}.png`}
                    />
                    <span>{record.flight_no}</span>
                </div>
            )
        },
        {
            key: 'cabin_class',
            dataIndex: 'cabin_class',
            title: 'Cabin',
            className: 'text-center',
            render: (_, record) => <span>{record.cabin_class ?? '-/-'}</span>
        },
        {
            key: 'departure',
            title: 'Departure',
            dataIndex: 'departure_date_time',
            ellipsis: true,
            className: 'text-center',
            render: (departDateTime, record) => {
                const iataCode = _.get(record, 'departure_airport.iata_code', '-')
                const city = _.get(record, 'departure_airport.city', '-')
                const name = _.get(record, 'departure_airport.name', '')
                const time = moment(departDateTime, 'YYYY-MM-DD[T]HH:mm').format('YYYY-MM-DD HH:mm')

                return (
                    <Card.Meta
                        title={
                            <div className="d-flex gap-2 align-items-center">
                                <GlobalOutlined />
                                <span>{`${iataCode} - ${city}`}</span>
                            </div>
                        }
                        description={
                            <div className="d-flex gap-2 flex-column">
                                <span className="text-start fw-lighter">{name}</span>
                                <div className="d-flex gap-2 align-items-center">
                                    <ClockCircleOutlined />
                                    <span className="fw-lighter">{time}</span>
                                </div>
                            </div>
                        }
                    />
                )
            }
        },
        {
            key: 'arrival',
            title: 'Arrival',
            dataIndex: 'arrival_date_time',
            ellipsis: true,
            className: 'text-center',
            render: (arrivalDateTime, record) => {
                const iataCode = _.get(record, 'arrival_airport.iata_code', '-')
                const city = _.get(record, 'arrival_airport.city', '-')
                const name = _.get(record, 'arrival_airport.name', '')
                const time = moment(arrivalDateTime, 'YYYY-MM-DD[T]HH:mm').format('YYYY-MM-DD HH:mm')

                return (
                    <Card.Meta
                        title={
                            <div className="d-flex gap-2 align-items-center">
                                <GlobalOutlined />
                                <span>{`${iataCode} - ${city}`}</span>
                            </div>
                        }
                        description={
                            <div className="d-flex gap-2 flex-column">
                                <span className="text-start fw-lighter">{name}</span>
                                <div className="d-flex gap-2 align-items-center">
                                    <ClockCircleOutlined />
                                    <span className="fw-lighter">{time}</span>
                                </div>
                            </div>
                        }
                    />
                )
            }
        },
        {
            key: 'duration',
            title: 'Duration',
            className: 'text-center',
            ellipsis: true,
            render: (_, record) => {
                const departTime = moment(record.departure_date_time, `YYYY-MM-DD[T]HH:mm`)
                const arrivalTime = moment(record.arrival_date_time, `YYYY-MM-DD[T]HH:mm`)
                const durationInMinutes = arrivalTime.diff(departTime, 'minutes')

                return <p>{`${durationInMinutes} minutes`}</p>
            }
        },
        {
            key: 'aircraft_type',
            dataIndex: 'aircraft_type',
            title: 'Aircraft',
            className: 'text-center',
            ellipsis: true,
            render: (_, record) => <span className="mx-auto">{record.aircraft_type || '-/-'}</span>
        }
    ]

    const title = useMemo(() => {
        const airportFrom = _.chain(leg[direction])
            .head()
            .get('departure_airport.city', '')
            .value()

        const airportTo = _.chain(leg[direction])
            .last()
            .get('arrival_airport.city', '')
            .value()

        const color = BOOKING_STATUS_COLORS.FLIGHT[status] || 'gray'

        return (
            <div className="d-flex gap-2 algin-items-center">
                {direction === 'outbound' ? <ArrowUpOutlined color={color} /> : <ArrowDownOutlined color={color} />}
                <p className="fw-bold my-auto">{`${airportFrom} - ${airportTo}`}</p>
            </div>
        )
    }, [direction, status, leg])

    return (
        <Table
            bordered
            size="middle"
            loading={loading}
            pagination={false}
            rowKey={r => r.flight_no}
            title={() => title}
            columns={columns}
            dataSource={leg[direction]}
        />
    )
}
