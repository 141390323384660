import React from 'react'
import { Spin, Drawer, Button, Space, Tooltip } from 'antd'
import { SaveOutlined, CopyOutlined } from '@ant-design/icons'
import { FORM_TITLE } from 'constant'

export default function FormLayout({
    type,
    action,
    open,
    loading,
    children,
    onCloseForm,
    onSubmit,
    onPaste,
    ...props
}) {
    return (
        <Drawer
            title={FORM_TITLE[type][action]}
            placement="right"
            size="large"
            open={open}
            destroyOnClose
            maskClosable={!loading}
            closable={!loading}
            onClose={onCloseForm}
            forceRender
            extra={
                <Space size="middle">
                    <Tooltip title="Paste" placement="bottom">
                        <Button icon={<CopyOutlined />} onClick={onPaste} />
                    </Tooltip>
                    <Button disabled={loading} type="danger" onClick={onCloseForm}>
                        Close
                    </Button>
                    <Button
                        disabled={loading}
                        type="primary"
                        htmlType="submit"
                        icon={<SaveOutlined />}
                        onClick={onSubmit}>
                        Save
                    </Button>
                </Space>
            }
            {...props}>
            <Spin spinning={loading}>{children}</Spin>
        </Drawer>
    )
}
