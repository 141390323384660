import React, { useState, useEffect } from 'react'

import { UploadOutlined } from '@ant-design/icons'

import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'

import { Button, Card, Input, Modal, Row, Col, notification, Spin, Upload, message } from 'antd'
import axios from 'axios'

import { filterTable } from '@/search'
import { Can, Say } from '../../components/can'
import appConfig from '../../config'
import apiErrorHandler from '../../api-error-handler'
import EditTable from '../../components/EditTable'
import _ from 'lodash'
import qs from 'querystring'

const mappingUrl = `${appConfig.apiUrl}/hotel/marriott/mapping-derbysoft`
const RESOURCE = 'hotel-marriott'
const columns = [
    {
        key: 'No',
        dataIndex: 'No',
        title: 'No'
    },
    {
        key: 'HotelCode',
        dataIndex: 'HotelCode',
        title: 'Hotel Code'
    },
    {
        key: 'HotelName',
        dataIndex: 'HotelName',
        title: 'Hotel Name'
    },
    {
        key: 'HotelAddress',
        dataIndex: 'HotelAddress',
        title: 'Hotel Address'
    },
    {
        key: 'Latitude',
        dataIndex: 'Latitude',
        title: 'Latitude'
    },
    {
        key: 'Longitude',
        dataIndex: 'Longitude',
        title: 'Longitude'
    },
    {
        key: 'CategoryCode',
        dataIndex: 'CategoryCode',
        title: 'Category Code'
    },
    {
        key: 'JPCode',
        dataIndex: 'JPCode',
        title: 'JP Code'
    },
    {
        key: 'SupplierZoneName',
        dataIndex: 'SupplierZoneName',
        title: 'Supplier Zone Name'
    },
    {
        key: 'JuniperZoneName',
        dataIndex: 'JuniperZoneName',
        title: 'Juniper Zone Name'
    },
    {
        key: 'Cities',
        dataIndex: 'Cities',
        title: 'Cities'
    }
]

function MarriottMappingDerbySoft() {
    const [loading, setLoading] = useState(true)
    const [initialRates, setInitialRates] = useState([])
    const [rates, setRates] = useState([])
    const [showImportRate, setShowImportRate] = useState(false)
    const headers = JSON.parse(localStorage.getItem('headers') || '{}')
    const [filter, setFilter] = useState({})

    useEffect(() => {
        getRates()
    }, [])

    const getRates = () => {
        const config = { method: 'get', url: mappingUrl, headers }
        if (!_.isEmpty(filter)) {
            config.url += `?${qs.stringify(filter)}`
        }

        axios(config)
            .then(({ data }) => {
                setRates(data)
                setInitialRates(data)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            })
    }

    const updateRate = row => {
        setLoading(true)
        axios({
            method: 'patch',
            url: `${mappingUrl}/${row.pair}`,
            headers,
            data: {
                rate: Number(row.rate)
            }
        })
            .then(() => {
                getRates()
            })
            .catch(error => {
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
                getRates()
            })
    }

    const toggleImportRate = () => {
        setShowImportRate(!showImportRate)
    }

    const onSearch = e => {
        const searchInput = e.target.value
        const rates = filterTable(searchInput, initialRates, columns)
        setRates(rates)
    }

    const onChangeHotelId = e => {
        const hotelId = e.target.value
        if (hotelId) {
            const newFilter = Object.assign(filter, { HotelCode: hotelId })
            setFilter(newFilter)
        } else {
            delete filter.HotelCode
        }
    }

    // const onChangeClusterCode = e => {
    //     const clusterCode = e.target.value
    //     if (clusterCode) {
    //         const newFilter = Object.assign(filter, { marriott_cluster_code: clusterCode })
    //         setFilter(newFilter)
    //     } else {
    //         delete filter.marriott_cluster_code
    //     }
    // }

    return (
        <div>
            <Card gutter={16}>
                <Row gutter={16}>
                    <Col md={8}>
                        <Input placeholder="Hotel Id (MC-PAROA)" onChange={onChangeHotelId} />
                    </Col>
                    {/* <Col md={8}>
                        <Input placeholder="Marriott Cluster Code (DN2)" onChange={onChangeClusterCode} />
                    </Col> */}
                    <Col md={8}>
                        <Button type="primary" onClick={getRates}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Card>
            <br />
            <Card>
                <Row>
                    <Col md={16}>
                        <Can resource={RESOURCE} perform={'create'}>
                            <Say yes>
                                <Button className="ml-2" type="success" onClick={toggleImportRate}>
                                    <UploadOutlined /> Upload Mapping DerbySoft
                                </Button>
                            </Say>
                        </Can>
                    </Col>
                    <Col md={8}>
                        <Input placeholder="Search" onChange={onSearch} />
                    </Col>
                </Row>
                <br />
                <Spin spinning={loading}>
                    <EditTable rowKey="pair" bordered dataSource={rates} columns={columns} handleSave={updateRate} />
                </Spin>
            </Card>

            <ImportCSV isShow={showImportRate} toggle={toggleImportRate} onSuccess={getRates} />
        </div>
    )
}

function ImportCSV({ onSuccess, isShow, toggle }) {
    const headers = JSON.parse(localStorage.getItem('headers') || '{}')

    const onChange = info => {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList)
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`)
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`)
        }
    }

    const excuteImport = () => {
        onSuccess()
        toggle()
    }

    return (
        <Modal
            visible={isShow}
            title="Import Mapping"
            onOk={excuteImport}
            onCancel={toggle}
            style={{ maxWidth: '70%' }}>
            <div className="mb-2 row">
                <div className="col-md-9">
                    <Upload name="file" multipart={true} action={mappingUrl} headers={headers} onChange={onChange}>
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                </div>
            </div>

            {/* <Table rowKey="line" bordered dataSource={csvData} columns={columns} /> */}
        </Modal>
    )
}

export default Form.create({ name: 'marriott-mapping-derbysoft' })(MarriottMappingDerbySoft)
