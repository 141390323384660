import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Modal } from 'antd'
import * as services from 'services'
import { usePermission } from 'hooks'
import Users from './Users'
import Logs from './Logs'
import ExportLayout from './exports'
import { getChangeFields } from '@/common'

export const ManageUsersContext = React.createContext({})

export default function ManageUsers({ product, resource, children }) {
    const [formState, setFormState] = useState({ action: 'hide' })
    const [loading, setLoading] = useState(false)
    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)
    const [columns, setColumns] = useState([])
    const [isOpenImport, setIsOpenImport] = useState(false)
    const [isOpenExport, setIsOpenExport] = useState({ open: false })
    const [selectedUsers, setSelectedUsers] = useState([])

    const { canCreate, canEdit, canExport, canImport } = usePermission({ resource })

    const onOpenCreateForm = () => {
        setFormState({ action: 'create' })
    }

    const onOpenEditForm = record => {
        setFormState({ action: 'edit', payload: record })
    }

    const onHideForm = () => {
        setFormState({ action: 'hide' })
    }

    const onSelectUser = record => {
        setSelectedUser(record)
    }

    const onClearSelectedUser = () => {
        setSelectedUser(null)
    }

    const onRegisterColumns = columns => {
        setColumns(columns)
    }

    const onReload = () => {
        dispatchEvent('load')
    }

    const onUpdate = useCallback((record, payload) => {
        setUsers(prev =>
            prev.map(user =>
                user.id !== record.id
                    ? user
                    : {
                          ...user,
                          ...payload
                      }
            )
        )
    }, [])

    const onRemove = record => {
        setUsers(prev => prev.filter(u => u.id !== record.id))
    }

    const onSave = async fieldsValue => {
        // console.log('Fields: ', data)
        const data = formState.action === 'edit' ? getChangeFields(fieldsValue, formState.payload) : fieldsValue

        const promise =
            formState.action === 'create'
                ? services.users.createUser({ product, data })
                : services.users.updateUser({
                      product,
                      userId: formState.payload.id,
                      data
                  })

        return promise
    }

    const onFetch = useCallback(
        () =>
            services.users
                .getUsers({
                    product
                })
                .then(users => setUsers(users)),
        [product]
    )

    const onChangeStatus = useCallback(
        async record =>
            services.users
                .updateUser({
                    product,
                    userId: record.id,
                    data: {
                        active: !record.active
                    }
                })
                .then(() =>
                    onUpdate(record, {
                        active: !record.active
                    })
                ),
        [product, onUpdate]
    )

    const onChangeCug = useCallback(
        async record =>
            services.users
                .updateUser({
                    product,
                    userId: record.id,
                    data: {
                        cug: !record.cug
                    }
                })
                .then(() =>
                    onUpdate(record, {
                        cug: !record.cug
                    })
                ),
        [product, onUpdate]
    )

    const onChangeCurrency = useCallback(
        async record =>
            services.users
                .updateUser({
                    product,
                    userId: record.id,
                    data: {
                        convert_currency: !record.convert_currency
                    }
                })
                .then(() =>
                    onUpdate(record, {
                        convert_currency: !record.convert_currency
                    })
                ),
        [product, onUpdate]
    )

    const onFetchLog = useCallback(
        async () =>
            services.logs.getLogs({
                product,
                resource: 'users',
                objectId: selectedUser.id
            }),
        [product, selectedUser]
    )

    const handlers = useMemo(
        () => ({
            load: onFetch,
            status: onChangeStatus,
            cug: onChangeCug,
            currency: onChangeCurrency
        }),
        [onFetch, onChangeStatus, onChangeCug, onChangeCurrency]
    )

    const dispatchEvent = useCallback(
        async (event, record) => {
            setLoading(true)

            const promise = handlers[event](record).finally(() => setLoading(false))

            return promise
        },
        [handlers]
    )

    const onOpenImport = () => {
        setIsOpenImport(true)
    }

    const onCloseImport = () => {
        setIsOpenImport(false)
    }

    const onOpenExport = type => {
        setIsOpenExport({ open: true, type })
    }

    const onCloseExport = () => {
        setIsOpenExport({ open: false })
    }

    const onChangeSelectedUsers = users => {
        setSelectedUsers(users)
    }

    const onBulkSave = () => {}

    useEffect(() => {
        dispatchEvent('load')
    }, [dispatchEvent])

    return (
        <React.Fragment>
            <ManageUsersContext.Provider
                value={{
                    product,
                    resource,
                    canCreate,
                    canEdit,
                    canExport,
                    canImport,
                    formState,
                    users,
                    selectedUser,
                    selectedUsers,
                    columns,
                    dispatchEvent,
                    onOpenCreateForm,
                    onOpenEditForm,
                    onHideForm,
                    onSelectUser,
                    onClearSelectedUser,
                    onRegisterColumns,
                    onSave,
                    onReload,
                    onUpdate,
                    onRemove,
                    isOpenImport,
                    onOpenImport,
                    onCloseImport,
                    isOpenExport,
                    onOpenExport,
                    onCloseExport,
                    onChangeSelectedUsers,
                    onBulkSave
                }}>
                <Users loading={loading} />

                {children}

                {!!selectedUser && (
                    <Modal
                        width={840}
                        title={`Logs of user: ${selectedUser.email}`}
                        open={!!selectedUser}
                        onCancel={onClearSelectedUser}>
                        <Logs onFetchLog={onFetchLog} />
                    </Modal>
                )}

                {canExport && (
                    <ExportLayout
                        resource={resource}
                        data={isOpenExport.type === 'ALL' ? users : selectedUsers}
                        isOpen={isOpenExport.open}
                        onCloseExport={onCloseExport}
                    />
                )}
            </ManageUsersContext.Provider>
        </React.Fragment>
    )
}
