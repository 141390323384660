import React from 'react'
import { Table } from 'antd'
import { ClockCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import moment from 'moment'

export default function BookingCancellationPolicies({ cancellations }) {
    const columns = [
        {
            key: 'from',
            title: 'From',
            dataIndex: 'from',
            className: 'text-center',
            ellipsis: true
        },
        {
            key: 'to',
            title: 'To',
            dataIndex: 'to',
            className: 'text-center',
            ellipsis: true
        },
        {
            key: 'price',
            title: 'Price',
            dataIndex: 'price',
            className: 'text-center',
            ellipsis: true
        },
        {
            key: 'status',
            title: 'Status',
            dataIndex: 'status',
            className: 'text-center',
            ellipsis: true,
            render: (_, record) => {
                const from = moment(record.from, 'yyyy-MM-dd HH:mm')
                const to = moment(record.to, 'yyyy-MM-dd HH:mm')
                const now = moment()

                const isOvercome = now.isAfter(to)
                const isInRange = now.isAfter(from) && now.isBefore(to)

                return (
                    <div className="d-flex justify-content-center align-items-center">
                        {isOvercome && <CloseCircleOutlined className="text-danger" />}
                        {isInRange && <p className={record.status.color}>{record.status.text}</p>}
                    </div>
                )
            }
        }
    ]

    return (
        <Table
            bordered
            size="middle"
            pagination={false}
            rowKey={r => r.index}
            title={() => (
                <div className="d-flex gap-2 align-items-center">
                    <ClockCircleOutlined />
                    <span className="fs-6 fw-bold">Cancellation Policies</span>
                </div>
            )}
            columns={columns}
            dataSource={cancellations}
        />
    )
}
