import React, { useContext, useState, useMemo } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { Card, Typography, Button, Tooltip, Row, Col, Spin, Table, Input, Space, Popover, DatePicker } from 'antd'
import {
    ReloadOutlined,
    PlusOutlined,
    DownloadOutlined,
    SearchOutlined,
    FilterOutlined,
    CalendarOutlined,
    SendOutlined,
    BarChartOutlined
} from '@ant-design/icons'
import _ from 'lodash'
import moment from 'moment'
import ViewsBookingColumns from './ViewsBookingColumns'
import { ManageBookingsContext } from './index'

export default function Bookings() {
    const history = useHistory()

    const {
        viewColumnKey,
        product,
        canCreate,
        canExport,
        loading,
        emitter,
        visibleColumns,
        selectedViewColumns,
        dates,
        filterBookings,
        onFilterQuery,
        onAdd,
        onReload,
        onOpenExport,
        onOpenChartViewer,
        onApplyViewColumns,
        onOpenEditViewColumns,
        onUseDefaultColumns,
        onToggleFilter,
        onChangeSelectedBookings,
        onChangeDate,
        onSendToTelegram
    } = useContext(ManageBookingsContext)

    const [query, setQuery] = useState([])

    const onDebounceChangeQuery = _.debounce(value => setQuery(value), 200)

    const bookings = useMemo(() => onFilterQuery(query, filterBookings), [query, filterBookings, onFilterQuery])

    const columns = useMemo(
        () =>
            visibleColumns.map(column => {
                if (column.openLink) {
                    column.render = (name, record) => {
                        if (record.status && record.status.toLowerCase() !== 'failed') {
                            return (
                                <Link
                                    className="text-truncate"
                                    to="/"
                                    onClick={e => {
                                        e.preventDefault()
                                        onAdd(name, record)
                                    }}>
                                    {name}
                                </Link>
                            )
                        }

                        return name
                    }
                }

                return column
            }),
        [visibleColumns]
    )

    return (
        <Card
            title={<Typography.Title level={3}>Booking</Typography.Title>}
            extra={
                <Tooltip title="Refresh">
                    <Button disabled={loading} icon={<ReloadOutlined />} onClick={onReload} />
                </Tooltip>
            }>
            <Row gutter={[16, 16]}>
                <Col sm={24} md={24} lg={24} xl={12}>
                    <div className="d-flex gap-2 justify-content-between justify-content-xl-start algin-items-center">
                        <Button
                            disabled={!canCreate}
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => history.push(`/${product}/create-booking`)}>
                            New Booking
                        </Button>
                        <Button disabled={!canExport} type="info" icon={<DownloadOutlined />} onClick={onOpenExport}>
                            Export to CSV
                        </Button>
                        <Button disabled type="info" icon={<SendOutlined />} onClick={onSendToTelegram}>
                            Send To Telegram
                        </Button>
                    </div>
                </Col>
                <Col sm={24} md={24} lg={24} xl={12}>
                    <Row gutter={[8]}>
                        <Col className="flex-grow-1">
                            <Input
                                className="w-100"
                                allowClear
                                placeholder="Search"
                                prefix={<SearchOutlined />}
                                onChange={e => onDebounceChangeQuery(e.target.value)}
                            />
                        </Col>
                        <Col className="d-flex flex-shrink-0">
                            <Space size="middle">
                                <Tooltip title="Calendar">
                                    <Popover
                                        trigger="hover"
                                        placement="bottom"
                                        content={
                                            <DatePicker.RangePicker
                                                showTime={false}
                                                allowClear={false}
                                                format="YYYY-MM-DD"
                                                placement="bottomLeft"
                                                value={dates}
                                                onChange={onChangeDate}
                                                disabledDate={date => date && date > moment().endOf('day')}
                                                ranges={{
                                                    Today: [moment(), moment()],
                                                    'This Week': [moment().startOf('week'), moment().endOf('week')],
                                                    'Last Week': [
                                                        moment()
                                                            .startOf('week')
                                                            .subtract(7, 'days'),
                                                        moment()
                                                            .endOf('week')
                                                            .subtract(7, 'days')
                                                    ],
                                                    'This Month': [moment().startOf('month'), moment().endOf('month')]
                                                }}
                                            />
                                        }>
                                        <Button icon={<CalendarOutlined />} />
                                    </Popover>
                                </Tooltip>
                                <Tooltip title="Filter">
                                    <Button icon={<FilterOutlined />} onClick={onToggleFilter} />
                                </Tooltip>
                                <Tooltip title="Chart">
                                    <Button icon={<BarChartOutlined />} onClick={onOpenChartViewer} />
                                </Tooltip>
                                <ViewsBookingColumns
                                    emitter={emitter}
                                    viewKey={viewColumnKey}
                                    selectedView={selectedViewColumns}
                                    onApplyViewColumns={onApplyViewColumns}
                                    onOpenEditViewColumns={onOpenEditViewColumns}
                                    onUseDefaultColumns={onUseDefaultColumns}
                                />
                            </Space>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <br />
            <Spin spinning={loading}>
                <Table
                    bordered
                    rowKey={r => r.id}
                    columns={columns}
                    dataSource={bookings}
                    pagination={true}
                    scroll={{ x: 'max-content' }}
                    rowSelection={{
                        onChange: (_, rows) => onChangeSelectedBookings(rows)
                    }}
                />
            </Spin>
        </Card>
    )
}
