import React, { useState, useRef, useEffect } from 'react'
import { Row, Col, Typography, Select } from 'antd'
import * as services from 'services'

export default function FilterSupplier({ product, selectedSuppliers, onChangeFilter }) {
    const [loading, setLoading] = useState(false)
    const [suppliers, setSuppliers] = useState([])
    const ref = useRef(null)

    useEffect(() => {
        setLoading(true)

        services.suppliers
            .getSuppliers({ product })
            .then(suplliers => setSuppliers(suplliers))
            .finally(() => {
                setLoading(false)
            })
    }, [product])

    const onClickTitle = () => {
        if (ref && ref.current) {
            ref.current.focus()
        }
    }

    const onFilterOption = (query, option) => RegExp('.*' + query, 'gi').test(option.label)

    return (
        <Row gutter={[8, 8]}>
            <Col span={8}>
                <Typography.Text strong ellipsis onClick={onClickTitle}>
                    Suppliers:
                </Typography.Text>
            </Col>
            <Col span={16}>
                <Select
                    ref={ref}
                    className="w-100 select-overflow text-center"
                    placeholder="Choose Suppliers"
                    showSearch
                    allowClear
                    mode="multiple"
                    loading={loading}
                    value={selectedSuppliers}
                    filterOption={onFilterOption}
                    onChange={onChangeFilter}
                    options={suppliers.map(supplier => ({
                        label: supplier.name,
                        value: supplier.id
                    }))}
                />
            </Col>
        </Row>
    )
}
