/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Card, Input, notification, Col, Row, InputNumber } from 'antd'
import axios from 'axios'
import _ from 'lodash'

import FormDialog from '../../components/FormDialog'
import HotelList from '../../components/HotelList'

import apiErrorHandler from '../../api-error-handler'
import appConfig from '../../config'

const PRODUCT = 'hotel'

const columns = [
    {
        key: 'hotel_id',
        dataIndex: 'hotel_id',
        title: 'Hotel Id'
    },
    {
        key: 'name',
        dataIndex: 'name',
        title: 'Name'
    },
    {
        key: 'city',
        dataIndex: 'city',
        title: 'City'
    },
    {
        key: 'country',
        dataIndex: 'country',
        title: 'Country'
    },
    {
        key: 'star_rating',
        dataIndex: 'star_rating',
        title: 'Star'
    }
]

function HotelManagement({ form, location, history }) {
    const apiUrl = `${appConfig.apiUrl}/${PRODUCT}`
    const [hotels, setHotels] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrenPage] = useState(1)
    const [formState, _setFormState] = useState('HIDE')
    const hotelForm = useRef()
    const headers = JSON.parse(localStorage.getItem('headers') || '{}')

    const getHotels = (current, pageSize, optionSearch, inputSearch) => {
        const params = {
            limit: pageSize || 1,
            page: current || 10,
            optionSearch,
            inputSearch
        }

        if (!inputSearch || inputSearch == '') {
            delete params.optionSearch
            delete params.inputSearch
        }

        return axios({
            method: 'post',
            url: `${apiUrl}/hotels/get-paging`,
            headers,
            data: params
        })
            .then(({ data }) => {
                if (data) {
                    const pagination = data.pagination
                    if (pagination.total) {
                        setTotalRecord(pagination.total)
                    }
                    setCurrenPage(pagination.currentPage)
                    setHotels(data.hotels)
                }
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    useEffect(() => {
        getHotels(1, 10)
    }, [])

    const onSave = () => {
        return new Promise(resolve => {
            form.validateFields((err, values) => {
                const data = { ...values }

                if (err) {
                    notification.error({
                        message: 'Error!!!',
                        description: err
                    })
                    return
                }

                let options = {
                    headers,
                    data,
                    method: 'post'
                }

                switch (hotelForm.current.formState) {
                    case 'CREATE':
                        options.url = `${apiUrl}/hotels/create`
                        break
                    case 'UPDATE': {
                        options.url = `${apiUrl}/hotels/update/${data.hotel_id}`
                        break
                    }
                    default:
                        notification.warning({
                            message: 'Warning!!!',
                            description: 'Unknow action'
                        })
                        return Promise.resolve('Unknow action')
                }

                return axios(options)
                    .then(() => {
                        setFormState('HIDE')
                        notification.success({
                            message: 'Successful!!!',
                            description:
                                (hotelForm.current.formState === 'CREATE' ? 'Created' : 'Updated') +
                                ' user: ' +
                                values.email
                        })
                        return getHotels()
                    })
                    .catch(error =>
                        notification.error({
                            message: 'Error!!!',
                            description: apiErrorHandler(error)
                        })
                    )
                    .then(() => {
                        resolve()
                    })
            })
        })
    }

    const formItemLayout = {
        labelCol: { span: 10 },
        wrapperCol: { span: 14 }
    }

    const formItemTextArea = {
        labelCol: { span: 5 },
        wrapperCol: { span: 19 }
    }
    const { getFieldDecorator } = form
    const { TextArea } = Input

    const setFormState = (state, values = {}) => {
        const formFields = [
            'hotel_id',
            'giata_id',
            'name',
            'brand',
            'chain',
            'address',
            'city',
            'country',
            'star_rating',
            'postal_code',
            'description',
            'accommodation_type',
            'checkin_instructions',
            'special_checkin_instructions',
            'lat',
            'lng',
            'phone',
            'fax',
            'email',
            'website',
            'checkin',
            'checkout',
            'nationality_restrictions'
        ]

        if (['CREATE', 'HIDE'].includes(state)) {
            form.resetFields()
        } else {
            const fields = _.pick(values, formFields)
            form.setFieldsValue(fields)
        }

        hotelForm.current.setFormState(state)
        _setFormState(state)
    }

    return (
        <div>
            <Card>
                <HotelList
                    hotels={hotels}
                    getHotels={getHotels}
                    totalRecord={totalRecord}
                    currentPage={currentPage}
                    product={PRODUCT}
                    columns={columns}
                    setFormState={setFormState}
                />
                <FormDialog
                    style={{ minWidth: '900px' }}
                    closeHandler={() => setFormState('HIDE')}
                    saveHandler={onSave}
                    ref={hotelForm}>
                    <Form {...formItemLayout}>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Hotel Id" hasFeedback>
                                    {getFieldDecorator('hotel_id')(<InputNumber disabled={formState === 'UPDATE'} />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Giata Id" hasFeedback>
                                    {getFieldDecorator('giata_id')(<InputNumber />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Name" hasFeedback>
                                    {getFieldDecorator('name', {
                                        rules: [
                                            {
                                                type: 'string',
                                                message: 'Name is required!'
                                            },
                                            { required: true, message: 'Please enter your name!' }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Brand" hasFeedback>
                                    {getFieldDecorator('brand', {
                                        rules: [{ type: 'string' }]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Chain" hasFeedback>
                                    {getFieldDecorator('chain', {
                                        rules: [{ type: 'string' }]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Address">
                                    {getFieldDecorator('address', {
                                        rules: [
                                            {
                                                type: 'string',
                                                message: 'Address is required!'
                                            },
                                            { required: true, message: 'Please enter your address!' }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="City">
                                    {getFieldDecorator('city', {
                                        rules: [{ type: 'string' }]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Country">
                                    {getFieldDecorator('country', {
                                        rules: [{ type: 'string' }]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Star Rating">
                                    {getFieldDecorator('star_rating')(<InputNumber />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Postal Code">
                                    {getFieldDecorator('postal_code', {
                                        rules: [{ type: 'string' }]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Nationality Restrictions">
                                    {getFieldDecorator('nationality_restrictions', {
                                        rules: [{ type: 'string' }]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Accommodation Type">
                                    {getFieldDecorator('accommodation_type', {
                                        rules: [{ type: 'string' }]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Checkin Instructions">
                                    {getFieldDecorator('checkin_instructions', {
                                        rules: [{ type: 'string' }]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Special Checkin Instructions">
                                    {getFieldDecorator('special_checkin_instructions', {
                                        rules: [{ type: 'string' }]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Lat">{getFieldDecorator('lat')(<InputNumber />)}</Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Lng">{getFieldDecorator('lng')(<InputNumber />)}</Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Phone">
                                    {getFieldDecorator('phone', {
                                        rules: [{ type: 'string' }]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Fax">
                                    {getFieldDecorator('fax', {
                                        rules: [{ type: 'string' }]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Email">
                                    {getFieldDecorator('email', {
                                        rules: [{ type: 'string' }]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Website">
                                    {getFieldDecorator('website', {
                                        rules: [{ type: 'string' }]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Checkin">
                                    {getFieldDecorator('checkin', {
                                        rules: [{ type: 'string' }]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Checkout">
                                    {getFieldDecorator('checkout', {
                                        rules: [{ type: 'string' }]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item label="Description" {...formItemTextArea}>
                                    {getFieldDecorator('description', {
                                        rules: [{ type: 'string' }]
                                    })(<TextArea />)}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </FormDialog>
            </Card>
        </div>
    )
}

export default Form.create({ name: 'hotel-management' })(HotelManagement)
