import React from 'react'
import { Card, Image, Row, Tag, Col } from 'antd'
import { BOOKING_STATUS_COLORS } from 'constant'

export default function BookingSummary({ row }) {
    const logo = require(`assets/airlines/logo/${row.flight_number.slice(0, 2)}.png`)

    return (
        <Card type="inner" size="small" title={<p className="fs-5 fw-bold">Summary</p>}>
            <Row gutter={[0, 16]} className="d-flex flex-column flex-md-row">
                <Row gutter={8} className="d-flex flex-column justify-content-start flex-grow-1">
                    <Row gutter={8}>
                        <Col sm={6} className="d-flex flex-column">
                            <p className="fw-lighter">Departure Airport</p>
                            <p>{row.departure_airport}</p>
                        </Col>

                        <Col sm={6} className="d-flex flex-column">
                            <p className="fw-lighter">Arrival Airport</p>
                            <p>{row.arrival_airport}</p>
                        </Col>

                        <Col sm={6} className="d-flex flex-column">
                            <p className="fw-lighter">Departure Date</p>
                            <p>{!row.departure_date ? '-/-' : row.departure_date}</p>
                        </Col>

                        <Col sm={6} className="d-flex flex-column">
                            <p className="fw-lighter">Departure Airport</p>
                            <p>{!row.arrival_date ? '-/-' : row.arrival_date}</p>
                        </Col>
                    </Row>

                    <Row gutter={8}>
                        <Col sm={6} className="d-flex flex-column">
                            <p className="fw-lighter">PNR</p>
                            <p className="fw-bold">{!row.pnr ? '-/-' : row.pnr}</p>
                        </Col>

                        <Col sm={6} className="d-flex flex-column">
                            <p className="fw-lighter">Status</p>
                            <p>
                                <Tag color={BOOKING_STATUS_COLORS.FLIGHT[row.status]}>{row.status}</Tag>
                            </p>
                        </Col>

                        <Col sm={6} className="d-flex flex-column">
                            <p className="fw-lighter">Amount</p>
                            <p className="text-danger">
                                {!row.currency_code || !row.amount ? '-/-' : `${row.currency_code} ${row.amount}`}
                            </p>
                        </Col>

                        <Col sm={6} className="d-flex flex-column">
                            <p className="fw-lighter">Provider</p>
                            <p className="fw-bold">{row.supplier_code}</p>
                        </Col>
                    </Row>
                </Row>

                <Row className="d-flex flex-shrink-0 justify-content-center align-items-center mx-auto">
                    <Image width={250} alt="airline_code" preview={false} src={logo} />
                </Row>
            </Row>
        </Card>
    )
}
