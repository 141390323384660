import React, { useCallback, useEffect, useState } from 'react'
import { Modal, Select } from 'antd'
import * as services from 'services'

export default function OwnerAdd({ isOpen, form, onClose, onAdd }) {
    const [loading, setLoading] = useState(false)
    const [owners, setOwners] = useState([])
    const [owner, setOwner] = useState(-1)

    const fetchOwners = useCallback(async () => {
        setLoading(true)

        try {
            let options = []

            if (form.mode === 'users') {
                options = await services.users.getUsers({ product: form.product })
                options = options.map(o => ({
                    label: o.email,
                    value: o.id
                }))
            }

            if (form.mode === 'suppliers') {
                options = await services.suppliers.getSuppliers({ product: form.product })
                options = options.map(o => ({
                    label: o.name,
                    value: o.id
                }))
            }

            setOwners(options)
        } finally {
            setLoading(false)
        }
    }, [form.mode, form.product])

    useEffect(() => {
        fetchOwners()
    }, [fetchOwners])

    const onOk = () => {
        const obj = {
            userId: form.userId,
            mode: form.mode,
            objectId: owner
        }

        onAdd(obj)
    }

    const onFilterOption = (query, option) => RegExp('.*' + query, 'gi').test(option.label)

    return (
        <Modal
            open={isOpen}
            title="Add Owner"
            centered
            onCancel={onClose}
            onOk={onOk}
            okText="Save"
            okButtonProps={{
                disabled: owner === -1
            }}>
            <Select
                className="w-100"
                placeholder="Choose owner"
                showSearch
                loading={loading}
                options={owners}
                onChange={setOwner}
                filterOption={onFilterOption}
            />
        </Modal>
    )
}
