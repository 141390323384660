import React from 'react'
import { Drawer } from 'antd'
import _ from 'lodash'
import { EXPORT_TITLE } from 'constant'
import Exporter from './Exporter'

export default function ExportLayout({ resource, data, isOpen, onCloseExport, ...props }) {
    const fields = data.length
        ? _.chain(data[0])
              .keys()
              .map(field => {
                  return {
                      group: null,
                      name: field
                  }
              })
              .value()
        : []

    return (
        <Drawer
            title={EXPORT_TITLE[resource]}
            destroyOnClose
            size="large"
            placement="right"
            open={isOpen}
            onClose={onCloseExport}
            {...props}>
            <Exporter resource={resource} data={data} fields={fields} />
        </Drawer>
    )
}
