import { getAuth } from '../../@misc'
import { createAction } from '@'
import axios from 'axios'
import querystring from 'querystring'
import { $hotelSupplierDestinationMapping } from '../stores'
import appConfig from '../../config'
const apiUrl = `${appConfig.apiUrl}`

export default createAction('GetSupplierDestinationMapping', async mappings => {
    const auth = getAuth()
    const hotelSupplierDestinationMapping = $hotelSupplierDestinationMapping.getState()

    await Promise.all(
        mappings
            .filter(m => m.supplier_code === 'Juniper')
            .map(async item => {
                const key = `${item.supplier_code}-${item.hotel_id}`

                if (hotelSupplierDestinationMapping && hotelSupplierDestinationMapping[key]) {
                    return
                }

                const qs = {
                    supplier_code: item.supplier_code,
                    hotel_id: item.hotel_id
                }

                try {
                    const { data } = await axios({
                        headers: { 'Content-Type': 'application/json', ...auth },
                        url: `${apiUrl}/hotel/mappings/supplier-region-mappings?${querystring.stringify(qs)}`,
                        method: 'GET'
                    })

                    $hotelSupplierDestinationMapping.setState(s => ({
                        ...s,
                        [key]: data.map(m => m.region_id)
                    }))
                } catch (e) {
                    $hotelSupplierDestinationMapping.setState(s => ({
                        ...s,
                        [key]: []
                    }))
                }
            })
    )
})
