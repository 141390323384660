import React from 'react'
import { Table, Tag } from 'antd'
import _ from 'lodash'
import { BOOKING_STATUS_COLORS } from 'constant'
import BookingTravellers from './booking-travellers'
import BookingCancellationPolicies from './booking-cancellation-policies'

export default function BookingRooms({ loading, booking }) {
    const columns = [
        {
            key: 'idx',
            dataIndex: 'idx',
            title: 'Room Number',
            className: 'text-center',
            ellipsis: true
        },
        {
            key: 'status',
            dataIndex: 'status',
            title: 'Status',
            className: 'text-center',
            ellipsis: true,
            render: (_, record) => <Tag color={BOOKING_STATUS_COLORS.HOTEL[record.status]}>{record.status}</Tag>
        },
        {
            key: 'room_name',
            dataIndex: 'room_name',
            title: 'Room Name',
            className: 'text-center',
            ellipsis: true
        },
        {
            key: 'board_basis',
            dataIndex: 'board_basis',
            title: 'Board Basis',
            className: 'text-center',
            ellipsis: true
        },
        {
            key: 'price',
            dataIndex: 'price',
            title: 'Price',
            className: 'text-center',
            ellipsis: true,
            render: (_, record) => `${booking.currency} ${record.price}`
        }
    ]

    return (
        <Table
            bordered
            size="middle"
            loading={loading}
            pagination={false}
            rowKey={r => r.idx}
            columns={columns}
            dataSource={_.get(booking, 'rooms', [])}
            expandable={{
                expandedRowRender: room => (
                    <div className="d-flex gap-2 flex-column">
                        <BookingTravellers paxes={room.paxes || []} />
                        <BookingCancellationPolicies cancellations={room.cancellations || []} />
                    </div>
                )
            }}
        />
    )
}
