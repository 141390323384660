import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Typography, Button, Modal, Tag, Row } from 'antd'
import { ClearOutlined, SearchOutlined } from '@ant-design/icons'
import _ from 'lodash'
import moment from 'moment'
import * as services from 'services'
import { BOOKING_STATUS_COLORS } from 'constant'
import BookingSummary from './booking-summary'
import BookingRooms from './booking-rooms'

export default function BookingForm({ row }) {
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [booking, setBooking] = useState(null)

    const handleFetch = useCallback(() => {
        setLoading(true)

        services.bookings
            .getBooking({
                product: 'hotel',
                bookingId: row.id,
                params: {
                    reference: row.reference
                }
            })
            .then(booking => {
                if (booking) {
                    booking.rooms = _.forEach(booking.rooms, room => {
                        _.forEach(room.paxes, (pax, index) => ({
                            ...pax,
                            id: index
                        }))

                        const now = moment()
                        room.cancellations = _.chain(room.cancellations)
                            .map((cancellation, index) => {
                                const from = moment(cancellation.from, 'yyyy-MM-dd HH:mm')
                                const to = moment(cancellation.to, 'yyyy-MM-dd HH:mm')
                                if (now.isAfter(from) && now.isBefore(to)) {
                                    let text = 'Partially Refundable'
                                    let color = 'text-warning'

                                    if (cancellation.amount === 0) {
                                        text = 'Free'
                                        color = 'text-success'
                                    }

                                    if (cancellation.amount === row.price) {
                                        text = 'Non-refundable'
                                        color = 'text-danger'
                                    }

                                    cancellation.status = {
                                        text,
                                        color
                                    }
                                }

                                cancellation.index = index

                                return cancellation
                            })
                            .orderBy(c => moment(c.from, 'yyyy-MM-dd HH:mm'), 'asc')
                            .value()
                    })
                }

                setBooking(booking)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        handleFetch()
    }, [handleFetch])

    const onCancel = async booking => {
        Modal.confirm({
            maskClosable: true,
            title: `Are you sure to cancel booking ${booking.client_ref || booking.reference}`,
            onOk: async () => {
                services.bookings.cancelBooking(booking)
            }
        })
    }

    const onGotoLog = () => {
        const params = {
            app: 'hotel-offer',
            env: process.env.REACT_APP_ENV,
            dates: [moment(booking.booking_date), moment(booking.booking_date)],
            request_id: booking.book_req_id
        }

        const searchParams = new URLSearchParams(params)

        history.push(`/log/log-loki?${searchParams.toString()}`)
    }

    return (
        <Card
            title={
                <div className="d-flex gap-2 align-items-center">
                    <Typography.Title ellipsis level={3}>
                        {row.client_ref}
                    </Typography.Title>
                    <Tag closable={false} color={BOOKING_STATUS_COLORS.HOTEL[row.status]}>
                        {row.status}
                    </Tag>
                </div>
            }
            extra={
                <div className="d-flex gap-2 align-items-center">
                    <Button
                        disabled={
                            !(
                                moment().isBefore(moment(row.cancellation_deadline)) &&
                                ['Confirmed', 'Received', 'Pending'].includes(row.status)
                            )
                        }
                        key="__cancel__"
                        type="danger"
                        icon={<ClearOutlined />}
                        onClick={() => onCancel(row)}>
                        Cancel
                    </Button>
                    <Button key="__log__" type="info" icon={<SearchOutlined />} onClick={onGotoLog}>
                        Logs
                    </Button>
                </div>
            }>
            <Card type="inner" size="small">
                <Row gutter={16} className="d-flex flex-column">
                    <BookingSummary row={row} />
                </Row>

                <Card type="inner" size="small" title={<p className="fs-5 fw-bold">Rooms</p>}>
                    <BookingRooms loading={loading} booking={booking} />
                </Card>
            </Card>
        </Card>
    )
}
