import React from 'react'
import { Table } from 'antd'

export default function BookingTravellers({ loading, paxes }) {
    const columns = [
        {
            key: 'title',
            dataIndex: 'title',
            title: 'Title',
            className: 'text-center',
            ellipsis: true
        },
        {
            key: 'first_name',
            dataIndex: 'first_name',
            title: 'First Name',
            className: 'text-center',
            ellipsis: true
        },
        {
            key: 'last_name',
            dataIndex: 'last_name',
            title: 'Last Name',
            className: 'text-center',
            ellipsis: true
        },
        {
            key: 'eticket_no',
            dataIndex: 'eticket_no',
            title: 'E-Ticket',
            className: 'text-center',
            ellipsis: true
        },
        {
            key: 'emd_no',
            dataIndex: 'emd_no',
            title: 'EMD',
            className: 'text-center',
            ellipsis: true
        }
    ]

    const nestColumns = [
        {
            key: 'flight_no',
            dataIndex: 'flight_no',
            title: 'Flight No',
            className: 'text-center',
            ellipsis: true
        },
        {
            key: 'departure_from',
            dataIndex: 'departure_from',
            title: 'Departure',
            className: 'text-center',
            ellipsis: true
        },
        {
            key: 'departure_time',
            dataIndex: 'departure_time',
            title: 'Departure Time',
            className: 'text-center',
            ellipsis: true
        },
        {
            key: 'arrival_from',
            dataIndex: 'arrival_from',
            title: 'Arrival',
            className: 'text-center',
            ellipsis: true
        },
        {
            key: 'arrival_time',
            dataIndex: 'arrival_time',
            title: 'Arrival Time',
            className: 'text-center',
            ellipsis: true
        },
        {
            key: 'meal',
            dataIndex: 'meal',
            title: 'Meal',
            className: 'text-center',
            ellipsis: true
        },
        {
            key: 'seat',
            dataIndex: 'seat',
            title: 'Seat',
            className: 'text-center',
            ellipsis: true
        },
        {
            key: 'baggage',
            dataIndex: 'baggage',
            title: 'Baggage',
            className: 'text-center',
            ellipsis: true
        }
    ]

    return (
        <Table
            bordered
            size="middle"
            loading={loading}
            pagination={false}
            rowKey={r => r.pax_id}
            columns={columns}
            dataSource={paxes}
            expandable={{
                expandedRowRender: record => (
                    <Table
                        bordered
                        size="middle"
                        pagination={false}
                        rowKey={r => r.flight_no}
                        columns={nestColumns}
                        dataSource={record.ancillaries}
                    />
                )
            }}
        />
    )
}
