import React, { useEffect, useContext, useState } from 'react'
import { Form, Modal, Row, Switch, Col, Input, Button, Tooltip, AutoComplete } from 'antd'
import { HistoryOutlined, EditOutlined, CopyOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { isObject, safeObjectToString } from '@/common'
import FormLayout from 'components/FormLayout'
import ManageSuppliers, { ManageSuppliersContext } from 'components/ManageSuppliers'

export default function TourSuppliers() {
    return (
        <ManageSuppliers product="tour" resource="tour-supplier">
            <InternalTourSuppliers />
        </ManageSuppliers>
    )
}

function InternalTourSuppliers() {
    const { canEdit, onRegisterColumns, onOpenEditForm, onSelectSupplier, onCopySupplier, dispatchEvent } = useContext(
        ManageSuppliersContext
    )

    const onShowChangeStatusConfirm = record => {
        Modal.confirm({
            maskClosable: true,
            title: `Are you sure to turn ${record.active ? 'off' : 'on'} ${record.name}`,
            onOk: async () => {
                dispatchEvent('status', record)
            }
        })
    }

    const columns = [
        {
            key: 'code',
            dataIndex: 'code',
            title: 'Code',
            defaultSortOder: 'ascend'
        },
        {
            key: 'name',
            dataIndex: 'name',
            title: 'Name',
            width: '20%',
            ellipsis: true
        },
        {
            key: 'status',
            dataIndex: 'active',
            title: 'Active',
            render: (_, record) => (
                <Switch disabled={!canEdit} checked={record.active} onClick={() => onShowChangeStatusConfirm(record)} />
            )
        },
        {
            key: 'action',
            title: 'Action',
            render: (_, record) => {
                return (
                    <Row gutter={[8, 8]}>
                        <Col>
                            <Tooltip title="Edit">
                                <Button
                                    ghost
                                    type="primary"
                                    icon={<EditOutlined />}
                                    disabled={!canEdit}
                                    onClick={() => onOpenEditForm(record)}
                                />
                            </Tooltip>
                        </Col>
                        <Col>
                            <Tooltip title="Show Log">
                                <Button
                                    type="dashed"
                                    icon={<HistoryOutlined />}
                                    onClick={() => onSelectSupplier(record)}
                                />
                            </Tooltip>
                        </Col>
                        <Col>
                            <Tooltip title="Copy">
                                <Button icon={<CopyOutlined />} onClick={() => onCopySupplier(record)} />
                            </Tooltip>
                        </Col>
                    </Row>
                )
            }
        }
    ]

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => onRegisterColumns(columns), [])

    return (
        <React.Fragment>
            <TourSupplierForm />
        </React.Fragment>
    )
}

function TourSupplierForm() {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const { suppliers, formState, onHideForm, onSave, onReload } = useContext(ManageSuppliersContext)

    const fields = ['name', 'code', 'username', 'password', 'email', 'api_key', 'shared_secret', 'url', 'custom_config']

    useEffect(() => {
        form.resetFields()

        if (formState.action === 'edit') {
            const fieldValues = _.pick(formState.payload, fields)
            fieldValues.custom_config = safeObjectToString(fieldValues.custom_config)
            form.setFieldsValue(fieldValues)
        }
    }, [formState])

    const onSubmit = () => {
        form.validateFields().then(fieldsValue => {
            setLoading(true)

            onSave(fieldsValue)
                .then(() => {
                    setLoading(false)
                    onHideForm()
                    onReload()
                })
                .catch(() => {
                    setLoading(false)
                })
        })
    }

    const onPaste = () => {}

    return (
        <FormLayout
            type="supplier"
            action={formState.action}
            open={formState.action === 'create' || formState.action === 'edit'}
            loading={loading}
            onCloseForm={onHideForm}
            onSubmit={onSubmit}
            onPaste={onPaste}>
            <Form form={form} colon={false} layout="vertical">
                <Row gutter={16}>
                    <Col md={12}>
                        <Form.Item
                            label="Code"
                            name="code"
                            validateTrigger="onBlur"
                            hasFeedback
                            required
                            rules={[
                                {
                                    required: true,
                                    message: 'Supplier code is required'
                                }
                            ]}>
                            <AutoComplete
                                showSearch
                                disabled={formState.action === 'edit'}
                                filterOption={(input, option) => RegExp('.*' + input, 'gi').test(option.label)}
                                options={_.chain(suppliers)
                                    .unionBy(s => s.code)
                                    .map(s => ({
                                        label: s.code,
                                        value: s.code
                                    }))
                                    .value()}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col md={12}>
                        <Form.Item label="Username" name="username" hasFeedback>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label="Password" name="password" hasFeedback>
                            <Input.Password />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col md={12}>
                        <Form.Item
                            label="Name"
                            name="name"
                            validateTrigger="onBlur"
                            hasFeedback
                            required
                            rules={[
                                {
                                    required: true,
                                    message: 'Name is required'
                                }
                            ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label="Email" name="email">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col md={24}>
                        <Form.Item
                            label="Url"
                            name="url"
                            validateTrigger="onBlur"
                            hasFeedback
                            required
                            rules={[
                                {
                                    required: true,
                                    message: 'URL is required'
                                }
                            ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col md={12}>
                        <Form.Item label="Shared Secret" name="shared_secret">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label="API Key" name="api_key">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col md={12}>
                        <Form.Item
                            label="Custom Config"
                            name="custom_config"
                            validateTrigger="onBlur"
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (value && !isObject(value)) {
                                            return Promise.reject('Invalid object')
                                        }

                                        return Promise.resolve()
                                    }
                                }
                            ]}>
                            <Input.TextArea
                                rows={6}
                                onChange={e =>
                                    form.setFieldsValue({
                                        custom_config: !e.target.value ? null : e.target.value
                                    })
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </FormLayout>
    )
}
