import React, { useMemo, useState } from 'react'
import { Drawer, List, Popover, Tag, Input } from 'antd'
import { DeleteOutlined, EditOutlined, MoreOutlined, StarFilled, SearchOutlined } from '@ant-design/icons'
import _ from 'lodash'
import ViewExportForm from './ViewExportForm'

export default function ViewsExport({
    isOpen,
    loading,
    viewKey,
    views,
    exportFields,
    onClose,
    onSelectField,
    onShowConfirmDeleteView,
    onHandleToggleFavoriteView
}) {
    const [editView, setEditView] = useState(null)
    const [query, setQuery] = useState('')

    const filterViews = useMemo(() => views.filter(view => RegExp('.*' + query, 'gi').test(view.title)), [views, query])

    const onDebounceChangeQuery = _.debounce(value => setQuery(value), 200)

    const onViewClick = view => {
        const fields = _.intersectionBy(view.fields, exportFields, f => f.key)
        const fieldKeys = fields.map(f => f.key)
        onSelectField(fieldKeys)
        onClose()
    }

    const onCloseView = () => {
        setEditView(null)
    }

    const buildPopoverContent = view => (
        <React.Fragment>
            <div
                key="__edit__"
                className="my-1 px-2 py-1 d-flex align-items-center popover-item"
                role="button"
                onClick={e => {
                    e.stopPropagation()
                    setEditView(view)
                }}>
                <EditOutlined />
                <span className="mx-2">Edit View</span>
            </div>
            <div
                key="__remove__"
                className="my-1 px-2 py-1 d-flex align-items-center popover-item"
                role="button"
                onClick={e => {
                    e.stopPropagation()
                    onShowConfirmDeleteView(view)
                }}>
                <DeleteOutlined className="text-danger" />
                <span className="mx-2">Delete View</span>
            </div>
        </React.Fragment>
    )

    return (
        <React.Fragment>
            <Drawer title="Manage Views" size="large" placement="right" open={isOpen} onClose={onClose}>
                <List
                    className="overflow-auto"
                    loading={loading}
                    rowKey={r => r.id}
                    dataSource={filterViews}
                    itemLayout="horizontal"
                    locale={{
                        emptyText: 'No views found'
                    }}
                    header={
                        <Input
                            size="middle"
                            allowClear
                            placeholder="Search"
                            prefix={<SearchOutlined role="button" style={{ opacity: 0.7 }} />}
                            onChange={e => onDebounceChangeQuery(e.target.value)}
                        />
                    }
                    renderItem={view => {
                        return (
                            <List.Item
                                onClick={() => onViewClick(view)}
                                actions={[
                                    <StarFilled
                                        key="__favorite__"
                                        role="button"
                                        className={`fs-5 ${view.is_favorite ? 'text-warning' : ''}`}
                                        onClick={e => {
                                            e.stopPropagation()
                                            onHandleToggleFavoriteView(view)
                                        }}
                                    />,
                                    <Popover
                                        overlayClassName="ant-custom-popover-inner-content"
                                        key="__action__"
                                        trigger="hover"
                                        placement="left"
                                        showArrow={false}
                                        content={() => buildPopoverContent(view)}>
                                        <MoreOutlined role="button" className="fs-5" />
                                    </Popover>
                                ]}>
                                <List.Item.Meta title={view.title} description={view.description} />
                                <Tag className="d-block" color="cyan">
                                    {view.fields.length} {view.fields.length > 1 ? 'filters' : 'filter'}
                                </Tag>
                            </List.Item>
                        )
                    }}
                />
            </Drawer>

            {!!editView && (
                <ViewExportForm
                    isEdit={true}
                    isOpen={!!editView}
                    viewKey={viewKey}
                    view={editView}
                    exportFields={exportFields}
                    onClose={onCloseView}
                />
            )}
        </React.Fragment>
    )
}
