import React from 'react'
import { Link } from 'react-router-dom'
import { CalendarOutlined, SyncOutlined, UserOutlined, DownloadOutlined } from '@ant-design/icons'
import { notification, Table, Card, Col, Row, Button, Modal, Tabs, Input, Popover, Spin, Select } from 'antd'
import axios from 'axios'
import qs from 'querystring'
import { startOfWeek, format } from 'date-fns'

import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import { CSVLink } from 'react-csv'
import { DateRangePicker, DateRange } from 'react-date-range'
import appConfig from '../config'
import apiErrorHandler from '../api-error-handler'
import { filterTable } from '@/search'
import _ from 'lodash'

const { TabPane } = Tabs
const headers = JSON.parse(localStorage.getItem('headers') || '{}')
let apiUrl = ''

class Booking extends React.Component {
    constructor(props) {
        super(props)
        apiUrl = `${appConfig.apiUrl}/${props.product}`

        this.state = {
            headers: null,
            loading: false,
            bookings: [],
            filter: {
                input: '',
                status: '',
                userIds: []
            },
            filterBookings: [],
            panes: {},
            dateRange: {
                selection: {
                    startDate: startOfWeek(new Date(), { weekStartsOn: 2 }),
                    endDate: new Date(),
                    key: 'selection'
                }
            },
            activeTab: 'filter',
            screenWidth: window.innerWidth,
            cancelledBooking: null,
            users: [],
            bookingsDownload: []
        }
    }

    componentDidMount() {
        window.addEventListener('resize', () => this.setState({ screenWidth: window.innerWidth }))
        this.getData()
    }

    onFilter = () => {
        let bookings = this.state.bookings
        Object.entries(this.state.filter).forEach(([key, value]) => {
            if (key === 'input' && value) {
                bookings = filterTable(value, bookings, this.props.columns)
            }

            if (key === 'status' && value) {
                bookings = bookings.filter(b => b.status === value)
            }

            if (key === 'userIds' && value.length) {
                bookings = bookings.filter(b => value.includes(b.ins_user_id))
            }
        })

        this.setState({ filterBookings: bookings })
    }

    async getUsers() {
        const url = `${apiUrl}/users`
        const axiosConfig = {
            url,
            method: 'get',
            headers
        }
        let { data: users } = await axios(axiosConfig)
        users = _.map(users, u => ({
            id: u.id,
            email: u.email
        }))

        return users
    }

    async getBookings() {
        const params = {
            from: format(this.state.dateRange.selection.startDate, 'yyyy-MM-dd'),
            to: format(this.state.dateRange.selection.endDate, 'yyyy-MM-dd')
        }

        const url = `${apiUrl}/bookings?${qs.stringify(params)}`
        const axiosConfig = {
            method: 'get',
            headers,
            url
        }

        const { data: bookings } = await axios(axiosConfig)
        return bookings
    }

    async getData() {
        this.setState({ loading: true })

        try {
            const [users, bookings] = await Promise.all([this.getUsers(), this.getBookings()])
            this.setState(
                state => ({
                    ...state,
                    users,
                    bookings
                }),
                this.onFilter
            )
        } catch (err) {
            apiErrorHandler(err)
        } finally {
            this.setState({ loading: false })
        }
    }

    dateRangeSelect = ranges => {
        this.setState(
            state => ({
                ...state,
                dateRange: ranges
            }),
            () => {
                this.setState({ loading: true })
                this.getBookings()
                    .then(bookings => {
                        this.setState({ bookings }, this.onFilter)
                    })
                    .catch(e => apiErrorHandler(e))
                    .finally(() => {
                        this.setState({ loading: false })
                    })
            }
        )
    }

    datePicker = () => {
        const { screenWidth } = this.state

        if (screenWidth >= 1048) {
            return (
                <DateRangePicker
                    ranges={[this.state.dateRange.selection]}
                    moveRangeOnFirstSelection={false}
                    onChange={this.dateRangeSelect}
                    months={2}
                    maxDate={new Date()}
                    direction="horizontal"
                />
            )
        }
        if (screenWidth > 820) {
            return (
                <DateRange
                    ranges={[this.state.dateRange.selection]}
                    moveRangeOnFirstSelection={false}
                    onChange={this.dateRangeSelect}
                    months={2}
                    maxDate={new Date()}
                    direction="horizontal"
                />
            )
        }
        return (
            <DateRange
                ranges={[this.state.dateRange.selection]}
                moveRangeOnFirstSelection={false}
                onChange={this.dateRangeSelect}
                months={1}
                maxDate={new Date()}
                direction="horizontal"
            />
        )
    }

    bookingStatus = statuses => {
        return (
            <Select
                showSearch
                onChange={status => {
                    this.setState(
                        state => ({
                            ...state,
                            filter: {
                                ...state.filter,
                                status
                            }
                        }),
                        this.onFilter
                    )
                }}
                allowClear={true}
                style={{ minWidth: 150 }}>
                {statuses.map(status => (
                    <Select.Option value={status} key={status}>
                        {status}
                    </Select.Option>
                ))}
            </Select>
        )
    }

    bookingUser = () => {
        return (
            <Select
                mode="multiple"
                allowClear={true}
                showSearch
                style={{ minWidth: 250 }}
                onChange={value => {
                    const userIds = _.map(value, u => _.toNumber(u))
                    this.setState(
                        state => ({
                            ...state,
                            filter: {
                                ...state.filter,
                                userIds
                            }
                        }),
                        this.onFilter
                    )
                }}>
                {this.state.users.map((user, index) => (
                    <Select.Option value={user.id} key={index}>
                        {user.email}
                    </Select.Option>
                ))}
            </Select>
        )
    }

    onCancelBooking = (booking = null) => {
        this.setState({
            cancelledBooking: booking
        })

        Modal.confirm({
            title: `Are you sure to cancel booking ${booking.client_ref || booking.booking_reference}?`,
            onOk: () => this.cancelBooking()
        })
    }

    cancelBooking = async () => {
        const { cancelledBooking, panes } = this.state

        const tabId = cancelledBooking[this.props.attTabId]

        try {
            await axios({
                method: 'delete',
                url: `${apiUrl}/bookings/${cancelledBooking.booking_id}`,
                headers,
                params: {
                    reference: cancelledBooking.reference,
                    supplier_code: cancelledBooking.supplier_code,
                    pnr: cancelledBooking.pnr
                }
            })

            notification.success({
                message: `Booking ${cancelledBooking[this.props.attTabId]} Cancelled!`
            })

            delete panes[tabId]
            this.setState(
                {
                    cancelledBooking: null,
                    panes,
                    activeTab: 'filter'
                },
                this.getBookings
            )
        } catch (err) {
            apiErrorHandler(err)
        }
    }

    add = row => {
        const { panes } = this.state
        const activeTab = row[this.props.attTabId]

        panes[activeTab] = { row, loading: true }

        this.setState({ panes, activeTab })

        axios({
            method: 'get',
            url: `${apiUrl}/bookings/${row.id}`,
            headers,
            params: {
                reference: row.reference,
                booking_id: row.booking_id,
                supplier_code: row.supplier_code,
                pnr: row.pnr
            }
        })
            .then(({ data }) => {
                panes[activeTab].details = data
                panes[activeTab].loading = false

                this.setState({ panes })
            })
            .catch(err => {
                panes[activeTab].details = {}
                panes[activeTab].loading = false

                this.setState({ panes })
                apiErrorHandler(err)
            })
    }

    remove = targetKey => {
        let { activeTab } = this.state
        let lastIndex
        const tabs = Object.keys(this.state.panes)
        tabs.forEach((pane, i) => {
            if (pane === targetKey) {
                lastIndex = i - 1
            }
        })

        const panes = this.state.panes
        delete panes[targetKey]

        if (tabs.length && activeTab === targetKey) {
            if (lastIndex >= 0) {
                activeTab = tabs[lastIndex]
            } else {
                activeTab = 'filter'
            }
        }

        this.setState({ panes, activeTab })
    }

    onChange = activeTab => {
        this.setState({ activeTab })
    }

    onEdit = (targetKey, action) => {
        this[action](targetKey)
    }

    bookingDownloadFileName = () => {
        const from = format(this.state.dateRange.selection.startDate, 'yyyy-MM-dd')
        const to = format(this.state.dateRange.selection.endDate, 'yyyy-MM-dd')
        return `bookings_${from}_${to}.csv`
    }

    render() {
        const columns = this.props.columns
        const { DetailForm, statuses = [] } = this.props

        const expandColumn = columns.find(col => !!col.openLink)
        if (expandColumn) {
            expandColumn.render = (col, row) =>
                row.status && row.status.toLowerCase() !== 'failed' ? (
                    <Link
                        to="/"
                        onClick={event => {
                            event.preventDefault()
                            this.add(row)
                        }}>
                        {col}
                    </Link>
                ) : (
                    col
                )
        }

        return (
            <Tabs
                onChange={this.onChange}
                activeKey={this.state.activeTab}
                type="editable-card"
                tabBarExtraContent={
                    <Button type="link" onClick={() => this.getBookings()} loading={this.state.loading}>
                        Refresh
                    </Button>
                }
                hideAdd={true}
                onEdit={this.onEdit}>
                <TabPane tab="Booking Filter" key="filter" closable={false}>
                    <Card>
                        <Row gutter={8}>
                            <Col md={2}>
                                <Popover placement="bottomLeft" content={this.datePicker()} trigger="click">
                                    <Button type="primary" style={{ width: '100%' }}>
                                        <CalendarOutlined /> Date
                                    </Button>
                                </Popover>
                            </Col>
                            {Array.isArray(statuses) && statuses.length ? (
                                <Col md={2}>
                                    {/* {this.bookingStatus(statuses)} */}
                                    <Popover
                                        placement="bottomLeft"
                                        content={this.bookingStatus(statuses)}
                                        trigger="click">
                                        <Button type="primary" style={{ width: '100%' }}>
                                            <SyncOutlined /> Status
                                        </Button>
                                    </Popover>
                                </Col>
                            ) : null}
                            <Col md={2}>
                                <Popover placement="bottomLeft" trigger="click" content={this.bookingUser()}>
                                    <Button type="primary" style={{ width: '100%' }}>
                                        <UserOutlined /> Users
                                    </Button>
                                </Popover>
                            </Col>
                            <Col flex={1}>
                                <Row justify={'end'}>
                                    <Col md={4} style={{ marginRight: '10px' }}>
                                        <CSVLink
                                            data={this.state.filterBookings}
                                            filename={this.bookingDownloadFileName()}>
                                            <Button type="primary" style={{ width: '100%' }}>
                                                <DownloadOutlined /> Download
                                            </Button>
                                        </CSVLink>
                                    </Col>
                                    <Col md={4}>
                                        <Input
                                            placeholder="Search"
                                            onChange={e => {
                                                this.setState(
                                                    state => ({
                                                        ...state,
                                                        filter: {
                                                            ...state.filter,
                                                            input: e.target.value
                                                        }
                                                    }),
                                                    this.onFilter
                                                )
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                    <br />
                    <Card>
                        <Spin spinning={this.state.loading}>
                            <Table bordered rowKey="id" columns={columns} dataSource={this.state.filterBookings} />
                        </Spin>
                    </Card>
                </TabPane>
                {Object.entries(this.state.panes).map(([tab, pane]) => (
                    <TabPane tab={tab} key={tab}>
                        {DetailForm ? (
                            <Spin spinning={pane.loading}>
                                <DetailForm
                                    booking={pane.row}
                                    details={pane.details}
                                    toggleCancelBookingModal={this.onCancelBooking}
                                />
                            </Spin>
                        ) : null}
                    </TabPane>
                ))}
            </Tabs>
        )
    }
}

export default Booking
