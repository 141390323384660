import _ from 'lodash'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { check } from '../components/can'
import routes from '../routes/frameRouting'
const iframeRender = frameList => {
    const result = []
    frameList.forEach(item => {
        const localItem = { ...item }
        if (localItem.navlabel) {
            const hasPermission = localItem.resources.find(resource => check(resource, localItem.perform))
            if (hasPermission) {
                result.push(localItem)
            }

            return
        }

        if (check(localItem.resource, localItem.perform)) {
            if (localItem.collapse) {
                localItem.children = iframeRender(localItem.routes)
                delete localItem.routes
            }
            result.push(localItem)
        }
        if (localItem.is_frame && !check(localItem.resource, localItem.perform)) {
            // eslint-disable-next-line no-unused-vars
            let topWindow
            let isSameDomain = true
            try {
                topWindow = window.top.location.host
            } catch (e) {
                isSameDomain = false
            }

            const isDebugLocal = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
            const isWhiteListDomain = _.includes(localItem.accessable, x => x.indexOf(window.location.hostname) > -1)
            if (localItem.accessable && localItem.accessable.length > 0) {
                if (isDebugLocal || (isWhiteListDomain && !isSameDomain)) {
                    result.push(localItem)
                }
            } else {
                result.push(localItem)
            }
        }
    })

    return result
}

const Iframe = props => {
    const ableRoutes = iframeRender(routes)
    return (
        <>
            {ableRoutes.map((prop, key) => {
                if (prop.navlabel) {
                    return null
                }
                if (prop.collapse) {
                    return prop.children.map((prop2, key2) => {
                        if (prop2.collapse) {
                            return prop2.children.map((prop3, key3) => (
                                <Route path={prop3.path} component={prop3.component} key={key3} {...props} />
                            ))
                        }

                        return <Route path={prop2.path} component={prop2.component} key={key2} {...props} />
                    })
                }

                if (prop.redirect) {
                    return <Redirect from={prop.path} to={prop.pathTo} key={key} {...props} />
                }

                return <Route path={prop.path} component={prop.component} key={key} {...props} />
            })}
        </>
    )
}

export default Iframe
