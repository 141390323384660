import _ from 'lodash'
import { apiAxios } from './api'

export async function getLogs({ product, resource, objectId }) {
    const promise1 = apiAxios.get(`/logs`, {
        params: {
            path: `/${product}/${resource}/${objectId}`,
            operator: '='
        }
    })

    const promise2 = apiAxios.get(`/logs`, {
        params: {
            path: `/${product}/${resource}/${objectId}/%`,
            operator: 'like'
        }
    })

    const [data1, data2] = await Promise.all([promise1, promise2])
    const logs = [...data1.data, ...data2.data]
    return _(logs)
        .sortBy('created_at')
        .reverse()
        .value()
}

export async function getLogsLoki(payload) {
    const { data } = await apiAxios.post('/api-logs-loki/v2', payload)
    return data
}
