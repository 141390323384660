import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Switch, Button, Modal, Form, Input, Row, Col, Tooltip } from 'antd'
import { EditOutlined, FormOutlined, HistoryOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { isObject, safeObjectToString } from '@/common'
import FormLayout from 'components/FormLayout'
import ManageUsers, { ManageUsersContext } from 'components/ManageUsers'

export default function InsuranceUsers() {
    return (
        <ManageUsers product="insurance" resource="insurance-user">
            <InternalInsuranceUsers />
        </ManageUsers>
    )
}

function InternalInsuranceUsers() {
    const { product, canEdit, onRegisterColumns, onOpenEditForm, onSelectUser, dispatchEvent } = useContext(
        ManageUsersContext
    )

    const onShowChangeStatusConfirm = record => {
        Modal.confirm({
            maskClosable: true,
            title: `Are you sure to turn ${record.active ? 'off' : 'on'} ${record.email}`,
            onOk: async () => {
                dispatchEvent('status', record)
            }
        })
    }

    const columns = [
        {
            key: 'name',
            dataIndex: 'name',
            title: 'User',
            ellipsis: true
        },
        {
            key: 'email',
            dataIndex: 'email',
            title: 'Email',
            ellipsis: true
        },
        {
            key: 'status',
            dataIndex: 'active',
            title: 'Active',
            render: (_, record) => (
                <Switch
                    disabled={!canEdit}
                    checked={record.active}
                    onChange={() => onShowChangeStatusConfirm(record)}
                />
            )
        },
        {
            key: 'action',
            title: 'Action',
            render: (_, record) => (
                <Row gutter={[8, 8]}>
                    <Col sm={24} md={8}>
                        <Tooltip title="Edit">
                            <Button
                                ghost
                                type="primary"
                                icon={<EditOutlined />}
                                disabled={!canEdit}
                                onClick={() => onOpenEditForm(record)}
                            />
                        </Tooltip>
                    </Col>
                    <Col sm={24} md={8}>
                        <Link to={`/${product}/users/${record.id}`}>
                            <Button ghost type="danger" icon={<FormOutlined />} disabled={!canEdit} />
                        </Link>
                    </Col>
                    <Col sm={24} md={8}>
                        <Tooltip title="Show Log">
                            <Button type="dashed" icon={<HistoryOutlined />} onClick={() => onSelectUser(record)} />
                        </Tooltip>
                    </Col>
                </Row>
            )
        }
    ]

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => onRegisterColumns(columns), [])

    return (
        <React.Fragment>
            <InsuranceUserForm />
        </React.Fragment>
    )
}

function InsuranceUserForm() {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const { formState, onHideForm, onSave, onReload } = useContext(ManageUsersContext)

    const fields = ['name', 'email', 'password', 'active', 'custom_config']

    useEffect(() => {
        form.resetFields()

        if (formState.action === 'edit') {
            const values = _.pick(formState.payload, fields)
            values.custom_config = safeObjectToString(values.custom_config)
            form.setFieldsValue(values)
        }

        if (formState.action === 'create') {
            form.setFieldsValue({
                active: true
            })
        }
    }, [])

    const onSubmit = () => {
        form.validateFields().then(fieldsValue => {
            setLoading(true)

            onSave(fieldsValue)
                .then(() => {
                    setLoading(false)
                    onHideForm()
                    onReload()
                })
                .catch(() => {
                    setLoading(false)
                })
        })
    }

    const onPaste = () => {}

    return (
        <FormLayout
            type="user"
            action={formState.action}
            open={formState.action === 'create' || formState.action === 'edit'}
            loading={loading}
            onCloseForm={onHideForm}
            onSubmit={onSubmit}
            onPaste={onPaste}>
            <Form form={form} colon={false} layout="vertical">
                <Form.Item
                    label="Name"
                    name="name"
                    validateTrigger="onBlur"
                    hasFeedback
                    required
                    rules={[
                        {
                            required: true,
                            message: 'Name is required'
                        }
                    ]}>
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email"
                    validateTrigger="onBlur"
                    hasFeedback
                    required
                    rules={[
                        {
                            required: true,
                            message: 'Email is required'
                        },
                        {
                            type: 'email',
                            message: 'Invalid email'
                        }
                    ]}>
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    validateTrigger="onBlur"
                    hasFeedback
                    required
                    rules={[
                        {
                            required: true,
                            message: 'Password is required'
                        }
                    ]}>
                    <Input.Password />
                </Form.Item>

                <Form.Item label="Active" name="active" valuePropName="checked">
                    <Switch />
                </Form.Item>

                <Form.Item
                    label="Custom Config"
                    name="custom_config"
                    validateTrigger="onBlur"
                    rules={[
                        {
                            validator: (_, value) => {
                                if (value && !isObject(value)) {
                                    return Promise.reject('Invalid object')
                                }

                                return Promise.resolve()
                            }
                        }
                    ]}>
                    <Input.TextArea
                        row={6}
                        onChange={e =>
                            form.setFieldsValue({
                                custom_config: !e.target.value ? null : e.target.value
                            })
                        }
                    />
                </Form.Item>
            </Form>
        </FormLayout>
    )
}
