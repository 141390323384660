import { apiAxios, hotelInfoAxios } from './api'
import qs from 'querystring'

export async function getHotels({ product, resource }) {
    const { data } = await apiAxios.get(`/${product}/${resource}`)

    return data
}

export async function remove({ product, resource, data }) {
    await apiAxios.put(`/${product}/${resource}/delete`, data)
}

export async function add({ product, resource, data }) {
    await apiAxios.put(`/${product}/${resource}`, data)
}

export async function getHotel({ params }) {
    await hotelInfoAxios.get(`/hotel?${qs.stringify(params)}}`)
}
