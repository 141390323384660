import React, { useContext, useEffect, useRef, useState, useMemo } from 'react'
import { Transfer, Table, Tag, Tooltip, Badge, Button, Dropdown, Menu, Modal, Spin } from 'antd'
import { SnippetsOutlined, SettingOutlined, CheckCircleOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { useViews } from 'hooks'
import ViewsExport from './ViewsExport.jsx'
import { ExporterContext } from './Exporter'

export default function SelectFieldStep() {
    const { key, fieldGroups, exportFields, selectedFieldKeys, onNext, onSelectField, onUnSelectField } = useContext(
        ExporterContext
    )

    const transferContainerRef = useRef(null)
    const tableHeaderRef = useRef(null)
    const [tableBodyHeight, setTableBodyHeight] = useState(null)
    const [isOpenViewsExportDrawer, setIsOpenViewsExportDrawer] = useState(false)
    const [applyView, setApplyView] = useState(null)

    const { views, loading, dispatchEvent } = useViews({ key })

    useEffect(() => {
        if (transferContainerRef.current) {
            const tableContainerHeight = transferContainerRef.current.offsetHeight
            const tableBodyHeight = tableContainerHeight - tableHeaderRef.current.offsetHeight - 20
            setTableBodyHeight(tableBodyHeight)
        }
    }, [transferContainerRef.current])

    const columns = [
        {
            key: 'group',
            dataIndex: 'group',
            title: 'Group',
            align: 'center',
            ellipsis: true,
            filters: fieldGroups.map(group => ({
                text: `${group.label} (${group.fields.length})`,
                value: group.value
            })),
            onFilter: (value, record) => {
                if (value !== 'ALL') {
                    return RegExp('.*' + value, 'gi').test(record.group)
                }

                return true
            },
            render: (_, record) => record.group && <Tag>{record.group}</Tag>
        },
        {
            key: 'name',
            dataIndex: 'name',
            title: 'Name',
            align: 'center',
            ellipsis: true
        }
    ]

    const onFilterOption = (input, item) => RegExp('.*' + input, 'gi').test(item.name)

    const onChange = (_, direction, moveKeys) => {
        if (direction === 'left') {
            onUnSelectField(moveKeys)
        }

        if (direction === 'right') {
            onSelectField(moveKeys)
        }
    }

    const onShowConfirmApplyView = view => {
        Modal.confirm({
            title: `Are you want to apply view ${view.title}`,
            maskClosable: true,
            onOk: () => {
                const fields = _.intersectionBy(view.fields, exportFields, f => f.key)
                const fieldKeys = fields.map(f => f.key)
                onSelectField(fieldKeys)
                setApplyView(view)
            }
        })
    }

    const onShowConfirmDeleteView = view => {
        Modal.confirm({
            title: `Are you sure to delete view ${view.title}`,
            maskClosable: true,
            onOk: async () => {
                const payload = {
                    id: view.id
                }

                dispatchEvent('remove', payload)
            }
        })
    }

    const onHandleToggleFavoriteView = view => {
        const payload = {
            id: view.id,
            is_favorite: !view.is_favorite
        }

        dispatchEvent('save', payload)
    }

    const onCloseViewsExportDrawer = () => {
        setIsOpenViewsExportDrawer(false)
    }

    const viewsMenu = useMemo(
        () => (
            <Spin spinning={loading}>
                <Menu>
                    {views.slice(0, 5).map((view, index) => (
                        <Menu.Item
                            key={index}
                            role="button"
                            onClick={() => onShowConfirmApplyView(view)}
                            itemIcon={
                                <CheckCircleOutlined
                                    role="button"
                                    className={applyView && applyView.id === view.id && 'text-success'}
                                />
                            }>
                            <span className="mx-2">{view.title}</span>
                        </Menu.Item>
                    ))}
                    {views.length > 0 && <Menu.Divider />}
                    <Menu.Item
                        key="__edit__"
                        role="button"
                        icon={<SettingOutlined />}
                        onClick={() => setIsOpenViewsExportDrawer(true)}>
                        <span className="text-truncate">Manage your views</span>
                    </Menu.Item>
                </Menu>
            </Spin>
        ),
        [loading, views, applyView]
    )

    return (
        <div ref={transferContainerRef} className="h-700">
            <div className="my-2 d-flex justify-content-between align-items-center">
                <Tooltip title="Saved views">
                    <Dropdown placement="bottom" overlay={viewsMenu}>
                        <Badge showZero count={views.length}>
                            <SnippetsOutlined role="button" className="fs-5" />
                        </Badge>
                    </Dropdown>
                </Tooltip>
                <Button key="next" type="primary" onClick={onNext}>
                    Next
                </Button>
            </div>
            <Transfer
                className="h-100 flex-grow-1 transfer-table"
                titles={[<span key="left">Available columns</span>, <span key="right">Export columns</span>]}
                showSearch
                showSelectAll={true}
                dataSource={exportFields}
                targetKeys={selectedFieldKeys}
                filterOption={onFilterOption}
                onChange={onChange}>
                {({ filteredItems, onItemSelect, onItemSelectAll, selectedKeys }) => {
                    return (
                        <Table
                            size="small"
                            columns={columns}
                            pagination={false}
                            rowKey={r => r.key}
                            dataSource={filteredItems}
                            onRow={({ key, disabled }) => ({
                                onClick: () => {
                                    if (!disabled) onItemSelect(key, !selectedKeys.includes(key))
                                }
                            })}
                            rowSelection={{
                                type: 'checkbox',
                                selectedRowKeys: selectedKeys,
                                getCheckboxProps: ({ disabled }) => ({ disabled }),
                                onChange: selectedRowKeys => {
                                    const checked = selectedRowKeys.length > selectedKeys.length
                                    const diffKeys = checked
                                        ? _.difference(selectedRowKeys, selectedKeys)
                                        : _.difference(selectedKeys, selectedRowKeys)

                                    onItemSelectAll(diffKeys, checked)
                                }
                            }}
                            scroll={{ y: tableBodyHeight }}
                            components={{
                                header: {
                                    cell: props => <th ref={tableHeaderRef} {...props} />
                                }
                            }}
                        />
                    )
                }}
            </Transfer>
            <ViewsExport
                isOpen={isOpenViewsExportDrawer}
                loading={loading}
                viewKey={key}
                views={views}
                exportFields={exportFields}
                onClose={onCloseViewsExportDrawer}
                onSelectField={onSelectField}
                onShowConfirmDeleteView={onShowConfirmDeleteView}
                onHandleToggleFavoriteView={onHandleToggleFavoriteView}
            />
        </div>
    )
}
