import React, { useMemo, useEffect } from 'react'
import { Dropdown, Badge, Menu, Spin, Modal, Tooltip } from 'antd'
import { BarsOutlined, EditOutlined, DeleteOutlined, FileAddOutlined, SettingOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { useViews } from 'hooks'

export default function ViewsBookingColumns({
    emitter,
    viewKey,
    selectedView,
    onApplyViewColumns,
    onOpenEditViewColumns,
    onUseDefaultColumns
}) {
    const { loading, views, dispatchEvent } = useViews({ key: viewKey })

    useEffect(() => {
        emitter.on(viewKey, ({ action }) => dispatchEvent(action))
        return () => {
            emitter.removeAllListeners(viewKey)
        }
    }, [])

    const onRemove = view => {
        Modal.confirm({
            maskCloseable: true,
            title: `Are you sure to delete view - ${view.title}`,
            onOk: async () => {
                dispatchEvent('remove', view).then(() => onUseDefaultColumns())
            }
        })
    }

    const viewsMenu = useMemo(
        () => (
            <Spin spinning={loading}>
                <Menu>
                    {views.slice(0, 5).map((view, index) => (
                        <Menu.Item key={index} role="button" onClick={() => onApplyViewColumns(view)}>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 text-truncate">
                                    <span className={selectedView && selectedView.id === view.id && 'fw-bold'}>
                                        {view.title}
                                    </span>
                                </div>
                                <div className="d-flex gap-2 align-items-center flex-shrink-0">
                                    <EditOutlined
                                        key="__edit__"
                                        role="button"
                                        onClick={e => {
                                            e.stopPropagation()
                                            onOpenEditViewColumns(view)
                                        }}
                                    />

                                    <DeleteOutlined
                                        disabled={index === 0}
                                        key="__delete__"
                                        role="button"
                                        onClick={e => {
                                            e.stopPropagation()
                                            onRemove(view)
                                        }}
                                    />
                                </div>
                            </div>
                        </Menu.Item>
                    ))}
                    {views.length > 0 && <Menu.Divider />}
                    <Menu.Item
                        key="__create__"
                        role="button"
                        icon={<FileAddOutlined />}
                        onClick={() => onOpenEditViewColumns(null)}>
                        <span className="text-truncate">Create view</span>
                    </Menu.Item>
                    <Menu.Item
                        disabled={_.isEmpty(selectedView)}
                        key="__default__"
                        role="button"
                        icon={<SettingOutlined />}
                        onClick={onUseDefaultColumns}>
                        <span className="text-truncate">Use default columns</span>
                    </Menu.Item>
                </Menu>
            </Spin>
        ),
        [loading, views]
    )

    return (
        <Tooltip title="Columns">
            <Dropdown placement="bottom" overlay={viewsMenu}>
                <Badge showZero count={views.length}>
                    <BarsOutlined role="button" className="fs-5" />
                </Badge>
            </Dropdown>
        </Tooltip>
    )
}
