import React, { useState, useEffect } from 'react'
import { EditOutlined, HistoryOutlined, UserAddOutlined } from '@ant-design/icons'
import { notification, Button, Modal, Table, Row, Col, Input, Select } from 'antd'
import axios from 'axios'
import _ from 'lodash'

import { Can, Say, check } from './can'
import appConfig from '../config'
import apiErrorHandler from '../api-error-handler'
import LogList from './LogList'

const { Option } = Select

function HotelList({ hotels, getHotels, totalRecord, currentPage, columns, product, setFormState }) {
    const [flagShowLog, setFlagShowLog] = useState(false)
    const [logs, setLogs] = useState([])
    const [modalTitle, setModalTitle] = useState('')
    const [hotelList, setHotelList] = useState([])
    const [optionSearch, setOptionSearch] = useState('hotel_id')
    const [inputSearch, setInputSearch] = useState('')
    const [pageSize, setPageSize] = useState()
    const headers = JSON.parse(localStorage.getItem('headers') || '{}')

    useEffect(() => {
        setHotelList(hotels)
        setPageSize(10)
    }, [hotels])

    const showLog = row => {
        return Promise.all([
            axios({
                method: 'get',
                url: `${appConfig.apiUrl}/logs`,
                headers,
                params: {
                    path: `/${product}/hotels/update/${row.hotel_id}`,
                    operator: '='
                }
            }),
            axios({
                method: 'get',
                url: `${appConfig.apiUrl}/logs`,
                headers,
                params: {
                    path: `/${product}/hotels/update/${row.hotel_id}/%`,
                    operator: 'like'
                }
            })
        ])
            .then(data => {
                const logs = [...data[0].data, ...data[1].data]

                setLogs(
                    _(logs)
                        .sortBy('created_at')
                        .reverse()
                        .value()
                )
                setFlagShowLog(true)
                setModalTitle(`Logs of supplier: ${row.code}`)
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    const actionCell = (cell, row) => {
        return (
            <div>
                <Can resource={`${product}-management`} perform={'edit'}>
                    <Say yes>
                        <Button
                            ghost
                            type="primary"
                            icon={<EditOutlined />}
                            onClick={() => setFormState('UPDATE', row)}
                        />
                    </Say>
                </Can>
                <Can resource={`${product}-management`} oneOfPerformeds={['create', 'edit']}>
                    <Say yes>
                        <Button type="dashed" icon={<HistoryOutlined />} onClick={() => showLog(row)} />
                    </Say>
                </Can>
            </div>
        )
    }

    const closeLog = () => {
        setFlagShowLog(false)
    }

    const onSearch = () => {
        getHotels(1, pageSize, optionSearch, inputSearch)
    }

    const handleOptionSearchChange = value => {
        setOptionSearch(value)
    }

    const handleInputSearch = e => {
        setInputSearch(e.target.value)
    }

    const changePage = function(current, pageSize) {
        getHotels(current, pageSize, optionSearch, inputSearch)
    }

    const tableColumns = [...columns]

    if (check(`${product}-management`, 'create') || check(`${product}-management`, 'edit')) {
        tableColumns.push({
            key: 'dummy',
            dataIndex: 'dummy',
            title: 'Action',
            className: 'text-center',
            render: actionCell
        })
    }

    return (
        <div>
            <Row>
                <Col md={16}>
                    <Can resource={`${product}-management`} perform={'create'}>
                        <Say yes>
                            <Button type="primary" icon={<UserAddOutlined />} onClick={() => setFormState('CREATE')}>
                                Create
                            </Button>
                        </Say>
                    </Can>
                </Col>
                <Col md={8}>
                    {/* <Search placeholder="input search text" onSearch={onSearch} enterButton /> */}
                    <Input.Group compact>
                        <Select defaultValue="hotel_id" onChange={handleOptionSearchChange}>
                            <Option value="hotel_id">Hotel Id</Option>
                            <Option value="name">Name</Option>
                            <Option value="country">Country</Option>
                            <Option value="city">City</Option>
                            <Option value="star_rating">Star</Option>
                        </Select>
                        <Input style={{ width: '50%' }} onChange={handleInputSearch} />
                        <Button type="primary" onClick={onSearch}>
                            Search
                        </Button>
                    </Input.Group>
                </Col>
            </Row>
            <br />
            <Table
                dataSource={hotelList}
                columns={tableColumns}
                pagination={{
                    defaultCurrent: 1,
                    current: currentPage,
                    total: totalRecord,
                    pageSize: pageSize,
                    onChange: changePage,
                    showSizeChanger: false
                }}
                bordered
                rowKey="hotel_id"
            />

            {/* log modal */}
            <Modal
                visible={flagShowLog}
                onClick={closeLog}
                style={{ minWidth: '840px' }}
                onCancel={() => setFlagShowLog(false)}
                title={modalTitle || ''}
                footer={null}>
                <LogList data={logs} />
            </Modal>
        </div>
    )
}

export default HotelList
