import { useState, useEffect, useCallback, useMemo } from 'react'
import * as services from '../services'

export default function useViews({ key }) {
    const [views, setViews] = useState([])
    const [loading, setLoading] = useState(false)

    const onSave = useCallback(async payload => services.views.saveView(key, payload).then(views => setViews(views)), [
        key
    ])

    const onRemove = useCallback(
        async payload => services.views.removeView(key, payload.id).then(views => setViews(views)),
        [key]
    )

    const onLoad = useCallback(() => services.views.getViews(key).then(views => setViews(views)), [key])

    const handlers = useMemo(
        () => ({
            save: onSave,
            remove: onRemove,
            load: onLoad
        }),
        [onSave, onRemove]
    )

    const dispatchEvent = useCallback(
        async (event, payload) => {
            setLoading(true)

            const handler = handlers[event]
            const promise = handler(payload).finally(() => setLoading(false))
            return promise
        },
        [handlers]
    )

    useEffect(() => {
        dispatchEvent('load')
    }, [dispatchEvent])

    return { loading, views, dispatchEvent }
}
