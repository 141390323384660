import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Card, Button, Input, Row, Col, Space } from 'antd'
import { UserAddOutlined, SettingOutlined, ReloadOutlined, DownloadOutlined, SearchOutlined } from '@ant-design/icons'
import _ from 'lodash'
import * as services from 'services'
import { filterTableV2 } from '@/search'
import { usePermission } from 'hooks'
import AntdEditTable from './AntdEditTable'
import AddSupplierModal from './AddSupplierModal'
import ApplyMarkupModal from './ApplyMarkupModal'
import ExportLayout from './exports'

export const ManageUserSuppliersContext = React.createContext({})

export default function ManageUserSuppliers({ product, resource, children, ...props }) {
    const { id: userId } = useParams()
    const [loading, setLoading] = useState(false)
    const [suppliers, setSuppliers] = useState([])
    const [userSuppliers, setUserSuppliers] = useState([])
    const [query, setQuery] = useState('')
    const [isOpenAddSupplierModal, setIsOpenAddSupplierModal] = useState(false)
    const [isOpenApplyMarkupModal, setIsOpenApplyMarkupModal] = useState(false)
    const [columns, setColumns] = useState([])
    const [isOpenExport, setIsOpenExport] = useState({ open: false })
    const [selectedUserSuppliers, setSelectedUserSuppliers] = useState([])

    const { canEdit, canExport } = usePermission({ resource })

    const onDebounceChangeQuery = _.debounce(value => setQuery(value), 200)

    const onReload = () => {
        dispatchEvent('load')
    }

    const onRegisterColumns = columns => {
        setColumns(columns)
    }

    const onCloseAddSupplierModal = () => {
        setIsOpenAddSupplierModal(false)
    }

    const onCloseApplyMarkupModal = () => {
        setIsOpenApplyMarkupModal(false)
    }

    const onChangeSelectedUserSuppliers = users => {
        setSelectedUserSuppliers(users)
    }

    const onCloseExport = () => {
        setIsOpenExport({ open: false })
    }

    const onOpenExport = type => {
        setIsOpenExport({ open: true, type })
    }

    const onAddSupplier = async supplierId => {
        services.suppliers
            .addSupplierToUser({
                product,
                userId,
                data: {
                    supplier_id: supplierId
                }
            })
            .then(() => onReload())
            .finally(() => {
                onCloseAddSupplierModal()
            })
    }

    const onApplyMarkup = async markupPercent => {
        services.suppliers
            .applyMarkup({
                product,
                userId,
                data: {
                    markup_percent: markupPercent
                }
            })
            .then(() => onReload())
            .finally(() => {
                onCloseApplyMarkupModal()
            })
    }

    const onUpdate = useCallback((record, payload) => {
        setUserSuppliers(prev =>
            prev.map(us =>
                us.id !== record.id
                    ? us
                    : {
                          ...us,
                          ...payload
                      }
            )
        )
    }, [])

    const onRemove = useCallback(record => {
        setUserSuppliers(prev => prev.filter(us => us.id !== record.id))
    }, [])

    const handleSave = async (record, payload) => {
        setLoading(true)

        services.suppliers
            .adjustConfigUserSupplier({
                product,
                userId,
                supplierId: record.id,
                data: payload
            })
            .then(() => onUpdate(record, payload))
            .finally(() => {
                setLoading(false)
            })
    }

    const handleFetch = useCallback(
        () =>
            Promise.all([
                services.suppliers.getSuppliers({ product }),
                services.suppliers.getSuppliersByUser({
                    product,
                    userId
                })
            ]).then(data => {
                setSuppliers(data[0])
                setUserSuppliers(data[1])
            }),
        [product, userId]
    )

    const handleRemoveSupplierFromUser = useCallback(
        async record =>
            services.suppliers
                .removeSupplierFromUser({
                    product,
                    userId,
                    supplierId: record.id
                })
                .then(() => onRemove(record)),
        [product, userId, onRemove]
    )

    const handleChangeStatus = useCallback(
        async record =>
            services.suppliers
                .adjustConfigUserSupplier({
                    product,
                    userId,
                    supplierId: record.id,
                    data: {
                        active: !!record.inactive
                    }
                })
                .then(() =>
                    onUpdate(record, {
                        inactive: Number(!record.inactive)
                    })
                ),
        [product, userId, onUpdate]
    )

    const handleChangeIsClient = useCallback(
        async record =>
            services.suppliers
                .adjustConfigUserSupplier({
                    product,
                    userId,
                    supplierId: record.id,
                    data: {
                        supplier_cug: !record.supplier_cug
                    }
                })
                .then(() =>
                    onUpdate(record, {
                        supplier_cug: Number(!record.supplier_cug)
                    })
                ),
        [product, userId, onUpdate]
    )

    const handlers = useMemo(
        () => ({
            load: handleFetch,
            status: handleChangeStatus,
            remove: handleRemoveSupplierFromUser,
            is_client: handleChangeIsClient
        }),
        [handleFetch, handleChangeStatus, handleRemoveSupplierFromUser, handleChangeIsClient]
    )

    const dispatchEvent = useCallback(
        async (event, record) => {
            setLoading(true)

            const promise = handlers[event](record).finally(() => setLoading(false))

            return promise
        },
        [handlers]
    )

    const filterUserSuppliers = useMemo(() => filterTableV2(query, userSuppliers, ['code', 'name']), [
        query,
        userSuppliers
    ])

    const supplierOptions = useMemo(() => {
        if (props.onBuildOptions) return props.onBuildOptions(suppliers, userSuppliers)

        const ids = userSuppliers.map(us => us.id)

        const options = _.chain(suppliers)
            .filter(s => s.active && !ids.includes(s.id))
            .map(s => ({
                label: s.name || s.username || s.id,
                value: s.id
            }))
            .value()

        return options
    }, [props.onBuildOptions, suppliers, userSuppliers])

    useEffect(() => {
        dispatchEvent('load')
    }, [dispatchEvent])

    return (
        <ManageUserSuppliersContext.Provider
            value={{
                product,
                resource,
                canEdit,
                userId,
                onRegisterColumns,
                onUpdate,
                dispatchEvent
            }}>
            <AddSupplierModal
                isOpen={isOpenAddSupplierModal}
                suppliers={supplierOptions}
                onAddSupplier={onAddSupplier}
                onClose={onCloseAddSupplierModal}
            />

            <ApplyMarkupModal
                isOpen={isOpenApplyMarkupModal}
                onApplyMarkup={onApplyMarkup}
                onClose={onCloseApplyMarkupModal}
            />

            {canExport && (
                <ExportLayout
                    resource={resource}
                    data={isOpenExport.type === 'ALL' ? userSuppliers : selectedUserSuppliers}
                    isOpen={isOpenExport.open}
                    onCloseExport={onCloseExport}
                />
            )}

            <Card>
                <Row gutter={[0, 8]}>
                    <Col sm={24} md={24} lg={24} xl={12}>
                        <Space className="d-flex justify-content-between justify-content-xl-start align-items-center">
                            <Button
                                disabled={loading}
                                type="primary"
                                icon={<UserAddOutlined />}
                                onClick={() => setIsOpenAddSupplierModal(true)}>
                                Add
                            </Button>
                            <Button
                                disabled={loading}
                                type="info"
                                icon={<SettingOutlined />}
                                onClick={() => setIsOpenApplyMarkupModal(true)}>
                                Set Markup
                            </Button>
                            <Button
                                disabled={!canExport}
                                type="info"
                                icon={<DownloadOutlined />}
                                onClick={() => onOpenExport('SELECTED')}>
                                Export (<span>{selectedUserSuppliers.length}</span>)
                            </Button>
                            <Button
                                disabled={!canExport}
                                type="info"
                                icon={<DownloadOutlined />}
                                onClick={() => onOpenExport('ALL')}>
                                Export All
                            </Button>
                            <Button disabled={loading} icon={<ReloadOutlined />} onClick={onReload}>
                                Reload
                            </Button>
                        </Space>
                    </Col>
                    <Col sm={24} md={24} lg={24} xl={12}>
                        <div className="d-flex justify-content-end align-items-center">
                            <Input
                                className="w-100"
                                allowClear
                                size="middle"
                                placeholder="Search"
                                prefix={<SearchOutlined />}
                                onChange={e => onDebounceChangeQuery(e.target.value)}
                            />
                        </div>
                    </Col>
                </Row>
                <br />
                <AntdEditTable
                    loading={loading}
                    draggable={false}
                    recordKey="id"
                    columns={columns}
                    dataSource={filterUserSuppliers}
                    handleSave={handleSave}
                    rowSelection={{
                        onChange: (_, rows) => onChangeSelectedUserSuppliers(rows)
                    }}
                />
            </Card>

            {children}
        </ManageUserSuppliersContext.Provider>
    )
}
