import React from 'react'
import { Modal, Form, Input, Space, Tag } from 'antd'

export default function SaveViewBookingColumns({ isOpen, isEdit, loading, view, onSave, onClose }) {
    const [form] = Form.useForm()

    const onOk = async () => {
        form.validateFields().then(fieldsValue => {
            const payload = {
                id: fieldsValue.id,
                title: fieldsValue.title,
                description: fieldsValue.description,
                columns: view.columns
            }

            onSave(payload)
        })
    }

    return (
        <Modal
            centered
            forceRender
            title={!isEdit ? 'Create View Booking Columns' : `Edit View Booking Columns - ${view.title}`}
            open={isOpen}
            zIndex={1001}
            okText="Save"
            onCancel={onClose}
            onOk={onOk}
            maskClosable={!loading}
            okButtonProps={{
                disabled: loading,
                loading
            }}>
            <p className="my-2 text-secondary">Save your current view so you can use in the future.</p>
            <Form form={form} requiredMark colon={false}>
                <Form.Item name="id" className="d-none" initialValue={view.id}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="title"
                    validateTrigger="onBlur"
                    initialValue={view.title}
                    required
                    rules={[
                        {
                            required: true,
                            message: 'Title is required'
                        }
                    ]}>
                    <Input placeholder="Tile" />
                </Form.Item>
                <Form.Item name="description" initialValue={view.description}>
                    <Input placeholder="Description" />
                </Form.Item>
                <Space size={[0, 8]} wrap>
                    {view.columns.map(column => (
                        <Tag key={column.id} color="cyan" closeable="false" className="user-select-none">
                            <span>{column.title}</span>
                        </Tag>
                    ))}
                </Space>
            </Form>
        </Modal>
    )
}
