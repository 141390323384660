import { createAction } from '@'
import axios from 'axios'

const ENDPOINT = process.env.REACT_APP_API_URL

export default createAction('CreateUser', async params => {
    try {
        await axios({
            url: `${ENDPOINT}/signup`,
            method: 'POST',
            data: {
                email: params.email,
                password: params.password,
                name: params.name,
                role_id: params.role
            }
        })
    } catch (e) {
        console.error(e)
    }

    if (params.onSuccess) {
        await params.onSuccess()
    }
})
