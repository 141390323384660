import React from 'react'
import { Modal, Form, Input } from 'antd'

export default function ZipFolderNameModal({ isOpen, onDownload, onToggleZipFolderNameModal }) {
    const [form] = Form.useForm()

    const onOk = () => {
        form.validateFields()
            .then(fieldsValue => onDownload(fieldsValue.name))
            .then(() => onToggleZipFolderNameModal())
    }

    return (
        <Modal
            centered
            forceRender
            title="Zip Folder Name"
            open={isOpen}
            zIndex={1001}
            onOk={onOk}
            okText="Download"
            onCancel={onToggleZipFolderNameModal}>
            <Form form={form} requiredMark colon={false}>
                <Form.Item
                    name="name"
                    validateTrigger="onBlur"
                    initialValue={'Logs'}
                    required
                    rules={[
                        {
                            required: true,
                            message: 'Name is required'
                        }
                    ]}>
                    <Input placeholder="Enter your zip folder name" />
                </Form.Item>
            </Form>
        </Modal>
    )
}
