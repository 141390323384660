export const EXPORT_TITLE = {
    'flight-supplier': 'Export Flight Suppliers',
    'flight-user': 'Export Flight Users',
    'hotel-supplier': 'Export Hotel Suppliers',
    'hotel-user': 'Export Hotel Users',
    'tour-supplier': 'Export Tour Suppliers',
    'tour-user': 'Export Tour Users',
    'transfer-supplier': 'Export Transfer Suppliers',
    'transfer-user': 'Export Transfer Users',
    'booking-flight': 'Export Bookings Flight',
    'booking-hotel': 'Export Bookings Hotel',
    'booking-tour': 'Export Bookings Tour',
    'booking-transfer': 'Export Bookings Transfer'
}

export const IMPORT_TITLE = {
    'flight-supplier': 'Import Flight Suppliers',
    'flight-user': 'Import Flight Users',
    'hotel-supplier': 'Import Hotel Suppliers',
    'hotel-user': 'Import Hotel Users',
    'tour-supplier': 'Import Tour Suppliers',
    'tour-user': 'Import Tour Users',
    'transfer-supplier': 'Import Transfer Suppliers',
    'transfer-user': 'Import Transfer Users'
}

export const FORM_TITLE = {
    user: {
        create: 'Create User',
        edit: 'Edit User'
    },
    supplier: {
        create: 'Create Supplier',
        edit: 'Edit Supplier'
    },
    blacklist: {
        create: 'Add new blocked hotel'
    }
}

export const BOOKING_STATUS_FILTER = {
    flight: ['Issued', 'Pending', 'Cancelled', 'Failed', 'Created'],
    hotel: ['Confirmed', 'Received', 'Pending', 'Cancelled', 'Failed', 'Created'],
    tour: ['CANCELLED', 'CONFIRM', 'CONFIRMED', 'FAILED', 'PENDING'],
    transfer: ['CANCELLED', 'CONFIRMED']
}

export const BOOKING_STATUS_COLORS = {
    FLIGHT: {
        Issued: 'green',
        Pending: 'orange',
        PendingForTicketing: 'orange',
        Cancelled: 'gray',
        Failed: 'red',
        TicketingFailed: 'gray',
        'Queued for ticketing': 'gold'
    },
    HOTEL: {
        Confirmed: 'green',
        Received: 'green',
        Pending: 'warning',
        Cancelled: 'red',
        Failed: 'red'
    }
}

export const BOOKING_ANCILLARIES = {
    BAGGAGE: 'Baggage',
    MEAL: 'Meal',
    SPORT: 'Sport',
    LOUNGE: 'Lounge',
    CHECKIN: 'PriorityCheckin',
    SEAT: 'Seat'
}

export const LOG_LOKI_APP_OPTIONS = [
    { value: 'flight', label: 'Flight' },
    { value: 'hotel-offer', label: 'Hotel Offer' },
    { value: 'hotel-book', label: 'Hotel Booking' }
]

export const LOG_LOKI_ENV_OPTIONS = [
    { value: 'staging', label: 'Staging' },
    { value: 'production', label: 'Production' }
]
