import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter, Route, Switch } from 'react-router-dom'
import './app.less'
import './index.css'
import indexRoutes from './routes/index.jsx'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(
    <HashRouter>
        <Switch>
            {indexRoutes.map((prop, key) => {
                return <Route path={prop.path} key={key} component={prop.component} onEnter={prop.onEnter} />
            })}
        </Switch>
    </HashRouter>,
    document.getElementById('root')
)

module.hot && module.hot.accept()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
