import { useEffect } from 'react'
import { compose, hoc, useStore } from '@'
import { $hotelSupplierDestinationMapping, $hotelRegionInfo, $epsRegionInfo } from '../../stores'
import _ from 'lodash'
// import GetSupplierDestinationMapping from '../../actions/GetSupplierDestinationMapping'
import GetRegionInfo from '../../actions/GetRegionInfo'
import GetEPSRegionInfo from '@mapping/actions/GetEPSRegionInfo'
const container = compose(
    hoc(props => {
        const { hotel, source } = props
        console.log({ hotel, source })
        const [hotelRegionInfo, epsRegionInfo, hotelSupplierDestinationMapping] = useStore([
            $hotelRegionInfo,
            $epsRegionInfo,
            $hotelSupplierDestinationMapping
        ])

        const destinationMapping =
            (hotelSupplierDestinationMapping &&
                hotelSupplierDestinationMapping[`${hotel.supplier_code}-${hotel.hotel_id}`]) ||
            []

        const missing = destinationMapping.filter(
            regionId =>
                !_.some(
                    source !== 'EPS' ? hotelRegionInfo : epsRegionInfo,
                    regionInfo => regionId == regionInfo.region_id
                )
        )

        useEffect(() => {
            if (source !== 'EPS') {
                GetRegionInfo(missing).catch(console.error)
            } else {
                GetEPSRegionInfo(missing).catch(console.error)
            }
        }, [missing])

        const [allHotelRegionInfo] = useStore([source !== 'EPS' ? $hotelRegionInfo : $epsRegionInfo])
        const lstHotelRegionInfo = Object.values(allHotelRegionInfo).filter(r =>
            _.some(destinationMapping, regionId => regionId == r.region_id)
        )
        return {
            lstHotelRegionInfo,
            ...props
        }
    })
)

export default container
