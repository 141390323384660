import React, { useState, useEffect } from 'react'
import { Card, Typography, Spin, Table, Row, Tag, Form, Button, notification, Space } from 'antd'
import { useLocation } from 'react-router-dom'
import { SearchOutlined, CopyOutlined } from '@ant-design/icons'
import moment from 'moment'
import JSZip from 'jszip'
import * as services from 'services'
import LogForm from './Form'
import ZipFolderNameModal from './ZipFolderNameModal'

export default function LogLokiV2() {
    const [loading, setLoading] = useState(false)
    const [selectedRecords, setSelectedRecords] = useState([])
    const [logs, setLogs] = useState([])
    const [isOpenZipFolderNameModal, setIsOpenZipFolderNameModal] = useState(false)
    const [form] = Form.useForm()
    const location = useLocation()

    useEffect(() => {
        form.resetFields()
        const params = new URLSearchParams(location.search)
        const app = params && params.has('app') ? params.get('app') : 'flight'
        const env = params && params.has('env') ? params.get('env') : 'staging'
        const content = params && params.has('request_id') ? params.get('request_id') : ''

        let dates = [moment(), moment()]
        if (params && params.has('dates')) {
            dates = params
                .getAll('dates')
                .map(date => (!moment(date).isValid() ? moment() : moment(date, 'YYYY-MM-DD')))
        }

        form.setFieldsValue({
            app,
            env,
            dates,
            content
        })
    }, [location])

    const onSearch = async payload => {
        setLoading(true)

        const [start, end] = payload.dates

        const body = {
            app: payload.app,
            env: payload.env,
            request_id: payload.request_id,
            start: start.format('YYYY-MM-DD'),
            end: end.format('YYYY-MM-DD')
        }

        return services.logs
            .getLogsLoki(body)
            .then(logs => setLogs(logs))
            .finally(() => {
                setLoading(false)
            })
    }

    const onDownload = async zipFolderName => {
        if (!logs.length) return

        const records = !selectedRecords.length ? logs : selectedRecords

        const zip = new JSZip()

        for (let i = 0; i < records.length; i += 1) {
            const record = records[i]
            const fileName = [record.type, record.name, record.action, record.timestamp].join('_')
            const extension = record.is_json ? '.json' : '.xml'
            const filePath = `${fileName}${extension}`
            zip.file(filePath, record.message)
        }

        return zip.generateAsync({ type: 'blob' }).then(blob => {
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = `${zipFolderName}.zip`
            link.click()
            link.remove()
        })
    }

    const onToggleZipFolderNameModal = () => {
        setIsOpenZipFolderNameModal(!isOpenZipFolderNameModal)
    }

    const onNavigateTabToViewLog = (data, record) => {
        const type = record.is_json ? 'application/json' : 'text/xml'
        const blob = new Blob([data], { type })
        const url = URL.createObjectURL(blob)
        window.open(url)
        URL.revokeObjectURL(url)
    }

    const onCopyIntoClipboard = data => {
        let promise

        if ('clipboard' in navigator) {
            promise = navigator.clipboard.writeText(data)
        } else {
            promise = document.execCommand('copy', true, data)
        }

        promise.then(() => {
            notification.success({
                message: 'Copy into clipboard'
            })
        })
    }

    const columns = [
        {
            key: 'timestamp',
            dataIndex: 'timestamp',
            title: 'Time',
            ellipsis: true,
            sorter: (a, b) => a.timestamp - b.timestamp,
            sortDirections: ['descend'],
            render: (_, record) => {
                return (
                    <Row className="d-flex justify-content-start align-items-center h-100">
                        <Tag color="cyan">{moment.unix(record.timestamp).format('DD/MM/YYYY HH:mm:ss')}</Tag>
                    </Row>
                )
            }
        },
        {
            key: 'name',
            dataIndex: 'name',
            title: 'Name',
            ellipsis: true
        },
        {
            key: 'action',
            dataIndex: 'action',
            title: 'Action',
            ellipsis: true
        },
        {
            key: 'type',
            dataIndex: 'type',
            title: 'Type',
            ellipsis: true,
            render: (_, record) => (
                <Row className="d-flex h-100 align-items-center">
                    <Tag color={record.type === 'Response' ? 'red' : 'orange'}>{record.type}</Tag>
                </Row>
            )
        },
        {
            key: 'message',
            dataIndex: 'message',
            title: 'Message',
            render: (value, record) => (
                <div className="d-flex w-100 align-items-center">
                    <Space size="middle">
                        <CopyOutlined onClick={() => onCopyIntoClipboard(value)} />
                        <Button
                            role="link"
                            type="danger"
                            size="small"
                            onClick={() => onNavigateTabToViewLog(value, record)}>
                            View
                        </Button>
                    </Space>
                </div>
            )
        }
    ]

    return (
        <React.Fragment>
            <Card title={<Typography level={3}>Logs</Typography>} extra={<SearchOutlined />}>
                <LogForm
                    loading={loading}
                    form={form}
                    onSearch={onSearch}
                    onToggleZipFolderNameModal={onToggleZipFolderNameModal}
                />
                <br />
                <Spin spinning={loading}>
                    <Table
                        bordered
                        size="middle"
                        loading={loading}
                        rowKey={record => record.timestamp}
                        columns={columns}
                        dataSource={logs}
                        scroll={{ x: 'max-content' }}
                        rowSelection={{
                            onChange: (_, rows) => setSelectedRecords(rows)
                        }}
                    />
                </Spin>
            </Card>
            <ZipFolderNameModal
                isOpen={isOpenZipFolderNameModal}
                onDownload={onDownload}
                onToggleZipFolderNameModal={onToggleZipFolderNameModal}
            />
        </React.Fragment>
    )
}
