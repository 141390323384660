import { LoginPage } from '@auth'
import Dashboard from '../layouts/dashboard.jsx'
import Iframe from '../layouts/iframe.jsx'

const indexRoutes = [
    {
        path: '/login',
        name: 'Auth',
        icon: 'mdi mdi-account-key',
        component: LoginPage
    },
    {
        path: '/iframe',
        name: 'Export Frame',
        extract: true,
        component: Iframe
    },
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard
    }
]

export default indexRoutes
