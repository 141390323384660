import { compose, hoc, useStore } from '@'
import { SetFormValues, useForm, useFormLoading } from '@form'
import { SetTableFilter } from '@table'
import { useEffect } from 'react'
import GetUsers from '../../actions/GetUsers'
import CreateCard from '../../actions/CreateCard'
import { $users } from '../../stores'

const $fieldProps = {
    user: { rules: [{ required: true }] },
    reference: { rules: [{ required: true }] },
    amount: { rules: [{ required: true }] }
}

const container = compose(
    hoc(props => {
        const [users] = useStore([$users])

        useEffect(() => {
            GetUsers()
        }, [])

        const [$props, $form] = useForm(() => {
            const onSubmit = async values => {
                await CreateCard(values)
                SetTableFilter('CardTable', {}) // refresh table
                SetFormValues('CardForm', { user: 1 }) // reset form
            }

            return {
                name: 'CardForm',
                onSubmit
            }
        }, [])

        const $loading = useFormLoading($form.name)

        return {
            users,
            $props,
            $loading,
            $fieldProps,
            ...props
        }
    })
)

export default container
