import React from 'react'
import { Form } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import { CSS } from '@dnd-kit/utilities'
import { useSortable } from '@dnd-kit/sortable'

export const RowContext = React.createContext({})

export default function Row({ draggable, children, ...props }) {
    const [form] = Form.useForm()
    const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
        id: props['data-row-key'],
        disabled: !draggable
    })

    return (
        <Form form={form} component={false}>
            <RowContext.Provider value={{ form }}>
                <tr
                    ref={setNodeRef}
                    {...props}
                    {...attributes}
                    style={{
                        ...props.style,
                        transition,
                        transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
                        ...(!isDragging
                            ? {}
                            : {
                                  position: 'relative',
                                  zIndex: 999
                              })
                    }}>
                    {React.Children.map(children, child => {
                        if (child.key === 'sort') {
                            return React.cloneElement(child, {
                                children: (
                                    <MenuOutlined
                                        ref={setActivatorNodeRef}
                                        style={{ cursor: 'move', touchAction: 'none' }}
                                        {...listeners}
                                    />
                                )
                            })
                        }

                        return child
                    })}
                </tr>
            </RowContext.Provider>
        </Form>
    )
}
