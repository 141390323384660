import { apiAxios } from './api'

export async function getUsers({ product }) {
    const { data } = await apiAxios.get(`/${product}/users`)

    return data.users
}

export async function createUser({ product, data }) {
    await apiAxios.post(`/${product}/users`, data)
}

export async function updateUser({ product, userId, data }) {
    await apiAxios.patch(`/${product}/users/${userId}`, data)
}
