import React, { useState, useEffect, useRef } from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Card, Input, Col, Row, notification } from 'antd'
import axios from 'axios'
import _ from 'lodash'

import FormDialog from '../../components/FormDialog'
import MarketList from '../../components/MarketList'
import apiErrorHandler from '../../api-error-handler'
import appConfig from '../../config'

const PRODUCT = 'flight'

const columns = [
    {
        key: 'id',
        dataIndex: 'id',
        title: 'ID',
        defaultSortOrder: 'ascend'
    },
    {
        key: 'supplier_code',
        dataIndex: 'supplier_code',
        title: 'Supplier Code',
        defaultSortOrder: 'ascend'
    },
    {
        key: 'source_id',
        dataIndex: 'source_id',
        title: 'Source ID',
        defaultSortOrder: 'ascend'
    },
    {
        key: 'airline_code',
        dataIndex: 'airline_code',
        title: 'airline_code',
        defaultSortOrder: 'ascend'
    },
    {
        key: 'office_id',
        dataIndex: 'office_id',
        title: 'office_id',
        defaultSortOrder: 'ascend'
    },
    {
        key: 'currency',
        dataIndex: 'currency',
        title: 'currency',
        defaultSortOrder: 'ascend'
    },
    {
        key: 'airports',
        dataIndex: 'airports',
        title: 'airport',
        defaultSortOrder: 'ascend'
    },
    {
        key: 'city',
        dataIndex: 'city',
        title: 'city',
        defaultSortOrder: 'ascend'
    },
    {
        key: 'country_code',
        dataIndex: 'country_code',
        title: 'country_code',
        defaultSortOrder: 'ascend'
    }
]

function FlightMarket({ form }) {
    const apiUrl = `${appConfig.apiUrl}/${PRODUCT}`
    const [markets, setMarkets] = useState([])
    const [currentMarket, setCurrentMarket] = useState({})
    const [formState, _setFormState] = useState('HIDE')
    const supplierForm = useRef()
    const headers = JSON.parse(localStorage.getItem('headers') || '{}')
    async function getMarkets() {
        return axios({
            method: 'get',
            url: `${apiUrl}/markets`,
            headers: headers
        })
            .then(({ data }) => {
                setMarkets(data.markets)
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    useEffect(() => {
        getMarkets()
    }, [])

    const onSave = () => {
        return new Promise(resolve => {
            form.validateFields((err, values) => {
                if (values.airports) {
                    values.airports = values.airports.split(',')
                }
                if (err) {
                    notification.error({
                        message: 'Error!!!',
                        description: err
                    })
                    return
                }
                let options = {
                    url: `${apiUrl}`,
                    headers: headers,
                    data: values
                }
                switch (supplierForm.current.formState) {
                    case 'CREATE':
                        options.method = 'put'
                        options.data = values
                        options.url += `/market/create`
                        break
                    case 'UPDATE': {
                        const changedFields = {}

                        Object.keys(values).forEach(prop => {
                            if (values[prop] !== undefined && values[prop] !== currentMarket[prop]) {
                                changedFields[prop] = values[prop]
                            }
                        })

                        if (!Object.keys(changedFields).length) {
                            notification.warn({
                                message: 'Warning!!!',
                                description: "Market infos don't change!!!"
                            })
                            return
                        }

                        options.method = 'patch'
                        options.url += `/market/${currentMarket.id}/change`
                        options.data = changedFields

                        break
                    }
                    default:
                        notification.warning({
                            message: 'Warning!!!',
                            description: 'Unknow action'
                        })
                        return
                }

                return axios(options)
                    .then(() => {
                        setFormState('HIDE')
                        notification.success({
                            message: 'Successful!!!',
                            description: (formState === 'CREATE' ? 'Created' : 'Updated') + ' market successfully!'
                        })
                        getMarkets()
                    })
                    .catch(error =>
                        notification.error({
                            message: 'Error!!!',
                            description: apiErrorHandler(error) || 'Please logout and relogin'
                        })
                    )
                    .then(() => {
                        resolve()
                    })
            })
        })
    }

    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
    }

    const { getFieldDecorator } = form

    const setFormState = (state, values = {}) => {
        const formFields = [
            'source_id',
            'supplier_code',
            'office_id',
            'airline_code',
            'currency',
            'airports',
            'merchant_code',
            'city',
            'country_code',
            'agency_code'
        ]

        if (['CREATE', 'HIDE'].includes(state)) {
            form.resetFields()
        } else {
            const fields = _.pick(values, formFields)
            form.setFieldsValue(fields)
        }

        supplierForm.current.setFormState(state)
        _setFormState(state)
        setCurrentMarket(values)
    }

    return (
        <div>
            <Card>
                <MarketList
                    markets={markets}
                    product={PRODUCT}
                    columns={columns}
                    getMarkets={getMarkets}
                    setFormState={setFormState}
                />
                <FormDialog closeHandler={() => setFormState('HIDE')} saveHandler={onSave} ref={supplierForm}>
                    <Form {...formItemLayout}>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Source Id" hasFeedback>
                                    {getFieldDecorator('source_id', {
                                        rules: [
                                            {
                                                type: 'string',
                                                message: 'source_id is required!'
                                            },
                                            { required: true, message: 'Please enter source_id!' }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Supplier Code" hasFeedback>
                                    {getFieldDecorator('supplier_code', {
                                        rules: [
                                            {
                                                type: 'string',
                                                message: 'Code is required!'
                                            },
                                            { required: true, message: 'Please enter supplier code!' }
                                        ]
                                    })(<Input disabled={formState === 'UPDATE'} />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Office Id" hasFeedback>
                                    {getFieldDecorator('office_id', {
                                        rules: [
                                            {
                                                type: 'string'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Airline Code" hasFeedback>
                                    {getFieldDecorator('airline_code', {
                                        rules: [
                                            {
                                                type: 'string',
                                                max: 2
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Currency" hasFeedback>
                                    {getFieldDecorator('currency', {
                                        rules: [
                                            {
                                                type: 'string'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Airports" hasFeedback>
                                    {getFieldDecorator('airports', {
                                        rules: [
                                            {
                                                type: 'string'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Merchant Code" hasFeedback>
                                    {getFieldDecorator('merchant_code', {
                                        rules: [
                                            {
                                                type: 'string'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="City" hasFeedback>
                                    {getFieldDecorator('city', {
                                        rules: [
                                            {
                                                type: 'string'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Country Code" hasFeedback>
                                    {getFieldDecorator('country_code', {
                                        rules: [
                                            {
                                                type: 'string'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Agency Code" hasFeedback>
                                    {getFieldDecorator('agency_code', {
                                        rules: [
                                            {
                                                type: 'string'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </FormDialog>
            </Card>
        </div>
    )
}

export default Form.create({ name: 'flight-market' })(FlightMarket)
