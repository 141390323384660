import React from 'react'
import { Tabs } from 'antd'
import BookingChart from '../../components/dashboard/booking-chart.jsx'
import BookingRacing from '../../components/dashboard/booking-racing.jsx'
import BookingSummary from '../../components/dashboard/booking-summary.jsx'
import { check } from '../../components/can'

const { TabPane } = Tabs

class Dashboard extends React.Component {
    constructor() {
        super()

        this.state = {
            activeTab: 'hotel',
            panes: ['Hotel', 'Flight', 'Car', 'Tour', 'Transfer', 'Insurance'].filter(p =>
                check(p.toLowerCase(), 'view')
            )
        }
    }

    onChangedTab = activeTab => {
        this.setState({
            activeTab
        })
    }

    render() {
        return (
            <Tabs onChange={this.onChangedTab} type="card">
                {this.state.panes.map(pane => (
                    <TabPane tab={pane} key={pane.toLowerCase()}>
                        <BookingSummary product={this.state.activeTab} />
                        <br />
                        <BookingChart product={this.state.activeTab} />
                        <br />
                        <BookingRacing product={this.state.activeTab} />
                    </TabPane>
                ))}
            </Tabs>
        )
    }
}

export default Dashboard
