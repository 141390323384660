import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react'
import { EventEmitter } from 'events'
import { Modal, Tabs, Drawer } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import * as services from 'services'
import { usePermission } from 'hooks'
import Bookings from './Bookings'
import BookingFilter from './BookingFilter'
import BookingColumns from './BookingColumns'
import ExportLayout from 'components/exports/ExportLayout'

export const ManageBookingsContext = React.createContext({})

export default function ManageBookings({ resource, product, antdColumns, onFilterQuery, BookingForm, BookingChart }) {
    const viewColumnKey = `views-${resource}-columns`
    const emitterRef = useRef(new EventEmitter())
    const [loading, setLoading] = useState(false)
    const [bookings, setBookings] = useState([])
    const [selectedBookings, setSelectedBookings] = useState([])
    const [isOpenExport, setIsOpenExport] = useState(false)
    const [isOpenManageBookingColumns, setIsOpenManageBookingColumns] = useState(false)
    const [isOpenChartViewer, setIsOpenChatViewer] = useState(false)
    const [editViewColumns, setEditViewColumns] = useState(null)
    const [selectedViewColumns, setSelectedViewColumns] = useState(null)
    const [isOpenFilter, setIsOpenFilter] = useState(false)
    const [appliedFilter, setAppliedFilter] = useState(null)
    const [dates, setDates] = useState([moment(), moment()])
    const [visibleColumns, setVisibleColumns] = useState(antdColumns)
    const [activeTabKey, setActiveTabKey] = useState('__filter__')
    const [panes, setPanes] = useState({})

    const { canExport } = usePermission({ resource })

    const filterBookings = useMemo(
        () =>
            _.chain(bookings)
                .filter(booking => {
                    const conditions = _.chain(appliedFilter)
                        .entries()
                        .map(([key, values]) => (!values.length ? true : _.includes(values, booking[key])))
                        .value()

                    if (!conditions.length) return true

                    return _.every(conditions, c => c === true)
                })
                .value(),
        [appliedFilter, bookings]
    )

    const columns = useMemo(
        () =>
            _.chain(antdColumns)
                .map((c, index) => ({
                    index: index + 1,
                    id: c.dataIndex,
                    title: c.title,
                    required: _.get(c, 'required', false)
                }))
                .value(),
        [antdColumns]
    )

    const handleFetch = useCallback(async () => {
        setLoading(true)

        services.bookings
            .getBookings({
                product,
                params: {
                    from: dates[0].format('YYYY-MM-DD'),
                    to: dates[1].format('YYYY-MM-DD')
                }
            })
            .then(bookings => setBookings(bookings))
            .finally(() => {
                setLoading(false)
            })
    }, [product, dates])

    useEffect(() => {
        if (selectedViewColumns) {
            const columnIds = _.map(columns, c => c.id)

            setVisibleColumns(
                _.chain(selectedViewColumns)
                    .get('columns', [])
                    .filter(c => _.includes(columnIds, c.id))
                    .map(c => {
                        const antdColumn = _.find(antdColumns, adc => adc.dataIndex === c.id)

                        antdColumn.title = c.title

                        return antdColumn
                    })
                    .value()
            )
        }
    }, [selectedViewColumns, antdColumns, columns])

    useEffect(() => {
        handleFetch()
    }, [handleFetch])

    const onReload = async () => {
        handleFetch()
    }

    const onSendToTelegram = () => {}

    const onOpenEditViewColumns = view => {
        setEditViewColumns(view)
        setIsOpenManageBookingColumns(true)
    }

    const onCloseEditViewColumns = () => {
        setEditViewColumns(null)
        setIsOpenManageBookingColumns(false)
    }

    const onApplyViewColumns = view => {
        Modal.confirm({
            maskClosable: true,
            title: `Are you want to apply view ${view.title}`,
            onOk: () => {
                setSelectedViewColumns(view)
            }
        })
    }

    const onUseDefaultColumns = () => {
        setSelectedViewColumns(null)
        setVisibleColumns(antdColumns)
    }

    const onSyncViewColumns = () => {
        emitterRef.current.emit(viewColumnKey, {
            action: 'load'
        })
    }

    const onToggleFilter = () => {
        setIsOpenFilter(!isOpenFilter)
    }

    const onApplyFilter = filter => {
        setAppliedFilter(filter)
    }

    const onResetFilter = () => {
        setAppliedFilter(null)
    }

    const onOpenExport = () => {
        setIsOpenExport(true)
    }

    const onCloseExport = () => {
        setIsOpenExport(false)
    }

    const onOpenChartViewer = () => {
        setIsOpenChatViewer(true)
    }

    const onCloseChatViewer = () => {
        setIsOpenChatViewer(false)
    }

    const onChangeSelectedBookings = bookings => {
        setSelectedBookings(bookings)
    }

    const onChangeDate = dates => {
        setDates(dates)
    }

    const onChangeTab = tabKey => {
        setActiveTabKey(tabKey)
    }

    const onEdit = (targetKey, action) => {
        if (action === 'remove') {
            const tabKeys = _.keys(panes)
            const tabIndex = _.findIndex(tabKeys, tabKey => tabKey === targetKey)
            const nextTabIndex = tabIndex - 1

            if (activeTabKey === targetKey) {
                const nextTabKey = nextTabIndex === 0 ? '__filter__' : tabKeys[nextTabIndex]
                setActiveTabKey(nextTabKey)
            }

            const nextPanes = _.clone(panes)
            delete nextPanes[targetKey]

            setPanes(nextPanes)
        }
    }

    const onAdd = (tabKey, record) => {
        setActiveTabKey(tabKey)
        setPanes(prev => ({
            ...prev,
            [tabKey]: {
                record
            }
        }))
    }

    return (
        <React.Fragment>
            <ManageBookingsContext.Provider
                value={{
                    resource,
                    product,
                    viewColumnKey,
                    emitter: emitterRef.current,
                    loading,
                    columns,
                    visibleColumns,
                    dates,
                    bookings,
                    filterBookings,
                    isOpenManageBookingColumns,
                    isOpenChartViewer,
                    editViewColumns,
                    selectedViewColumns,
                    isOpenFilter,
                    appliedFilter,
                    onFilterQuery,
                    onReload,
                    onAdd,
                    onOpenChartViewer,
                    onCloseChatViewer,
                    onCloseExport,
                    onOpenExport,
                    onToggleFilter,
                    onApplyFilter,
                    onResetFilter,
                    onOpenEditViewColumns,
                    onCloseEditViewColumns,
                    onApplyViewColumns,
                    onUseDefaultColumns,
                    onSyncViewColumns,
                    onChangeSelectedBookings,
                    onChangeDate,
                    onSendToTelegram
                }}>
                {canExport && (
                    <ExportLayout
                        resource={resource}
                        data={!selectedBookings.length ? bookings : selectedBookings}
                        isOpenExport={isOpenExport}
                        onCloseExport={onCloseExport}
                    />
                )}

                <Tabs hideAdd type="editable-card" activeKey={activeTabKey} onChange={onChangeTab} onEdit={onEdit}>
                    <Tabs.TabPane tab="Booking Filter" key="__filter__" closable={false}>
                        <Bookings />
                    </Tabs.TabPane>
                    {Object.entries(panes).map(([tabKey, pane]) => (
                        <Tabs.TabPane tab={tabKey} key={tabKey}>
                            <BookingForm row={pane.record} />
                        </Tabs.TabPane>
                    ))}
                </Tabs>

                <BookingFilter
                    product={product}
                    isOpen={isOpenFilter}
                    appliedFilter={appliedFilter}
                    onToggleFilter={onToggleFilter}
                    onApplyFilter={onApplyFilter}
                    onResetFilter={onResetFilter}
                />

                <BookingColumns
                    isOpen={isOpenManageBookingColumns}
                    viewKey={viewColumnKey}
                    editView={editViewColumns}
                    columns={columns}
                    onSyncViewColumns={onSyncViewColumns}
                    onClose={onCloseEditViewColumns}
                />

                <Drawer title="Booking Chart" size="large" open={isOpenChartViewer} onClose={onCloseChatViewer}>
                    <BookingChart />
                </Drawer>
            </ManageBookingsContext.Provider>
        </React.Fragment>
    )
}
