import React, { useMemo, useState } from 'react'
import { Modal, Form, Input, Space, Tag, Select } from 'antd'
import _ from 'lodash'
import { useViews } from 'hooks'

export default function ViewExportForm({ isEdit, isOpen, viewKey, view, exportFields, onClose }) {
    const [form] = Form.useForm()
    const [selectedFields, setSelectedFields] = useState(view.fields)
    const [editTagIndex, setEditTagIndex] = useState(-1)
    const [editTagValue, setEditTagValue] = useState('')

    const { loading, dispatchEvent } = useViews({ key: viewKey })

    const fieldOptions = useMemo(() => {
        const selectedFieldKeys = selectedFields.map(f => f.key)

        const options = _.chain(exportFields)
            .filter(f => !selectedFieldKeys.includes(f.key))
            .map(f => ({
                label: f.label,
                value: f.key
            }))
            .value()

        return options
    }, [exportFields, selectedFields])

    const onOk = () => {
        form.validateFields().then(fieldsValue => {
            const payload = {
                id: fieldsValue.id,
                title: fieldsValue.title,
                description: fieldsValue.description,
                fields: selectedFields
            }

            dispatchEvent('save', payload).then(() => onClose())
        })
    }

    const onSelectField = key => {
        const field = _.find(exportFields, f => f.key === key)
        setSelectedFields(prev => [...prev, field])
    }

    const onRemoveField = key => {
        setSelectedFields(prev => prev.filter(f => f.key !== key))
    }

    const onClear = () => {
        setSelectedFields([])
    }

    const onConfirmEdit = () => {
        if (editTagValue) {
            const newFields = [...selectedFields]
            newFields[editTagIndex].label = editTagValue
            setSelectedFields(newFields)
        }

        setEditTagIndex(-1)
        setEditTagValue('')
    }

    const onTagDoubleClick = (e, index, field) => {
        e.preventDefault()
        setEditTagIndex(index)
        setEditTagValue(field.label)
    }

    const tagInputStyle = {
        width: 64,
        height: 22,
        marginInlineEnd: 8,
        verticalAlign: 'top'
    }

    return (
        <Modal
            centered
            forceRender
            open={isOpen}
            title={!isEdit ? 'Create View' : `Edit View - ${view.title}`}
            okText="Save"
            onCancel={onClose}
            onOk={onOk}
            maskClosable={!loading}
            okButtonProps={{
                disabled: loading,
                loading
            }}>
            <p className="my-2 text-secondary">Save your current view so you can recall in the future.</p>
            <Form form={form} requiredMark colon={false}>
                <Form.Item name="id" className="d-none" initialValue={view.id}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="title"
                    validateTrigger="onBlur"
                    initialValue={view.title}
                    required
                    rules={[
                        {
                            required: true,
                            message: 'Title is required'
                        }
                    ]}>
                    <Input placeholder="Title" />
                </Form.Item>
                <Form.Item name="description" initialValue={view.description}>
                    <Input placeholder="Description" />
                </Form.Item>
                <Form.Item>
                    Fields
                    <Select
                        className="w-100 ml-2"
                        showSearch
                        defaultActiveFirstOption={false}
                        filterOption={(value, option) => RegExp('.*' + value, 'gi').test(option.label)}
                        options={fieldOptions}
                        onSelect={onSelectField}
                    />
                </Form.Item>
                <Space size={[0, 8]} wrap>
                    {selectedFields.map((field, index) => {
                        if (editTagIndex === index) {
                            return (
                                <Input
                                    key={field.key}
                                    autoFocus
                                    size="small"
                                    style={tagInputStyle}
                                    value={editTagValue}
                                    onChange={e => setEditTagValue(e.target.value)}
                                    onBlur={onConfirmEdit}
                                    onPressEnter={onConfirmEdit}
                                />
                            )
                        }

                        return (
                            <Tag
                                key={field.key}
                                color="cyan"
                                closable
                                className="user-select-none"
                                onClose={() => onRemoveField(field.key)}>
                                <span onDoubleClick={e => onTagDoubleClick(e, index, field)}>{field.label}</span>
                            </Tag>
                        )
                    })}
                    {selectedFields.length > 0 && (
                        <Tag key="__clear__" color="red" role="button" closable onClick={onClear} onClose={onClear}>
                            <span> Clear All</span>
                        </Tag>
                    )}
                </Space>
            </Form>
        </Modal>
    )
}
