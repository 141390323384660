import MockAdapter from 'axios-mock-adapter'
import qs from 'querystring'
import { apiAxios } from './api'
import config from 'config'
import bookings from './mocks/bookings.json'

export async function getBookings({ product, params }) {
    const { data: bookings } = await apiAxios.get(`/${product}/bookings?${qs.stringify(params)}`)

    return bookings
}

export async function cancelBooking(booking) {
    await apiAxios.delete(`/bookings/${booking.booking_id}`, {
        reference: booking.reference,
        supplier_code: booking.supplier_code,
        pnr: booking.pnr
    })
}

export async function syncTicket(booking) {
    await apiAxios.post('/proxy', {
        url: booking.eticket_push_url,
        method: 'POST',
        body: booking,
        json: true
    })
}

export async function getBooking({ product, bookingId, params }) {
    const { data: booking } = await apiAxios.get(`/${product}/bookings/${bookingId}?${qs.stringify(params)}`)

    return booking
}

if (config.isAxiosMock) {
    const mock = new MockAdapter(apiAxios)

    mock.onGet(new RegExp(/.*\bbookings\b.*/)).reply(200, bookings)
}
