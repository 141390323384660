import {
    ApiOutlined,
    BarChartOutlined,
    CarOutlined,
    ClusterOutlined,
    CompassOutlined,
    CreditCardOutlined,
    DashboardOutlined,
    DesktopOutlined,
    DingdingOutlined,
    DollarOutlined,
    EllipsisOutlined,
    FileMarkdownOutlined,
    SafetyCertificateOutlined,
    ShopOutlined,
    ShoppingCartOutlined,
    TeamOutlined,
    UserAddOutlined,
    StopOutlined,
    UploadOutlined,
    HeartOutlined,
    BugOutlined,
    GroupOutlined,
    UserOutlined
} from '@ant-design/icons'
import { CardPage } from '@card'
import { FlightPriceCompare } from '@flight'
import { HotelRebook } from '@hotel-rebook'
import { HotelMapping, RegionMapping, AmenitiesMapping } from '@mapping'
import { RolePage } from '@role'
import { MappingPage } from '@tour-mapping'
import { UserPage } from '@user'
import React from 'react'
import { IconTaxes } from '../@icons/index.js'
import Dashboard from '../views/dashboard/dashboard.jsx'

// car management components
import CarBooking from '../views/car/booking.jsx'
import CarSupplier from '../views/car/suppliers.jsx'
import CarUser from '../views/car/users.jsx'

// flight management components
import FlightBooking from '../views/flight/bookings.jsx'
// import FlightSupplier from '../views/flight/supplier.jsx'
// import FlightUser from '../views/flight/user.jsx'
import FlightMarket from '../views/flight/market.jsx'
import FlightSupplier from '../views/flight/suppliers.jsx'
import FlightUser from '../views/flight/users.jsx'

// hotel management components
import HotelBooking from '../views/hotel/bookings.jsx'
import Chart from '../views/hotel/chart.jsx'
// import HotelSupplier from '../views/hotel/supplier.jsx'
import HotelSupplier from '../views/hotel/suppliers.jsx'
import HotelManagement from '../views/hotel/hotel-management'
import Taxes from '../views/hotel/taxes'
// import HotelUser from '../views/hotel/user.jsx'
import HotelUser from '../views/hotel/users.jsx'
import BlackList from '../views/hotel/blacklist.jsx'
import GreyList from '../views/hotel/greylist.jsx'

import MarriottRateProgram from '../views/hotel/marriott-rate-program'
import MarriottValueAdd from '../views/hotel/marriott-value-add'
import MarriottMappingDerbySoft from '../views/hotel/marriott-mapping-derbysoft'
import MarriottBARB from '../views/hotel/marriott-barb'

// Insurance management components
// import InsuranceBooking from '../views/insurance/booking.jsx'
import InsuranceSupplier from '../views/insurance/suppliers.jsx'
import InsuranceUser from '../views/insurance/users.jsx'

// management dashboard
import Currency from '../views/management/currency'
// import TourSupplier from '../views/tour/supplier.jsx'
import TourSupplier from '../views/tour/suppliers.jsx'
// tour mangament components
// import TourUser from '../views/tour/user.jsx'
import TourUser from '../views/tour/users.jsx'

// transfer mangament components
// import TransferSupplier from '../views/transfer/supplier.jsx'
import TransferSupplier from '../views/transfer/suppliers.jsx'
// import TransferUser from '../views/transfer/user.jsx'
import TransferUser from '../views/transfer/users.jsx'

//log
// import LokiLog from '../views/log/Loki/LokiLog'
import LogLokiV2 from 'components/Log/LogLoki.jsx'

import Owner from '../views/management/Owner.jsx'

const ThemeRoutes = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        icon: <DashboardOutlined />,
        component: Dashboard,
        resource: 'dashboard',
        perform: 'view',
        ruotes: []
    },
    {
        navlabel: true,
        name: 'Products',
        icon: <EllipsisOutlined />,
        resources: ['hotel', 'flight', 'car', 'tour', 'transfer', 'insurance'],
        perform: 'view'
    },
    {
        collapse: true,
        path: '/hotel',
        name: 'Hotel',
        state: 'hotel',
        icon: <ShopOutlined />,
        resource: 'hotel',
        perform: 'view',
        routes: [
            {
                path: '/hotel/users',
                name: 'User',
                exact: true,
                icon: <UserAddOutlined />,
                component: HotelUser,
                resource: 'hotel-user',
                perform: 'view'
            },
            {
                path: '/hotel/suppliers',
                name: 'Supplier',
                icon: <ApiOutlined />,
                component: HotelSupplier,
                resource: 'hotel-supplier',
                perform: 'view'
            },
            {
                path: '/hotel/hotel-management',
                name: 'Hotel Management',
                icon: (
                    <span role="img" aria-label="api" className="anticon anticon-api">
                        <IconTaxes />
                    </span>
                ),
                component: HotelManagement,
                resource: 'hotel-management',
                perform: 'view'
            },
            {
                path: '/hotel/taxes-management/:siteId',
                name: 'Taxes Management',
                icon: (
                    <span role="img" aria-label="api" className="anticon anticon-api">
                        <IconTaxes />
                    </span>
                ),
                component: Taxes,
                resource: 'hotel-taxes-management',
                perform: 'view'
            },
            {
                path: '/hotel/bookings',
                name: 'Booking Management',
                icon: <DesktopOutlined />,
                component: HotelBooking,
                resource: 'hotel-booking',
                perform: 'view'
            },
            {
                path: '/hotel/create-booking',
                name: 'Create Booking',
                mini: 'H',
                icon: <ShoppingCartOutlined />,
                component: HotelRebook,
                resource: 'hotel-create-booking',
                perform: 'view'
            },
            {
                path: '/hotel/charts',
                name: 'Chart',
                icon: <BarChartOutlined />,
                component: Chart,
                resource: 'hotel-chart',
                perform: 'view'
            },
            {
                path: '/hotel/blacklist',
                name: 'Blacklist',
                icon: <StopOutlined />,
                component: BlackList,
                resource: 'hotel-blacklist',
                perform: 'view'
            },
            {
                path: '/hotel/black-n-white',
                name: 'Black&White List',
                icon: <StopOutlined />,
                component: GreyList,
                resource: 'hotel-blacklist',
                perform: 'view'
            },
            {
                collapse: true,
                path: '/hotel/mapping',
                name: 'Mapping',
                state: 'hotelMapping',
                icon: <ClusterOutlined />,
                resource: 'hotel-mapping',
                perform: 'view',
                routes: [
                    {
                        path: '/hotel/region-mapping',
                        name: 'Region Mapping',
                        icon: <CompassOutlined />,
                        component: RegionMapping,
                        resource: 'hotel-mapping',
                        perform: 'view'
                    },
                    {
                        path: '/hotel/hotel-mapping',
                        name: 'Hotel Mapping',
                        icon: <CompassOutlined />,
                        component: HotelMapping,
                        resource: 'hotel-mapping',
                        perform: 'view'
                    },
                    {
                        path: '/hotel/amenities-mapping',
                        name: 'Amenities Mapping',
                        icon: <CompassOutlined />,
                        component: AmenitiesMapping,
                        resource: 'hotel-mapping',
                        perform: 'view'
                    }
                ]
            },
            {
                collapse: true,
                path: '/marriott',
                name: 'Marriott',
                icon: <ClusterOutlined />,
                resource: 'hotel-marriott',
                perform: 'view',
                routes: [
                    {
                        path: '/marriott/upload-rate-programs',
                        name: 'Rate Programs',
                        icon: <UploadOutlined />,
                        component: MarriottRateProgram,
                        resource: 'hotel-marriott',
                        perform: 'view'
                    },
                    {
                        path: '/marriott/upload-value-adds',
                        name: 'Value Adds',
                        icon: <UploadOutlined />,
                        component: MarriottValueAdd,
                        resource: 'hotel-marriott',
                        perform: 'view'
                    },
                    {
                        path: '/marriott/mapping-derbysoft',
                        name: 'Mapping DerbySoft',
                        icon: <UploadOutlined />,
                        component: MarriottMappingDerbySoft,
                        resource: 'hotel-marriott',
                        perform: 'view'
                    },
                    {
                        path: '/marriott/barb-rate',
                        name: 'BARB Rate',
                        icon: <UploadOutlined />,
                        component: MarriottBARB,
                        resource: 'hotel-marriott',
                        perform: 'view'
                    }
                ]
            }
        ]
    },
    {
        collapse: true,
        path: '/flight',
        name: 'Flight',
        state: 'flight',
        icon: <DingdingOutlined />,
        resource: 'flight',
        perform: 'view',
        routes: [
            {
                path: '/flight/users',
                name: 'User',
                exact: true,
                icon: <UserAddOutlined />,
                component: FlightUser,
                resource: 'flight-user',
                perform: 'view'
            },
            {
                path: '/flight/markets',
                name: 'Market',
                icon: <FileMarkdownOutlined />,
                component: FlightMarket,
                resource: 'flight-market',
                perform: 'view'
            },
            // {
            //     path: '/flight/markets',
            //     name: 'Market',
            //     icon: <DesktopOutlined />,
            //     component: FlightMarket,
            //     resource: 'flight-market',
            //     perform: 'view'
            // },
            {
                path: '/flight/suppliers',
                name: 'Supplier',
                icon: <ApiOutlined />,
                component: FlightSupplier,
                resource: 'flight-supplier',
                perform: 'view'
            },
            {
                path: '/flight/bookings',
                name: 'Booking Management',
                icon: <DesktopOutlined />,
                exact: true,
                component: FlightBooking,
                resource: 'flight-booking',
                perform: 'view'
            },
            {
                path: '/flight/price-comparison',
                name: 'Price Comparison',
                icon: <DollarOutlined />,
                component: FlightPriceCompare,
                resource: 'flight-price-comparison',
                perform: 'view'
            }
        ]
    },
    {
        collapse: true,
        path: '/tour',
        name: 'Tour',
        state: 'tour',
        icon: <ShopOutlined />,
        resource: 'tour',
        perform: 'view',
        routes: [
            {
                path: '/tour/users',
                name: 'User',
                exact: true,
                icon: <UserAddOutlined />,
                component: TourUser,
                resource: 'tour-user',
                perform: 'view'
            },
            {
                path: '/tour/suppliers',
                name: 'Supplier',
                icon: <ApiOutlined />,
                component: TourSupplier,
                resource: 'tour-supplier',
                perform: 'view'
            },
            {
                path: '/tour/tour-mapping',
                name: 'Tour Mapping',
                icon: <ClusterOutlined />,
                component: MappingPage,
                resource: 'tour-mapping',
                perform: 'view'
            }
        ]
    },
    {
        collapse: true,
        path: '/car',
        name: 'Car',
        state: 'car',
        icon: <CarOutlined />,
        resource: 'car',
        perform: 'view',
        routes: [
            {
                path: '/car/users',
                name: 'User',
                exact: true,
                icon: <UserAddOutlined />,
                component: CarUser,
                resource: 'car-user',
                perform: 'view'
            },
            {
                path: '/car/suppliers',
                name: 'Supplier',
                icon: <ApiOutlined />,
                component: CarSupplier,
                resource: 'car-supplier',
                perform: 'view'
            },
            {
                path: '/car/bookings',
                name: 'Booking Management',
                icon: <DesktopOutlined />,
                component: CarBooking,
                resource: 'car-booking',
                perform: 'view'
            }
        ]
    },
    {
        collapse: true,
        path: '/insurance',
        name: 'Insurance',
        state: 'insurance',
        icon: <HeartOutlined />,
        resource: 'insurance',
        perform: 'view',
        routes: [
            {
                path: '/insurance/users',
                name: 'User',
                icon: <UserAddOutlined />,
                component: InsuranceUser,
                resource: 'insurance-user',
                perform: 'view'
            },
            {
                path: '/insurance/suppliers',
                name: 'Supplier',
                icon: <ApiOutlined />,
                component: InsuranceSupplier,
                resource: 'car-supplier',
                perform: 'view'
            },
            {
                path: '/insurance/bookings',
                name: 'Booking Management',
                icon: <DesktopOutlined />,
                // component: InsuranceBooking,
                resource: 'insurance-booking',
                perform: 'view'
            }
        ]
    },
    {
        collapse: true,
        path: '/transfer',
        name: 'Transfer',
        state: 'transfer',
        icon: <CarOutlined />,
        resource: 'transfer',
        perform: 'view',
        routes: [
            {
                path: '/transfer/users',
                name: 'User',
                exact: true,
                icon: <UserAddOutlined />,
                component: TransferUser,
                resource: 'transfer-user',
                perform: 'view'
            },
            {
                path: '/transfer/suppliers',
                name: 'Supplier',
                icon: <ApiOutlined />,
                component: TransferSupplier,
                resource: 'transfer-supplier',
                perform: 'view'
            }
        ]
    },
    {
        collapse: true,
        path: '/log',
        name: 'Log',
        state: 'log',
        icon: <BugOutlined />,
        resource: 'log',
        perform: 'view',
        routes: [
            {
                path: '/log/loki',
                name: 'Loki Grafana',
                icon: <GroupOutlined />,
                component: LogLokiV2,
                resource: 'log-loki',
                perform: 'view'
            }
        ]
    },
    {
        navlabel: true,
        name: 'Management',
        icon: <EllipsisOutlined />,
        resources: ['currency', 'user', 'role'],
        perform: 'view'
    },
    {
        collapse: false,
        path: '/currency-rates',
        name: 'Currency Rates',
        state: 'currency-rates',
        icon: <DollarOutlined />,
        component: Currency,
        resource: 'currency',
        perform: 'view'
    },
    {
        collapse: false,
        path: '/users',
        name: 'Users',
        state: 'users',
        icon: <TeamOutlined />,
        component: UserPage,
        resource: 'user',
        perform: 'view'
    },
    {
        collapse: false,
        path: '/roles',
        name: 'Roles',
        state: 'roles',
        icon: <SafetyCertificateOutlined />,
        component: RolePage,
        resource: 'role',
        perform: 'view'
    },
    {
        collapse: false,
        path: '/virtual-card',
        name: 'Virtual Card',
        state: 'roles',
        icon: <CreditCardOutlined />,
        component: CardPage,
        resource: 'card',
        perform: 'view'
    },
    {
        collapse: false,
        path: '/owner',
        name: 'Owner',
        state: 'roles',
        icon: <UserOutlined />,
        component: Owner,
        resource: 'owner',
        perform: 'view'
    }
]

export default ThemeRoutes
