import React from 'react'
import { Card, Dropdown, Menu, Avatar } from 'antd'
import { LogoutOutlined } from '@ant-design/icons'

export default function RightHeader({ user, onLogout }) {
    const menu = (
        <Menu className="mw-100">
            <Menu.Item key="logout" onClick={onLogout}>
                <LogoutOutlined /> Logout
            </Menu.Item>
        </Menu>
    )

    return (
        <Dropdown className="overflow-hidden" overlay={menu} placement="bottomRight">
            <Card.Meta
                avatar={
                    <Avatar
                        src="https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png"
                        alt="avatar"
                    />
                }
                description={user && user.name}
            />
        </Dropdown>
    )
}
