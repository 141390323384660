import React from 'react'
import { useLocation, Link } from 'react-router-dom'
import { Breadcrumb as AntdBreadcrumb } from 'antd'
import { HomeOutlined } from '@ant-design/icons'
import _ from 'lodash'
import routing from '../routes/routing'

function buildRoutes(routes, output = []) {
    _.forEach(routes, route => {
        if (route.path) {
            if (Array.isArray(route.routes)) buildRoutes(route.routes, output)
            output.push({
                path: route.path,
                name: route.name
            })
        }
    })

    return output
}

export default function Breadcrumb() {
    const location = useLocation()

    const paths = location.pathname.split('/').filter(p => p)

    const path2Name = _.keyBy(buildRoutes(routing), r => r.path)

    const extraBreadcrumbItems = paths.map((_, index) => {
        const path = `/${paths.slice(0, index + 1).join('/')}`

        return (
            <>
                {path2Name[path] ? (
                    <AntdBreadcrumb.Item key={path}>
                        <Link to={path}>{path2Name[path].name}</Link>
                    </AntdBreadcrumb.Item>
                ) : (
                    <></>
                )}
            </>
        )
    })

    const breadcrumbItems = [
        <AntdBreadcrumb.Item key="home">
            <Link to="/">
                <HomeOutlined />
            </Link>
        </AntdBreadcrumb.Item>
    ].concat(extraBreadcrumbItems)

    return <AntdBreadcrumb>{breadcrumbItems}</AntdBreadcrumb>
}
