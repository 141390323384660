import React, { useMemo, useState } from 'react'
import { Row, Col, Button, Input, Space, Select, List, Typography } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import _ from 'lodash'
import airlines from '../assets/airlines/airline_list.json'

function AirlinePreferencesListHeader({ selectedAirlines }) {
    return !selectedAirlines.length ? <p>Choose some airline</p> : `Seleted (${selectedAirlines.length})`
}

export default function AirlinePreferences({ type, field, form, onChangeForm }) {
    const [query, setQuery] = useState('')
    const selectedAirlines = form[field] || []
    const airlineOptions = airlines.filter(s => !selectedAirlines.includes(s.code))
    const airlineList = useMemo(() => {
        const list = []

        selectedAirlines.forEach(code => {
            const airline = airlines.find(a => a.code === code)
            if (
                airline &&
                (RegExp('.*' + query, 'gi').test(airline.code) || RegExp('.*' + query, 'gi').test(airline.name))
            ) {
                list.push(airline)
            }
        })

        return list
    }, [query, selectedAirlines])

    const onRemove = index => {
        const code = selectedAirlines[index]
        onChangeForm({
            [field]: selectedAirlines.filter(s => s !== code)
        })
    }

    const onClear = () => {
        onChangeForm({
            [field]: []
        })
    }

    const onChange = value => {
        onChangeForm({
            [field]: [...selectedAirlines, value]
        })
    }

    const onDebounceChange = _.debounce(value => setQuery(value), 150)

    return (
        <Space direction="vertical" className="w-100">
            <Row gutter={[8, 0]}>
                <Col span={12}>
                    <Select
                        className="w-100 select-overflow"
                        showSearch
                        defaultActiveFirstOption={false}
                        placeholder={type === 'operating_carrier' ? 'Search Operating Carrier' : 'Search Airline'}
                        filterOption={(value, option) => RegExp('.*' + value, 'gi').test(option.label)}
                        onChange={onChange}>
                        {airlineOptions.map(({ code, name }) => (
                            <Select.Option key={code}>{`${code} - ${name}`}</Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col span={12}>
                    <Space>
                        <Input onChange={e => onDebounceChange(e.target.value)} />
                        <Button type="danger" disabled={!selectedAirlines.length} onClick={onClear}>
                            Clear
                        </Button>
                    </Space>
                </Col>
            </Row>

            <List
                size="small"
                bordered
                dataSource={airlineList}
                style={{
                    overflow: 'auto',
                    height: '250px'
                }}
                header={<AirlinePreferencesListHeader selectedAirlines={selectedAirlines} />}
                renderItem={(item, index) => (
                    <List.Item
                        key={item.code}
                        actions={[
                            <Button
                                key="remove"
                                type="danger"
                                icon={<CloseCircleOutlined />}
                                onClick={e => {
                                    e.stopPropagation()
                                    onRemove(index)
                                }}
                            />
                        ]}>
                        <Typography.Text>{`${item.code} - ${item.name}`}</Typography.Text>
                    </List.Item>
                )}
            />
        </Space>
    )
}
