import React, { useState, useEffect, useRef } from 'react'

import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Card, Modal, Input, Col, Row, notification, Table, Button, Select } from 'antd'
import { UserAddOutlined, DeleteOutlined, EditOutlined, HistoryOutlined } from '@ant-design/icons'
import LogList from '../../components/LogList'
import axios from 'axios'
import _ from 'lodash'

import { Can, Say } from '../../components/can'
import FormDialog from '../../components/FormDialog'
import apiErrorHandler from '../../api-error-handler'
import appConfig from '../../config'

// const PRODUCT = 'hotel'
/**
 * @table_name: greylisting_hotels
 * -----------------------------------------------
 * @id: (int)
 * @supplier_code: (string)
 * @supplier_username: (string) supplier user_name or supplier api_key
 * @supplier_hotel_id: (string), (JP code or HOB code, ...)
 * @type: (enum) ['black' | 'white']
 */
function GreyList({ form }) {
    const product = 'hotel'
    const apiUrl = `${appConfig.apiUrl}`

    const [flagShowModal, setFlagShowModal] = useState(false)
    const [flagShowLog, setFlagShowLog] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [logs, setLogs] = useState([])

    const [supplierProperties, setSupplierProperties] = useState([])
    const [supplierOptions, setSupplierOptions] = useState([])
    const [supplierPropertyOptions, setSupplierPropertyOptions] = useState([])

    const [selectedSupplierProperties, setSelectedSupplierProperties] = useState([])
    const [supplierSelected, setSupplierSelected] = useState()
    const [filterGreylist, setFilterGreylist] = useState([])
    const [greylist, setGreylist] = useState([])
    const [formState, _setFormState] = useState('HIDE')
    const [searchInput, setSearchInput] = useState('')

    const supplierForm = useRef()
    const headers = JSON.parse(localStorage.getItem('headers') || '{}')

    const showLog = row => {
        return Promise.all([
            axios({
                method: 'get',
                url: `${appConfig.apiUrl}/logs`,
                headers,
                params: {
                    path: `/${product}/greylist/${row.id}`,
                    operator: '='
                }
            }),
            axios({
                method: 'get',
                url: `${appConfig.apiUrl}/logs`,
                headers,
                params: {
                    path: `/${product}/greylist/${row.id}/%`,
                    operator: 'like'
                }
            })
        ])
            .then(data => {
                const logs = [...data[0].data, ...data[1].data]

                setLogs(
                    _(logs)
                        .sortBy('created_at')
                        .reverse()
                        .value()
                )
                setFlagShowLog(true)
                setModalTitle(`Logs of grey: ${row.id}`)
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }
    const closeLog = () => {
        setFlagShowLog(false)
    }
    const toggleSwitchModal = () => {
        setFlagShowModal(!flagShowModal)
    }
    const getSupplierProperties = async () => {
        try {
            const response = await axios.get(`${apiUrl}/hotel/greylist/supplier-properties`, {
                headers: headers
            })
            if (response.status === 200) {
                console.log(response.data)
                setSupplierProperties(response.data)
                const supOpts = _(response.data || [])
                    .map('code')
                    .uniqBy()
                    .value()
                setSupplierOptions(supOpts)
                console.log()
            }
        } catch (error) {
            notification.error({
                message: 'Error!!!',
                description: apiErrorHandler(error)
            })
        }
    }

    const getGreylist = async () => {
        try {
            const response = await axios.get(`${apiUrl}/hotel/greylist`, {
                headers: headers
            })
            if (response.status === 200) {
                setGreylist(response.data)

                setFilterGreylist(response.data)
            }
        } catch (error) {
            notification.error({
                message: 'Error!!!',
                description: apiErrorHandler(error)
            })
        }
    }

    const deleteGreylist = async hotel => {
        try {
            const response = await axios.delete(
                `${apiUrl}/hotel/greylist`,

                { data: hotel, headers }
            )
            if (response.status === 200) {
                notification.success({
                    message: 'Successful!!!'
                })
            }
            getGreylist()
        } catch (error) {
            notification.error({
                message: 'Error!!!',
                description: apiErrorHandler(error)
            })
        }
    }

    const showDeleteConfirm = row => {
        Modal.confirm({
            title: `Are you sure to delete this record from ${row.type} list ${row.supplier_code} - ${row.supplier_username} - ${row.supplier_hotel_id}?`,
            onOk: () => deleteGreylist(row),
            onCancel: () => toggleSwitchModal()
        })
    }
    const onSearch = e => {
        setSearchInput(e.target.value)
    }
    const actionCell = (cell, row) => {
        return (
            <div>
                <Row>
                    <Can resource={`${product}-blacklist`} perform={'edit'}>
                        <Say yes>
                            <Button
                                ghost
                                type="primary"
                                icon={<EditOutlined />}
                                onClick={() => setFormState('UPDATE', row)}
                            />
                            <Button
                                size="primary"
                                danger
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                    showDeleteConfirm(row)
                                }}
                            />
                        </Say>
                    </Can>
                    <Can resource={`${product}-blacklist`} oneOfPerformeds={['view', 'create', 'edit', 'delete']}>
                        <Say yes>
                            <Button type="dashed" icon={<HistoryOutlined />} onClick={() => showLog(row)} />
                        </Say>
                    </Can>
                </Row>
            </div>
        )
    }
    useEffect(() => {
        getGreylist()
        getSupplierProperties()
    }, [])

    useEffect(() => {
        setFilterGreylist(
            greylist.filter(hotel =>
                Object.values(hotel)
                    .join('-')
                    // .toString()
                    .includes(searchInput)
            )
        )
    }, [searchInput])

    const onSave = () => {
        return new Promise(resolve => {
            form.validateFields((err, values) => {
                if (err) {
                    notification.error({
                        message: 'Error!!!',
                        description: err
                    })
                    return
                }

                let options = {
                    url: `${apiUrl}/hotel/greylist`,
                    headers,
                    data: values
                }

                switch (supplierForm.current.formState) {
                    case 'CREATE':
                        options.method = 'post'
                        options.data = values
                        break
                    case 'UPDATE':
                        options.method = 'put'
                        options.url += `/${values.id}`
                        options.data = values
                        break
                    default:
                        notification.warning({
                            message: 'Warning!!!',
                            description: 'Unknow action'
                        })
                        return
                }

                return axios(options)
                    .then(response => {
                        setFormState('HIDE')
                        notification.success({
                            message: response.data.message
                        })
                        getGreylist()
                    })
                    .catch(error =>
                        notification.error({
                            message: 'Error!!!',
                            description: apiErrorHandler(error)
                        })
                    )
                    .then(() => {
                        resolve()
                    })
            })
        })
    }

    const columns = [
        {
            key: 'supplier_code',
            dataIndex: 'supplier_code',
            title: 'Supplier Code',
            width: '30%'
        },
        {
            key: 'supplier_username',
            dataIndex: 'supplier_username',
            title: 'Supplier'
        },
        {
            key: 'supplier_hotel_id',
            dataIndex: 'supplier_hotel_id',
            title: 'Supplier Hotel Id'
        },
        {
            key: 'type',
            dataIndex: 'type',
            title: 'Type'
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'actions',
            render: actionCell
        }
    ]

    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 }
    }

    const { getFieldDecorator } = form

    const setFormState = (state, values = {}) => {
        const formFields = ['id', 'supplier_code', 'supplier_username', 'supplier_hotel_id', 'type']
        // debugger
        if (['CREATE', 'HIDE'].includes(state)) {
            form.resetFields()
        } else {
            const fields = _.pick(values, formFields)
            form.setFieldsValue(fields)
        }

        supplierForm.current.setFormState(state)
        _setFormState(state)
    }

    const handleSupplierOptionChange = code => {
        const supplierPropertyOpt = _(supplierProperties)
            .filter(s => s.code === code)
            .map(opt => opt.username)
            .uniqBy()
            .value()

        setSupplierSelected(code)
        setSupplierPropertyOptions(supplierPropertyOpt)
        setSelectedSupplierProperties([])
        form.setFieldsValue({ supplier_username: [] })
    }

    const handleSelectedSupplierPropertiesChange = usernames => {
        setSelectedSupplierProperties(usernames)
    }
    const validateSelectedCredential = (rule, value, callback) => {
        if (value && value.length > 0) {
            callback()
        } else {
            callback(`Please select Supplier Credentials`)
        }
    }
    return (
        <div>
            <Card>
                <div>
                    <Row>
                        <Col md={16}>
                            <Button type="primary" icon={<UserAddOutlined />} onClick={() => setFormState('CREATE')}>
                                Create
                            </Button>
                        </Col>
                        <Col md={8}>
                            <Input placeholder="Search" onChange={onSearch} />
                        </Col>
                    </Row>
                    <br />
                    <Table dataSource={filterGreylist} columns={columns} bordered rowKey="id" />
                </div>
                <FormDialog closeHandler={() => setFormState('HIDE')} saveHandler={onSave} ref={supplierForm}>
                    <Form form={form} {...formItemLayout}>
                        <Row gutter={8}>
                            <Col md={24}>
                                <Form.Item label="Supplier Code" name="supplier_code">
                                    {getFieldDecorator('supplier_code', {
                                        rules: [{ required: true, message: 'Please select supplierCode!' }]
                                    })(
                                        <Select
                                            showSearch
                                            disabled={formState === 'UPDATE'}
                                            initialvalue={supplierOptions[0]}
                                            setfieldsvalue={supplierSelected}
                                            style={{ width: '100%' }}
                                            onChange={handleSupplierOptionChange}
                                            options={supplierOptions.map(supOpt => ({
                                                label: supOpt,
                                                value: supOpt
                                            }))}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        {JSON.stringify(selectedSupplierProperties)}
                        <Row gutter={16}>
                            <Form.Item name="id">{getFieldDecorator('id')(<Input disabled hidden />)}</Form.Item>
                            <Col md={24}>
                                <Form.Item label="Supplier Credentials" name="supplier_username">
                                    {getFieldDecorator('supplier_username', {
                                        rules: [
                                            {
                                                validator: validateSelectedCredential
                                            }
                                        ]
                                    })(
                                        <Select
                                            showSearch
                                            allowClear
                                            disabled={formState === 'UPDATE'}
                                            mode="multiple"
                                            initialvalue={selectedSupplierProperties}
                                            setfieldsvalue={selectedSupplierProperties}
                                            onChange={handleSelectedSupplierPropertiesChange}
                                            style={{ width: '100%' }}
                                            options={supplierPropertyOptions.map(opt => ({
                                                label: opt,
                                                value: opt
                                            }))}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={24}>
                                <Form.Item label="Hotel id" name="supplier_hotel_id">
                                    {getFieldDecorator('supplier_hotel_id', {
                                        rules: [
                                            {
                                                // pattern: new RegExp('^[0-9]*$'),
                                                message: 'Hotel id number'
                                            },
                                            { required: true, message: 'Please enter hotel id!' }
                                        ]
                                    })(<Input placeholder="123" />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={24}>
                                <Form.Item label="Type" name="Type">
                                    {getFieldDecorator('type', {
                                        rules: [
                                            {
                                                // pattern: new RegExp('^[0-9]*$'),
                                                message: 'type black/white list'
                                            },
                                            { required: true, message: 'Please enter  type!' }
                                        ]
                                    })(
                                        <Select
                                            // disabled={formState === 'UPDATE'}
                                            initialvalue={'blacklist'}
                                            style={{ width: '100%' }}
                                            options={['blacklist', 'whitelist'].map(opt => ({
                                                label: opt,
                                                value: opt
                                            }))}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </FormDialog>
            </Card>
            {/* log modal */}
            <Modal
                visible={flagShowLog}
                onClick={closeLog}
                style={{ minWidth: '840px' }}
                onCancel={() => setFlagShowLog(false)}
                title={modalTitle || ''}
                footer={null}>
                <LogList data={logs} />
            </Modal>
        </div>
    )
}

export default Form.create({ name: 'hotel-greylist' })(GreyList)
