import React, { useState, useEffect } from 'react'
import { EditOutlined, DeleteOutlined, UserAddOutlined, HistoryOutlined } from '@ant-design/icons'
import { notification, Switch, Button, Modal, Table, Row, Col, Input } from 'antd'
import axios from 'axios'

import { Can, check, Say } from './can'
import appConfig from '../config'
import apiErrorHandler from '../api-error-handler'
import LogList from './LogList'
import { filterTable } from '@/search'
import _ from 'lodash'

function MarketList({ markets, getMarkets, columns, product, setFormState }) {
    const [modalTitle, setModalTitle] = useState('')
    const [flagShowModal, setFlagShowModal] = useState(false)
    const apiUrl = `${appConfig.apiUrl}/${product}`
    const [flagShowLog, setFlagShowLog] = useState(false)
    const [logs, setLogs] = useState([])
    const [initialMarket, setInitialMarket] = useState([])
    const [marketList, setMarketList] = useState([])
    const headers = JSON.parse(localStorage.getItem('headers') || '{}')
    useEffect(() => {
        setInitialMarket(markets)
        setMarketList(markets)
    }, [markets])

    const showUpdateStatusConfirm = row => {
        Modal.confirm({
            title: `Are you sure to turn ${row.del_flag ? 'on' : 'off'}?`,
            onOk: () => handleUpdateStatus(row),
            onCancel: () => toggleSwitchModal()
        })
    }
    const showDeleteConfirm = row => {
        Modal.confirm({
            title: `Are you sure to delete?`,
            onOk: () => handleDelete(row),
            onCancel: () => toggleSwitchModal()
        })
    }
    const toggleSwitchModal = () => {
        setFlagShowModal(!flagShowModal)
    }
    const handleDelete = row => {
        axios({
            method: 'put',
            url: `${apiUrl}/market/${row.id}/delete`,
            headers,
            data: {
                id: row.id,
                supplier_code: row.supplier_code,
                source_id: row.source_id
            }
        })
            .then(() => {
                notification.success({
                    message: 'Successful!!!',
                    description: `Delete market (id: ${row.id}) successfully`
                })
                getMarkets()
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error) || 'Please logout and relogin'
                })
            )
    }

    const handleUpdateStatus = row => {
        axios({
            method: 'patch',
            url: `${apiUrl}/market/${row.id}/change`,
            headers,
            data: {
                del_flag: row.del_flag === 1 ? 0 : 1
            }
        })
            .then(() => {
                notification.success({
                    message: 'Successful!!!',
                    description: `Update market (id: ${row.id}) successfully`
                })
                getMarkets()
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error) || 'Please logout and relogin'
                })
            )
    }

    const statusCell = (cell, row) => (
        <Switch
            disabled={false}
            defaultChecked={!row.del_flag}
            checked={!row.del_flag}
            onClick={() => showUpdateStatusConfirm(row)}
        />
    )

    const actionCell = (cell, row) => {
        return (
            <div>
                <Can resource={`${product}-supplier`} perform={'edit'}>
                    <Say yes>
                        <Button
                            ghost
                            type="primary"
                            icon={<EditOutlined />}
                            onClick={() => setFormState('UPDATE', row)}
                        />
                    </Say>
                </Can>
                <Can resource={`${product}-supplier`} oneOfPerformeds={['create', 'edit']}>
                    <Say yes>
                        <Button type="dashed" icon={<HistoryOutlined />} onClick={() => showLog(row)} />
                    </Say>
                </Can>
                <Can resource={`${product}-supplier`} oneOfPerformeds={['create', 'edit']}>
                    <Say yes>
                        <Button
                            type="dashed"
                            icon={<DeleteOutlined />}
                            onClick={() => showDeleteConfirm(row)}
                            disabled={!check(`${product}-market`, 'delete')}
                        />
                    </Say>
                </Can>
            </div>
        )
    }

    const closeLog = () => {
        setFlagShowLog(false)
    }

    const showLog = row => {
        console.log(`/${product}/market/${row.id}`)
        return Promise.all([
            axios({
                method: 'get',
                url: `${appConfig.apiUrl}/logs`,
                headers,
                params: {
                    path: `/${product}/market/${row.id}`,
                    operator: '='
                }
            }),
            axios({
                method: 'get',
                url: `${appConfig.apiUrl}/logs`,
                headers,
                params: {
                    path: `/${product}/market/${row.id}/%`,
                    operator: 'like'
                }
            })
        ])
            .then(data => {
                const logs = [...data[0].data, ...data[1].data]
                console.log(logs)
                setLogs(
                    _(logs)
                        .sortBy('created_at')
                        .reverse()
                        .value()
                )
                setFlagShowLog(true)
                setModalTitle(`Logs of market: ${row.id}`)
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    const onSearch = e => {
        const searchInput = e.target.value
        const market = filterTable(searchInput, initialMarket, columns)
        setMarketList(market)
    }

    const tableColumns = [...columns]

    tableColumns.push(
        {
            key: 'del_flag',
            dataIndex: 'del_flag',
            title: 'Status',
            className: 'text-center',
            render: statusCell
        },
        {
            key: 'dummy',
            dataIndex: 'dummy',
            title: 'Action',
            className: 'text-center',
            render: actionCell
        }
    )

    return (
        <div>
            <Row>
                <Col md={16}>
                    <Can resource={`${product}-supplier`} perform={'create'}>
                        <Say yes>
                            <Button type="primary" icon={<UserAddOutlined />} onClick={() => setFormState('CREATE')}>
                                Create
                            </Button>
                        </Say>
                    </Can>
                </Col>
                <Col md={8}>
                    <Input placeholder="Search" onChange={onSearch} />
                </Col>
            </Row>
            <br />
            <Table dataSource={marketList} columns={tableColumns} bordered rowKey="id" />

            {/* log modal */}
            <Modal
                visible={flagShowLog}
                onClick={closeLog}
                style={{ minWidth: '840px' }}
                onCancel={() => setFlagShowLog(false)}
                title={modalTitle || ''}
                footer={null}>
                <LogList data={logs} />
            </Modal>
        </div>
    )
}

export default MarketList
