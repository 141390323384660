/* eslint-disable no-unused-vars */
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { EditOutlined } from '@ant-design/icons'
import { Button, Card, Col, Input, Modal, notification, Row, Switch } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import apiErrorHandler from '../../api-error-handler'
import EPSSupplierList from '../../components/EPSSupplierList'
import FormDialog from '../../components/FormDialog'
import appConfig from '../../config'
const PRODUCT = 'hotel'

function HotelTaxes({ form, isFrame, customCss }) {
    let { siteId } = useParams()
    const apiUrl = `${appConfig.apiOffer}/asgard`

    const [EPSList, setEPSList] = useState([])
    const [currentSupplier, setCurrentSupplier] = useState({})
    const [changedFields, setChangedFields] = useState({})
    const [formState, _setFormState] = useState('HIDE')
    const supplierForm = useRef()
    const headers = JSON.parse(localStorage.getItem('headers') || '{}')

    const columns = [
        {
            key: 'hotel_id',
            dataIndex: 'hotel_id',
            title: 'Hotel ID'
        },
        {
            key: 'hotel_name',
            dataIndex: 'hotel_name',
            title: 'Hotel Name'
        },
        {
            key: 'country',
            dataIndex: 'country',
            title: 'Country'
        },
        {
            key: 'region_name',
            dataIndex: 'region_name',
            title: 'Region'
        },
        {
            key: 'applied_tax_amount',
            dataIndex: 'applied_tax_amount',
            title: 'Applied Tax Amount'
        },
        {
            key: 'currency',
            dataIndex: 'currency',
            title: 'Currency'
        },
        {
            key: 'scope',
            dataIndex: 'scope',
            title: 'Scope'
        },
        {
            key: 'frequency',
            dataIndex: 'frequency',
            title: 'Frequency'
        },
        {
            key: 'passenger_age',
            dataIndex: 'passenger_age',
            title: 'Passenger Ages'
        },
        {
            key: 'travel_dates',
            dataIndex: 'travel_dates',
            title: 'Travel Dates'
        },
        {
            key: 'del_flag',
            dataIndex: 'del_flag',
            title: 'Status',
            className: 'text-center',
            render: statusCell
        },
        {
            title: 'Action',
            key: 'action',
            render: item => {
                return (
                    <Button
                        type={'danger'}
                        ghost
                        icon={<EditOutlined />}
                        onClick={() => {
                            setFormState('UPDATE', item)
                        }}
                    />
                )
            }
        }
    ]
    const statusCell = (cell, row) => {
        console.log(row)
        return <Switch defaultChecked={!row.del_flag} checked={!row.del_flag} onClick={() => showConfirm(row)} />
    }
    const changeTaxStatus = supplier => {
        onSave()
    }
    const showConfirm = row => {
        Modal.confirm({
            title: `Are you sure to turn ${row.inactive ? 'on' : 'off'} ${row.name}?`,
            onOk: () => changeTaxStatus(row),
            onCancel: () => setFormState('HIDE')
        })
    }
    const getEPSList = () => {
        return axios({
            method: 'get',
            url: `${apiUrl}/taxes/${siteId}`,
            headers
        })
            .then(({ data }) => {
                setEPSList(data.data)
            })
            .catch(error =>
                setEPSList([]).then(
                    notification.error({
                        message: 'Error!!!',
                        description: apiErrorHandler(error)
                    })
                )
            )
    }

    useEffect(() => {
        getEPSList()
    }, [])

    const onSave = () => {
        return new Promise(resolve => {
            form.validateFields((err, values) => {
                if (err) {
                    notification.error({
                        message: 'Error!!!',
                        description: err
                    })
                    return
                }

                let options = {
                    url: `${apiUrl}/taxes`,
                    headers,
                    data: { tax_and_fee: values.tax_and_fee, mandatory_tax: values.mandatory_tax }
                }

                switch (supplierForm.current.formState) {
                    case 'UPDATE': {
                        Object.keys(values).forEach(prop => {
                            if (values[prop] !== undefined && values[prop] !== currentSupplier[prop]) {
                                changedFields[prop] = values[prop]
                            }
                        })

                        if (!Object.keys(changedFields).length) {
                            notification.warn({
                                message: 'Warning!!!',
                                description: "EPS taxes infos don't change!!!"
                            })
                            return
                        }

                        options.method = 'put'
                        options.url += `/${currentSupplier.id}`
                        options.data = changedFields

                        break
                    }
                    default:
                        notification.warning({
                            message: 'Warning!!!',
                            description: 'Unknow action'
                        })
                        return
                }

                return axios(options)
                    .then(() => {
                        setFormState('HIDE')
                        notification.success({
                            message: 'Successful!!!',
                            description:
                                (formState === 'CREATE' ? 'Created' : 'Updated') + ' EPS taxes: ' + values.hotel_name
                        })
                        getEPSList()
                    })
                    .catch(error =>
                        notification.error({
                            message: 'Error!!!',
                            description: apiErrorHandler(error)
                        })
                    )
                    .then(() => {
                        resolve()
                    })
            })
        })
    }

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 }
    }

    const { getFieldDecorator } = form

    const setFormState = (state, values = {}) => {
        const formFields = [
            'id',
            'hotel_id',
            'hotel_name',
            'region_name',
            'tax_and_fee',
            'mandatory_tax.request_currency.value',
            'mandatory_tax.request_currency.currency',
            'mandatory_tax.billable_currency.value',
            'mandatory_tax.billable_currency.currency'
        ]
        if (['HIDE'].includes(state)) {
            form.resetFields()
        } else {
            setChangedFields({})
            const fields = _.pick(values, formFields)
            form.setFieldsValue(fields)
        }

        supplierForm.current.setFormState(state)
        _setFormState(state)
        setCurrentSupplier(values)
    }

    return (
        <Card>
            <EPSSupplierList
                EPSList={EPSList}
                product={PRODUCT}
                columns={columns}
                getEPSList={getEPSList}
                setFormState={setFormState}
                style={customCss}
            />
            <FormDialog closeHandler={() => setFormState('HIDE')} saveHandler={onSave} ref={supplierForm}>
                {supplierForm && supplierForm.current ? (
                    <Form {...formItemLayout}>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Hotel ID" hasFeedback>
                                    {getFieldDecorator('hotel_id', {
                                        rules: [
                                            {
                                                type: 'string'
                                            },
                                            {
                                                required: false,
                                                message: ' Please enter Hotel ID!'
                                            }
                                        ]
                                    })(<Input disabled />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Hotel name" hasFeedback>
                                    {getFieldDecorator('hotel_name', {
                                        rules: [
                                            {
                                                type: 'string'
                                            },
                                            {
                                                required: false,
                                                message: ' Please enter Hotel name!'
                                            }
                                        ]
                                    })(<Input disabled />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Region" hasFeedback>
                                    {getFieldDecorator('region_name', {
                                        rules: [
                                            {
                                                type: 'string'
                                            },
                                            {
                                                required: false,
                                                message: ' Please enter Region!'
                                            }
                                        ]
                                    })(<Input disabled />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Tax & Fee" hasFeedback>
                                    {getFieldDecorator('tax_and_fee', {
                                        rules: [
                                            {
                                                type: 'string'
                                            },
                                            {
                                                required: true,
                                                message: ' Please enter Tax & Fee!'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12} style={{ fontSize: 14, marginBottom: 5, fontWeight: 600 }}>
                                Mandatory tax:
                            </Col>
                        </Row>
                        <Row gutter={16} style={{ marginBottom: 10 }}>
                            <Col md={12}>Request Currency:</Col>
                            <Col md={12}>Billable Currency:</Col>
                        </Row>
                        <Row gutter={16} justify="space-around">
                            <Col md={12}>
                                <Form.Item label="Value" hasFeedback>
                                    {getFieldDecorator('mandatory_tax.request_currency.value', {
                                        rules: [
                                            {
                                                type: 'string'
                                            },
                                            {
                                                required: true,
                                                message: ' Please enter value!'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                                <Form.Item label="Currency" hasFeedback>
                                    {getFieldDecorator('mandatory_tax.request_currency.currency', {
                                        rules: [
                                            {
                                                type: 'string'
                                            },
                                            {
                                                required: true,
                                                message: ' Please enter currency!'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Value" hasFeedback>
                                    {getFieldDecorator('mandatory_tax.billable_currency.value', {
                                        rules: [
                                            {
                                                type: 'string'
                                            },
                                            {
                                                required: true,
                                                message: ' Please enter value!'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                                <Form.Item label="Currency" hasFeedback>
                                    {getFieldDecorator('mandatory_tax.billable_currency.currency', {
                                        rules: [
                                            {
                                                type: 'string'
                                            },
                                            {
                                                required: true,
                                                message: ' Please enter currency!'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                ) : null}
            </FormDialog>
        </Card>
    )
}

export default Form.create({ name: 'taxes-management' })(HotelTaxes)
