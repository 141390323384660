import { useEffect, useMemo, useState } from 'react'

export default function useLocalStorage({ key, defaultValue, ...props }) {
    const options = useMemo(
        () => ({
            parser: JSON.parse,
            serializer: JSON.stringify,
            sync: true,
            ...props.options
        }),
        [props.options]
    )

    const [value, setValue] = useState(() => {
        if (typeof window === 'undefined') return defaultValue

        const rawValue = window.localStorage.getItem(key)
        let value

        try {
            value = options.parser(rawValue)
        } catch {
            value = defaultValue
        }

        return value
    })

    useEffect(() => {
        if (typeof window === 'undefined') return

        if (value === undefined) {
            window.localStorage.removeItem(key)
        } else {
            const rawValue = options.serializer(value)

            window.localStorage.setItem(key, rawValue)
        }
    }, [value])

    return [value, setValue]
}
