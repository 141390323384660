import React, { useCallback } from 'react'
import { Tag } from 'antd'
import { BOOKING_STATUS_COLORS } from 'constant'
import { filterTableV2 } from '@/search'
import BookingForm from './booking-form'
import BookingChart from './chart'
import ManageBookings from 'components/Booking/index'

export default function HotelBookings() {
    const columns = [
        {
            key: 'client_ref',
            dataIndex: 'client_ref',
            title: 'Client Reference',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.id - b.id,
            openLink: true,
            ellipsis: true,
            required: true
        },
        {
            key: 'status',
            dataIndex: 'status',
            title: 'Status',
            className: 'text-center',
            render: (_, record) => (
                <Tag color={BOOKING_STATUS_COLORS.HOTEL[record.status] || 'gray'}>{record.status}</Tag>
            )
        },
        {
            key: 'booking_id',
            dataIndex: 'booking_id',
            title: 'Booking Id',
            ellipsis: true
        },
        {
            key: 'confirmation_number',
            dataIndex: 'confirmation_number',
            title: 'Confirmation Number',
            ellipsis: true
        },
        {
            key: 'hotel_name',
            dataIndex: 'hotel_name',
            title: 'Hotel Name',
            ellipsis: true
        },
        {
            key: 'booking_date',
            dataIndex: 'booking_date',
            title: 'Booking Date'
        },
        {
            key: 'checkin',
            dataIndex: 'checkin',
            title: 'Checkin'
        },
        {
            key: 'checkout',
            dataIndex: 'checkout',
            title: 'Checkout'
        },
        {
            key: 'amount',
            dataIndex: 'amount',
            title: 'Amount',
            className: 'text-right',
            render: (_, record) => <span>{`${record.currency} ${(record.amount || 0.0).toFixed(2)}`}</span>
        }
    ]

    const onFilterQuery = useCallback(
        (query, bookings) => filterTableV2(query, bookings, ['confirmation_number', 'client_ref', 'booking_id']),
        []
    )

    return (
        <ManageBookings
            product="hotel"
            resource="booking-hotel"
            antdColumns={columns}
            onFilterQuery={onFilterQuery}
            BookingForm={BookingForm}
            BookingChart={BookingChart}
        />
    )
}
