function isValidObject(str) {
    if (str === '' || str === undefined) {
        return null
    }
    let obj
    try {
        obj = JSON.parse(str)
    } catch (error) {
        obj = undefined
    }
    return obj
}

function safeObjectToString(obj) {
    if (obj && typeof obj === 'object') return JSON.stringify(obj)
    return obj
}

function getChangeFields(newFieldsValue, oldFieldsValue, ignoreFields = []) {
    const fields = {}
    Object.keys(newFieldsValue).forEach(field => {
        if (!ignoreFields.includes(field) && newFieldsValue[field] !== oldFieldsValue[field]) {
            fields[field] = newFieldsValue[field]
        }

        if (ignoreFields.includes(field)) {
            fields[field] = newFieldsValue[field]
        }
    })

    return fields
}

function isObject(str) {
    if (!str) return false

    try {
        JSON.parse(str)
        return true
    } catch {
        return false
    }
}

export { isObject, isValidObject, safeObjectToString, getChangeFields }
