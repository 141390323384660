import { createStore } from '@'

export const $regions = createStore('mapping:regions')
export const $hotelMappings = createStore('mapping:hotelMappings')
export const $hotelInfoMapping = createStore('mapping:hotelInfoMapping')
export const $hotelMappingFrom = createStore('mapping:hotelMappingFrom')
export const $hotelSupplierDestinationMapping = createStore('mapping:hotelSupplierDestinationMapping', {})
export const $hotelRegionInfo = createStore('mapping:hotelRegionInfo', [])
export const $epsRegionInfo = createStore('mapping:epsRegionInfo', [])

export const $amenitiesGoQuo = createStore('mapping:amenitiesGoQuo')
export const $amenitiesUnmap = createStore('mapping:amenitiesUnmap')
export const $amenitiesType = createStore('mapping:amenitiesType')
export const $amenitiesProvider = createStore('mapping:amenitiesProvider')
