import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Modal } from 'antd'
import * as services from 'services'
import { usePermission } from 'hooks'
import Suppliers from './Suppliers'
import Logs from './Logs'
import ExportLayout from './exports'
import { getChangeFields } from '@/common'

export const ManageSuppliersContext = React.createContext({})

export default function ManageSuppliers({ product, resource, children }) {
    const [formState, setFormState] = useState({ action: 'hide' })
    const [loading, setLoading] = useState(false)
    const [suppliers, setSuppliers] = useState([])
    const [selectedSupplier, setSelectedSupplier] = useState(null)
    const [columns, setColumns] = useState([])
    const [isOpenImport, setIsOpenImport] = useState(false)
    const [isOpenExport, setIsOpenExport] = useState({ open: false })
    const [selectedSuppliers, setSelectedSuppliers] = useState([])
    const [copiedSupplier, setCopiedSupplier] = useState(null)

    const { canCreate, canEdit, canExport, canImport } = usePermission({ resource })

    const onOpenCreateForm = () => {
        setFormState({ action: 'create' })
    }

    const onOpenEditForm = record => {
        setFormState({ action: 'edit', payload: record })
    }

    const onHideForm = () => {
        setFormState({ action: 'hide' })
    }

    const onSelectSupplier = record => {
        setSelectedSupplier(record)
    }

    const onCopySupplier = record => {
        setCopiedSupplier(record)
    }

    const onClearSelectedSupplier = () => {
        setSelectedSupplier(null)
    }

    const onRegisterColumns = columns => {
        setColumns(columns)
    }

    const onReload = () => {
        dispatchEvent('load')
    }

    const onUpdate = useCallback((record, payload) => {
        setSuppliers(prev =>
            prev.map(supplier =>
                supplier.id !== record.id
                    ? supplier
                    : {
                          ...supplier,
                          ...payload
                      }
            )
        )
    }, [])

    const onRemove = record => {
        setSuppliers(prev => prev.filter(s => s.id !== record.id))
    }

    const onSave = async payload => {
        // console.log('Fields: ', data)
        const data = formState.action === 'edit' ? getChangeFields(payload, formState.payload) : payload

        const promise =
            formState.action === 'create'
                ? services.suppliers.createSupplier({ product, data })
                : services.suppliers.updateSupplier({
                      product,
                      supplierId: formState.payload.id,
                      data
                  })

        return promise
    }

    const onFetch = useCallback(
        () =>
            services.suppliers
                .getSuppliers({
                    product
                })
                .then(suppliers => setSuppliers(suppliers)),
        [product]
    )

    const onChangeStatus = useCallback(
        async record =>
            services.suppliers
                .adjustConfig({
                    product,
                    supplierId: record.id,
                    data: {
                        active: !record.active
                    }
                })
                .then(() =>
                    onUpdate(record, {
                        active: !record.active
                    })
                ),
        [product, onUpdate]
    )

    const onChangeMockBooking = useCallback(
        async record =>
            services.suppliers
                .adjustConfig({
                    product,
                    supplierId: record.id,
                    data: {
                        mock_booking: !record.mock_booking
                    }
                })
                .then(() =>
                    onUpdate(record, {
                        mock_booking: !record.mock_booking
                    })
                ),
        [product, onUpdate]
    )

    const onFetchLog = useCallback(
        () =>
            services.logs.getLogs({
                product,
                resource: 'suppliers',
                objectId: selectedSupplier.id
            }),
        [product, selectedSupplier]
    )

    const handlers = useMemo(
        () => ({
            load: onFetch,
            status: onChangeStatus,
            mock_booking: onChangeMockBooking
        }),
        [onFetch, onChangeStatus, onChangeMockBooking]
    )

    const dispatchEvent = useCallback(
        async (event, record) => {
            setLoading(true)

            const promise = handlers[event](record).finally(() => setLoading(false))

            return promise
        },
        [handlers]
    )

    const onOpenImport = () => {
        setIsOpenImport(true)
    }

    const onCloseImport = () => {
        setIsOpenImport(false)
    }

    const onOpenExport = type => {
        setIsOpenExport({ open: true, type })
    }

    const onCloseExport = () => {
        setIsOpenExport({ open: false })
    }

    const onChangeSelectedSuppliers = suppliers => {
        setSelectedSuppliers(suppliers)
    }

    const onBulkSave = () => {}

    useEffect(() => {
        dispatchEvent('load')
    }, [dispatchEvent])

    return (
        <React.Fragment>
            <ManageSuppliersContext.Provider
                value={{
                    product,
                    resource,
                    canCreate,
                    canEdit,
                    canExport,
                    canImport,
                    formState,
                    suppliers,
                    selectedSupplier,
                    copiedSupplier,
                    selectedSuppliers,
                    columns,
                    dispatchEvent,
                    onOpenCreateForm,
                    onOpenEditForm,
                    onHideForm,
                    onSelectSupplier,
                    onClearSelectedSupplier,
                    onRegisterColumns,
                    onSave,
                    onReload,
                    onUpdate,
                    onRemove,
                    isOpenImport,
                    onOpenImport,
                    onCloseImport,
                    isOpenExport,
                    onOpenExport,
                    onCloseExport,
                    onChangeSelectedSuppliers,
                    onBulkSave,
                    onCopySupplier
                }}>
                <Suppliers loading={loading} />

                {children}

                {!!selectedSupplier && (
                    <Modal
                        width={840}
                        title={`Logs of supplier: ${selectedSupplier.code}`}
                        open={!!selectedSupplier}
                        onCancel={onClearSelectedSupplier}>
                        <Logs onFetchLog={onFetchLog} />
                    </Modal>
                )}

                {canExport && (
                    <ExportLayout
                        resource={resource}
                        data={isOpenExport.type === 'ALL' ? suppliers : selectedSuppliers}
                        isOpen={isOpenExport.open}
                        onCloseExport={onCloseExport}
                    />
                )}
            </ManageSuppliersContext.Provider>
        </React.Fragment>
    )
}
