import React from 'react'
import { IconTaxes } from '../@icons/index.js'
import Taxes from '../views/hotel/taxes'

const whilelistDomain = (process.env.WHITELIST_DOMAIN || '').split(',')
const ThemeRoutes = [
    {
        path: '/iframe/taxes-management/:siteId',
        name: 'Taxes Management',
        icon: (
            <span role="img" aria-label="api" className="anticon anticon-api">
                <IconTaxes />
            </span>
        ),
        is_frame: true,
        component: Taxes,
        resource: 'hotel-taxes-management',
        perform: 'view',
        accessable: [...whilelistDomain]
    }
]

export default ThemeRoutes
