import { filterTable } from '@/search'
import { EditOutlined } from '@ant-design/icons'
import { Button, Col, Input, Row, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { Can, check, Say } from './can'

function EPSSupplierList({ EPSList, columns, product, setFormState, style }) {
    const [initialEPSList, setInitialEPSList] = useState([])
    const [EPSSupplierList, setEPSSupplierList] = useState([])

    useEffect(() => {
        setInitialEPSList(EPSList)
        setEPSSupplierList(EPSList)
    }, [EPSList])

    const actionCell = (cell, row) => {
        return (
            <div>
                <Can resource={`${product}-taxes-management`} perform={'edit'}>
                    <Say yes>
                        <Button
                            ghost
                            type="primary"
                            icon={<EditOutlined />}
                            onClick={() => setFormState('UPDATE', row)}
                        />
                    </Say>
                </Can>
            </div>
        )
    }

    const onSearch = e => {
        const searchInput = e.target.value
        const EPSList = filterTable(searchInput, initialEPSList, columns)
        setEPSSupplierList(EPSList)
    }

    const tableColumns = [...columns]

    if (check(`${product}-taxes-management`, 'edit')) {
        tableColumns.push({
            key: 'dummy',
            dataIndex: 'dummy',
            title: 'Action',
            className: 'text-center',
            render: actionCell
        })
    }

    return (
        <div style={style}>
            <Row>
                <Col md={16}></Col>
                <Col md={8}>
                    <Input placeholder="Search" onChange={onSearch} />
                </Col>
            </Row>
            <br />
            <Table dataSource={EPSSupplierList} columns={tableColumns} bordered rowKey="id" />
        </div>
    )
}

export default EPSSupplierList
