import React, { useCallback } from 'react'
import { Tag } from 'antd'
import { BOOKING_STATUS_COLORS } from 'constant'
import { filterTableV2 } from '@/search'
import BookingForm from './booking-form'
import BookingChart from './chart'
import ManageBookings from 'components/Booking/index'

export default function FlightBookings() {
    const columns = [
        {
            key: 'booking_reference',
            dataIndex: 'booking_reference',
            title: 'Client Reference',
            defaultSortOrder: 'descend',
            openLink: true,
            ellipsis: true,
            required: true
        },
        {
            key: 'status',
            dataIndex: 'status',
            title: 'Status',
            className: 'text-center',
            render: (_, record) => (
                <Tag color={BOOKING_STATUS_COLORS.FLIGHT[record.status] || 'gray'}>{record.status}</Tag>
            )
        },
        {
            key: 'pnr',
            dataIndex: 'pnr',
            title: 'PNR'
        },
        {
            key: 'airline_code',
            dataIndex: 'airline_code',
            title: 'Airline'
        },
        {
            key: 'supplier_code',
            dataIndex: 'supplier_code',
            title: 'Supplier'
        },
        {
            key: 'passenger',
            dataIndex: 'passenger',
            title: 'Passenger',
            ellipsis: true,
            render: (_, record) => <span>{`${record.first_name} ${record.last_name}`}</span>
        },
        {
            key: 'route',
            dataIndex: 'route',
            title: 'Route',
            ellipsis: true,
            render: (_, record) => <span>{`${record.departure_airport} - ${record.arrival_airport}`}</span>
        },
        {
            key: 'booking_date',
            dataIndex: 'booking_date',
            title: 'Booking Date'
        },
        {
            key: 'departure_date',
            dataIndex: 'departure_date',
            title: 'Departure'
        },
        {
            key: 'arrival_date',
            dataIndex: 'arrival_date',
            title: 'Return'
        },
        {
            key: 'amount',
            dataIndex: 'amount',
            title: 'Amount',
            className: 'text-right',
            render: (_, record) => <span>{`${record.currency_code} ${(record.amount || 0.0).toFixed(2)}`}</span>
        }
    ]

    const onFilterQuery = useCallback(
        (query, bookings) => filterTableV2(query, bookings, ['booking_reference', 'pnr']),
        []
    )

    return (
        <ManageBookings
            product="flight"
            resource="booking-flight"
            antdColumns={columns}
            onFilterQuery={onFilterQuery}
            BookingForm={BookingForm}
            BookingChart={BookingChart}
        />
    )
}
