import React from 'react'
import { Card, Table } from 'antd'
// import _ from 'lodash'
import container from './container'

const columns = [
    {
        key: 'region_id',
        dataIndex: 'region_id',
        title: 'Region ID'
    },
    {
        key: 'region_name',
        dataIndex: 'region_name',
        title: 'Region Name'
    },
    {
        key: 'country_code',
        dataIndex: 'country_code',
        title: 'Country Code'
    }
]

const RegionInfo = props => {
    let { lstHotelRegionInfo } = props

    return (
        <div>
            <Card>
                <Table
                    bordered
                    rowKey={(item, idx) => idx}
                    dataSource={lstHotelRegionInfo}
                    columns={columns}
                    pagination={{ pageSize: 5, defaultCurrent: 1 }}
                />
            </Card>
        </div>
    )
}

export default container(RegionInfo)
