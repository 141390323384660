import React, { useState } from 'react'
import { Modal, Select } from 'antd'

export default function AddBookingColumns({ open, columns, onAddColumns, onClose }) {
    const [columnIds, setColumnIds] = useState([])

    const onOk = () => {
        onAddColumns(columnIds)
    }

    const onFilter = (input, option) => RegExp('.*' + input, 'gi').test(option.label)

    return (
        <Modal
            centered
            destroyOnClose
            title="Add Column"
            open={open}
            onCancel={onClose}
            onOk={onOk}
            okButtonProps={{
                disabled: !columnIds.length
            }}>
            <p className="mb-1">Select columns you want to show in the screen</p>
            <Select
                className="w-100"
                placeholder="Choose columns"
                mode="multiple"
                showSearch
                value={columnIds}
                onChange={columnIds => setColumnIds(columnIds)}
                filterOption={onFilter}
                maxTagCount={5}
                maxTagPlaceholder={omittedValues => `+ ${omittedValues.length} more`}
                options={columns.map(column => ({
                    label: column.title,
                    value: column.id
                }))}
            />
        </Modal>
    )
}
