import React, { useState } from 'react'
import { Drawer, Button, Row, Col } from 'antd'
import { SaveOutlined, ClearOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { FilterState, FilterCreatedBy, FilterSupplier } from './Filters'

export default function BookingFilter({
    product,
    isOpen,
    appliedFilter,
    onToggleFilter,
    onApplyFilter,
    onResetFilter
}) {
    const [filter, setFilter] = useState(appliedFilter)

    const onChangeFilter = (key, value) => {
        setFilter(prev => ({
            ...prev,
            [key]: value
        }))
    }

    return (
        <Drawer
            title="Booking Filter"
            placement="right"
            destroyOnClose
            size="large"
            open={isOpen}
            onClose={onToggleFilter}
            extra={
                <div className="d-flex gap-2 align-items-center">
                    <Button type="info" onClick={onToggleFilter}>
                        Close
                    </Button>
                    <Button
                        disabled={!_.isEmpty(appliedFilter)}
                        type="danger"
                        icon={<ClearOutlined />}
                        onClick={onResetFilter}>
                        Reset
                    </Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                        icon={<SaveOutlined />}
                        onClick={() => onApplyFilter(filter)}>
                        Apply
                    </Button>
                </div>
            }>
            <Row gutter={[8, 16]}>
                <Col span={24}>
                    <FilterState
                        product={product}
                        selectedStates={_.get(filter, 'states', [])}
                        onChangeFilter={value => onChangeFilter('states', value)}
                    />
                </Col>
                <Col span={24}>
                    <FilterCreatedBy
                        product={product}
                        selectedUsers={_.get(filter, 'ins_user_id', [])}
                        onChangeFilter={value => onChangeFilter('ins_user_id', value)}
                    />
                </Col>
                <Col span={24}>
                    <FilterSupplier
                        product={product}
                        selectedSuppliers={_.get(filter, 'supplier_id', [])}
                        onChangeFilter={value => onChangeFilter('supplier_id', value)}
                    />
                </Col>
            </Row>
        </Drawer>
    )
}
