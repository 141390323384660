import React from 'react'
import { Row, Col } from 'antd'
import TotalBooking from '../../components/charts/total-booking'

class Chart extends React.Component {
    render() {
        return (
            <div>
                <Row>
                    <Col>
                        <TotalBooking by="supplier" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TotalBooking by="user" />
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Chart
