import React, { useEffect, useContext } from 'react'
import { Button, Switch, Modal, Tooltip, Row, Col } from 'antd'
import { UserDeleteOutlined } from '@ant-design/icons'
import ManageUserSuppliers, { ManageUserSuppliersContext } from 'components/ManageUserSuppliers'

export default function CarUserSuppliers() {
    return (
        <ManageUserSuppliers product="car" resource="car-user">
            <InternalCarUserSuppliers />
        </ManageUserSuppliers>
    )
}

function InternalCarUserSuppliers() {
    const { canEdit, onRegisterColumns, dispatchEvent } = useContext(ManageUserSuppliersContext)

    const columns = [
        {
            key: 'code',
            dataIndex: 'code',
            title: 'Code',
            defaultSortOrder: 'ascend'
        },
        {
            key: 'name',
            dataIndex: 'name',
            title: 'Name',
            ellipsis: true
        },
        {
            key: 'markup_percent',
            dataIndex: 'markup_percent',
            title: 'Markup (%)',
            editable: true,
            inputType: 'number',
            width: '10%'
        },
        {
            key: 'inactive',
            dataIndex: 'inactive',
            title: 'Status',
            render: (_, record) => {
                return (
                    <Switch
                        disabled={!canEdit}
                        checked={!record.inactive}
                        onClick={() => onShowChangeStatusConfirm(record)}
                    />
                )
            }
        },
        {
            key: 'action',
            title: 'Action',
            render: (_, record) => {
                return (
                    <Row>
                        <Col>
                            <Tooltip title="Remove">
                                <Button
                                    type="danger"
                                    disabled={!canEdit}
                                    icon={<UserDeleteOutlined />}
                                    onClick={() => onShowRemoveConfirm(record)}
                                />
                            </Tooltip>
                        </Col>
                    </Row>
                )
            }
        }
    ]

    const onShowChangeStatusConfirm = record => {
        Modal.confirm({
            maskClosable: true,
            title: `Are you sure to turn ${record.inactive ? 'on' : 'off'} ${record.name}`,
            onOk: async () => {
                dispatchEvent('status', record)
            }
        })
    }

    const onShowRemoveConfirm = record => {
        Modal.confirm({
            maskClosable: true,
            title: `Are you sure to remove ${record.name}`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                dispatchEvent('remove', record)
            }
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => onRegisterColumns(columns), [])

    return null
}
