import React from 'react'
import { Table } from 'antd'
import { TeamOutlined } from '@ant-design/icons'

export default function BookingTravellers({ paxes }) {
    const columns = [
        {
            key: 'title',
            dataIndex: 'title',
            title: 'Title',
            className: 'text-center',
            ellipsis: true
        },
        {
            key: 'first_name',
            dataIndex: 'first_name',
            title: 'First Name',
            className: 'text-center',
            ellipsis: true
        },
        {
            key: 'last_name',
            dataIndex: 'last_name',
            title: 'Last Name',
            className: 'text-center',
            ellipsis: true
        },
        {
            key: 'age',
            dataIndex: 'age',
            title: 'Age',
            className: 'text-center',
            ellipsis: true
        },
        {
            key: 'type',
            dataIndex: 'type',
            title: 'Type',
            className: 'text-center',
            ellipsis: true
        },
        {
            key: 'nationality',
            dataIndex: 'nationality',
            title: 'Nationality',
            className: 'text-center',
            ellipsis: true
        }
    ]

    return (
        <Table
            bordered
            size="middle"
            pagination={false}
            rowKey={r => r.id}
            title={() => (
                <div className="d-flex gap-2 align-items-center">
                    <TeamOutlined />
                    <span className="fs-6 fw-bold">Passengers</span>
                </div>
            )}
            columns={columns}
            dataSource={paxes}
        />
    )
}
