import React, { useContext, useState } from 'react'
import { Form, Input, InputNumber } from 'antd'
import { RowContext } from './Row'

export default function Cell({ record, column, type, editable, children, handleSave, ...restProps }) {
    const { form } = useContext(RowContext)
    const [editing, setEditing] = useState(false)

    const onSave = () => {
        form.validateFields()
            .then(fieldsValue => handleSave(record, fieldsValue))
            .finally(() => {
                setEditing(false)
            })
    }

    let childNode = children
    if (editable) {
        childNode = !editing ? (
            <div className="editable-cell-value" onClick={() => setEditing(true)}>
                {childNode}
            </div>
        ) : (
            <Form.Item className="m-0" name={column} initialValue={record[column]}>
                <CellInput type={type} onBlur={onSave} onPressEnter={onSave} />
            </Form.Item>
        )
    }

    return <td {...restProps}>{childNode}</td>
}

export function CellInput({ type, ...props }) {
    return type === 'text' ? <Input autoFocus {...props} /> : <InputNumber autoFocus {...props} />
}
