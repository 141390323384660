export default [
    'GoQuo',
    'Accor',
    'Adonis',
    'Agoda',
    'Anixe',
    'APItude',
    'CNBooking',
    'CUG',
    'Darina',
    'DerbySoft',
    'Dida',
    'DOTW',
    'EAN',
    'EHUB',
    'EPS',
    'Fastpayhotels',
    'FIT',
    'Getaroom',
    'GIATA',
    'GTA',
    'Hilton',
    'HMS',
    'HotelsPro',
    'Infinite',
    'Innstant',
    'JacTravel',
    'Jalan',
    'Juniper',
    'MARJ',
    'Marriott',
    'MG',
    'MGJarvis',
    'Miki',
    'NTA',
    'PriceLine',
    'Qtech',
    'Quantum',
    'Restel',
    'RMS',
    'RoomsXML',
    'Rotana',
    'Seera',
    'Smyrooms',
    'Stuba',
    'TBO',
    'Traveloka',
    'Vibe',
    'Vinpearl',
    'Zumata'
]
