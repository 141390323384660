import { createAction } from '@'
import axios from 'axios'
import { $hotelMap } from '../stores'
import appConfig from '../../config'
const apiHotelInfo = `${appConfig.apiHotelInfo}`
export default createAction('GetHotelInfo', async values => {
    const hotelMap = $hotelMap.getState()
    if (hotelMap && hotelMap[values.goquo_id]) {
        return
    }

    const { data } = await axios({
        url: `${apiHotelInfo}/hotel?hotel_id=${values.goquo_id}`,
        method: 'GET'
    })

    if (!Object.keys(data).length) {
        return
    }

    $hotelMap.setState(s => ({ ...s, [values.goquo_id]: data }))
})
