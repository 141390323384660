import React, { useState, useMemo, useEffect } from 'react'
import { Drawer, List, Input, Button, Tooltip } from 'antd'
import { SearchOutlined, PlusOutlined, SaveOutlined, ClearOutlined } from '@ant-design/icons'
import { DndContext, PointerSensor, useSensors, useSensor } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable'
import _ from 'lodash'
import { useViews } from 'hooks'
import BookingColumn from './BookingColumn'
import AddBookingColumns from './AddBookingColumns'
import SaveViewBookingColumns from './SaveViewBookingColumns'

export default function BookingColumns({ isOpen, viewKey, editView, columns, onSyncViewColumns, onClose }) {
    const [query, setQuery] = useState('')
    const [selectedColumnIds, setSelectedColumnIds] = useState([])
    const [selectedColumns, setSelectedColumns] = useState([])
    const [isOpenAddColumns, setIsOpenAddColumns] = useState(false)
    const [isOpenSaveViewBookingColumns, setIsOpenSaveViewBookingColumns] = useState(false)

    const { loading, dispatchEvent } = useViews({ key: viewKey })

    useEffect(() => {
        if (isOpen) {
            const availableColumnIds = _.map(columns, c => c.id)
            setSelectedColumnIds(
                _.chain(editView)
                    .get('columns', columns)
                    .map(c => c.id)
                    .filter(columnId => availableColumnIds.includes(columnId))
                    .value()
            )
        }
    }, [isOpen, columns, editView])

    useEffect(() => {
        const selectedColumns = _.get(editView, 'columns', [])
        setSelectedColumns(
            _.chain(selectedColumnIds)
                .map(columnId => {
                    let column = _.find(selectedColumns, c => c.id === columnId)
                    if (!column) {
                        column = _.find(columns, c => c.id === columnId)
                    }

                    return column
                })
                .value()
        )
    }, [selectedColumnIds, columns, editView])

    const filterColumns = useMemo(() => _.filter(selectedColumns, c => RegExp('.*' + query, 'gi').test(c.title)), [
        selectedColumns,
        query
    ])

    const hiddenColumns = useMemo(() => _.filter(columns, c => !selectedColumnIds.includes(c.id)), [
        selectedColumnIds,
        columns
    ])

    const saveView = {
        ...editView,
        columns: selectedColumns
    }

    const onDebounceChangeQuery = _.debounce(value => setQuery(value), 200)

    const onRemoveColumn = columnId => {
        setSelectedColumnIds(prev => prev.filter(id => id !== columnId))
    }

    const onRemoveAllColumn = () => {
        console.log(selectedColumns)
        const requiredColumnIds = _.filter(selectedColumns, c => c.required).map(c => c.id)
        setSelectedColumnIds(requiredColumnIds)
    }

    const onAddColumns = columnIds => {
        setSelectedColumnIds(prev => [...prev, ...columnIds])
        setIsOpenAddColumns(false)
    }

    const onChangeColumnTitle = (columnId, title) => {
        setSelectedColumns(prev =>
            prev.map(column =>
                column.id !== columnId
                    ? column
                    : {
                          ...column,
                          title
                      }
            )
        )
    }

    const onCloseSaveViewBookingColumns = () => {
        setIsOpenSaveViewBookingColumns(false)
    }

    const onSave = payload => {
        dispatchEvent('save', payload)
            .then(() => onSyncViewColumns())
            .then(() => onCloseSaveViewBookingColumns())
    }

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 1
            }
        })
    )

    const onDragEnd = ({ active, over }) => {
        if (over && active.id !== over.id) {
            const activeIndex = _.findIndex(selectedColumns, f => f.index === active.id)
            const overIndex = _.findIndex(selectedColumns, c => c.index === over.id)
            const selectedColumnIds = _.map(selectedColumns, c => c.id)
            const columnIds = arrayMove(selectedColumnIds, activeIndex, overIndex)
            setSelectedColumnIds(columnIds)
        }
    }

    return (
        <React.Fragment>
            <Drawer
                title={!editView ? 'Create View Booking Columns' : `Edit View Booking Columns - ${editView.title}`}
                placement="right"
                size="large"
                open={isOpen}
                destroyOnClose
                onClose={onClose}>
                <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                    <SortableContext items={selectedColumns.map(c => c.index)} strategy={verticalListSortingStrategy}>
                        <List
                            className="overflow-auto"
                            rowKey={r => r.id}
                            loading={loading}
                            dataSource={filterColumns}
                            header={
                                <div className="d-flex gap-3 align-items-center">
                                    <Input
                                        className="w-100"
                                        size="middle"
                                        allowClear
                                        placeholder="Search"
                                        prefix={<SearchOutlined role="button" />}
                                        onChange={e => onDebounceChangeQuery(e.target.value)}
                                    />
                                    <div className="d-flex gap-2 flex-shrink-0">
                                        <Tooltip title="Add Columns">
                                            <Button
                                                disabled={!hiddenColumns.length}
                                                type="primary"
                                                icon={<PlusOutlined />}
                                                onClick={() => setIsOpenAddColumns(true)}
                                            />
                                        </Tooltip>
                                        <Tooltip title="Remove All">
                                            <Button
                                                disabled={!selectedColumnIds.length}
                                                type="danger"
                                                icon={<ClearOutlined />}
                                                onClick={onRemoveAllColumn}
                                            />
                                        </Tooltip>
                                        <Tooltip title="Save View">
                                            <Button
                                                icon={<SaveOutlined />}
                                                onClick={() => setIsOpenSaveViewBookingColumns(true)}
                                            />
                                        </Tooltip>
                                    </div>
                                </div>
                            }
                            renderItem={column => (
                                <BookingColumn
                                    column={column}
                                    onRemoveColumn={onRemoveColumn}
                                    onChangeColumnTitle={onChangeColumnTitle}
                                />
                            )}
                        />
                    </SortableContext>
                </DndContext>
            </Drawer>

            <AddBookingColumns
                open={isOpenAddColumns}
                columns={hiddenColumns}
                onAddColumns={onAddColumns}
                onClose={() => setIsOpenAddColumns(false)}
            />

            <SaveViewBookingColumns
                isOpen={isOpenSaveViewBookingColumns}
                isEdit={!_.isNull(editView)}
                loading={loading}
                view={saveView}
                onSave={onSave}
                onClose={onCloseSaveViewBookingColumns}
            />
        </React.Fragment>
    )
}
