import React, { useState } from 'react'
import { Modal, Select } from 'antd'

export default function AddSupplierModal({ isOpen, suppliers, onAddSupplier, onClose }) {
    const [supplierId, setSupplierId] = useState(-1)

    const onOk = () => {
        onAddSupplier(supplierId)
    }

    const onFilter = (input, option) => RegExp('.*' + input, 'gi').test(option.label)

    return (
        <Modal
            centered
            destroyOnClose
            title="Add Supplier"
            open={isOpen}
            onOk={onOk}
            onCancel={onClose}
            okButtonProps={{ disabled: supplierId === -1 }}>
            <p className="mb-1">Select supplier you want to add into this user</p>
            <Select
                className="w-100"
                showSearch
                options={suppliers}
                onChange={value => setSupplierId(Number.parseInt(value))}
                filterOption={onFilter}
            />
        </Modal>
    )
}
