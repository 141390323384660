import React, { useContext, useState } from 'react'
import { Tag, Button, Space, Tooltip, Row, Col } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import { arrayMove } from '@dnd-kit/sortable'
import _ from 'lodash'
import AntdEditTable from 'components/AntdEditTable'
import ViewExportForm from './ViewExportForm'
import { ExporterContext } from './Exporter'

export default function ReorderAndRenameFieldStep() {
    const {
        key,
        data,
        selectedFields,
        exportFields,
        onBack,
        onNext,
        onSetFieldLabel,
        onReorderSelectedField
    } = useContext(ExporterContext)

    const [isOpenViewExportFormModal, setIsOpenViewExportFormModal] = useState(false)

    const handleSave = (record, payload) => {
        onSetFieldLabel(record, payload)
    }

    const columns = [
        {
            key: 'sort',
            width: '10%',
            align: 'center'
        },
        {
            key: 'name',
            dataIndex: 'name',
            title: 'Column',
            align: 'center',
            ellipsis: true
        },
        {
            key: 'label',
            dataIndex: 'label',
            title: 'Header Name',
            align: 'center',
            ellipsis: true,
            editable: true,
            width: '27%'
        },
        {
            key: 'preview',
            title: 'Sample Data',
            ellipsis: true,
            render: (_, record) => {
                const isExceedTag = data.length > 5

                const tags = (
                    <Row gutter={[8, 8]}>
                        {data.slice(0, 5).map((item, index) => (
                            <Col key={index}>
                                <Tag color="blue">{item[record.name] || 'null'}</Tag>
                            </Col>
                        ))}
                        {isExceedTag && <Col key="__empty__">...</Col>}
                    </Row>
                )

                return !isExceedTag ? (
                    tags
                ) : (
                    <Tooltip
                        title={data.map(item => item[record.name] || 'null').join(',')}
                        overlayInnerStyle={{
                            width: '250px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>
                        {tags}
                    </Tooltip>
                )
            }
        }
    ]

    const onDragEnd = ({ active, over }) => {
        if (over && active.id !== over.id) {
            const activeIndex = _.findIndex(selectedFields, f => f.key === active.id)
            const overIndex = _.findIndex(selectedFields, f => f.key === over.id)
            const selectedFieldKeys = selectedFields.map(f => f.key)
            const fieldKeys = arrayMove(selectedFieldKeys, activeIndex, overIndex)
            onReorderSelectedField(fieldKeys)
        }
    }

    return (
        <div className="h-700 d-flex flex-column">
            <div className="my-2 d-flex justify-content-between align-items-center">
                <Button type="primary" icon={<SaveOutlined />} onClick={() => setIsOpenViewExportFormModal(true)}>
                    Create View
                </Button>
                <Space size="middle">
                    <Button className="mr-4" key="back" type="danger" onClick={onBack}>
                        Back
                    </Button>
                    <Button key="next" type="primary" onClick={onNext}>
                        Next
                    </Button>
                </Space>
            </div>
            <div className="flex-grow-1 overflow-auto">
                <AntdEditTable
                    draggable={true}
                    recordKey="key"
                    columns={columns}
                    dataSource={selectedFields}
                    handleSave={handleSave}
                    onDragEnd={onDragEnd}
                    pagination={false}
                />
            </div>
            <ViewExportForm
                isEdit={false}
                isOpen={isOpenViewExportFormModal}
                viewKey={key}
                view={{ fields: selectedFields }}
                exportFields={exportFields}
                onClose={() => setIsOpenViewExportFormModal(false)}
            />
        </div>
    )
}
