import axios from 'axios'
import _ from 'lodash'
import { notification } from 'antd'
import { isObject } from '@/common'

export function createAxiosInstance({ url }) {
    const instance = axios.create({
        baseURL: url
    })

    let headers = localStorage.getItem('headers')
    if (isObject(headers)) {
        headers = JSON.parse(headers)
        instance.interceptors.request.use(config => {
            config.headers = {
                ...config.headers,
                ...headers
            }

            return config
        })
    }

    instance.interceptors.response.use(
        response => response,
        error => {
            const message = _.get(error.response, 'data.message', 'Error!!! Some thing wrong')
            notification.error({
                message
            })

            return Promise.reject(error)
        }
    )

    return instance
}
