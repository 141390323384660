import React, { useEffect, useContext, useState } from 'react'
import { Tag, Form, Modal, Row, Switch, Col, AutoComplete, Select, Input, Button, Tooltip } from 'antd'
import { HistoryOutlined, EditOutlined, CopyOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { isObject, safeObjectToString } from '@/common'
import FormLayout from 'components/FormLayout'
import ManageSuppliers, { ManageSuppliersContext } from 'components/ManageSuppliers'

export default function HotelSuppliers() {
    return (
        <ManageSuppliers product="hotel" resource="hotel-supplier">
            <InternalHotelSuppliers />
        </ManageSuppliers>
    )
}

function InternalHotelSuppliers() {
    const { canEdit, onRegisterColumns, onOpenEditForm, onSelectSupplier, onCopySupplier, dispatchEvent } = useContext(
        ManageSuppliersContext
    )

    const onShowChangeMockBookingConfirm = record => {
        Modal.confirm({
            maskClosable: true,
            title: `Are you sure to turn ${record.mock_booking ? 'off' : 'on'} mock booking`,
            onOk: async () => {
                dispatchEvent('mock_booking', record)
            }
        })
    }

    const onShowChangeStatusConfirm = record => {
        Modal.confirm({
            maskClosable: true,
            title: `Are you sure to turn ${record.active ? 'off' : 'on'} ${record.name}`,
            onOk: async () => {
                dispatchEvent('status', record)
            }
        })
    }

    const columns = [
        {
            key: 'code',
            dataIndex: 'code',
            title: 'Code',
            defaultSortOder: 'ascend'
        },
        {
            key: 'source_id',
            dataIndex: 'source_id',
            title: 'Source'
        },
        {
            key: 'contract_id',
            dataIndex: 'contract_id',
            title: 'Contract'
        },
        {
            key: 'name',
            dataIndex: 'name',
            title: 'Name',
            width: '20%',
            ellipsis: true
        },
        {
            key: 'rate_type',
            dataIndex: 'rate_type',
            title: 'Rate Type',
            render: (_, cell) => {
                switch (cell.rate_type) {
                    case 'Package':
                        return <Tag color="green">{cell.rate_type}</Tag>
                    case 'Standalone':
                        return <Tag color="orange">{cell.rate_type}</Tag>
                    default:
                        return <Tag color="geekblue">{cell.rate_type}</Tag>
                }
            }
        },
        {
            key: 'mock_booking',
            dataIndex: 'mock_booking',
            title: 'Mock Booking',
            render: (_, record) => (
                <Switch
                    disabled={!canEdit}
                    checked={record.mock_booking}
                    onClick={() => onShowChangeMockBookingConfirm(record)}
                />
            )
        },
        {
            key: 'status',
            dataIndex: 'active',
            title: 'Active',
            render: (_, record) => (
                <Switch disabled={!canEdit} checked={record.active} onClick={() => onShowChangeStatusConfirm(record)} />
            )
        },
        {
            key: 'action',
            title: 'Action',
            render: (_, record) => {
                return (
                    <Row gutter={[8, 8]}>
                        <Col>
                            <Tooltip title="Edit">
                                <Button
                                    ghost
                                    type="primary"
                                    icon={<EditOutlined />}
                                    disabled={!canEdit}
                                    onClick={() => onOpenEditForm(record)}
                                />
                            </Tooltip>
                        </Col>
                        <Col>
                            <Tooltip title="Show Log">
                                <Button
                                    type="dashed"
                                    icon={<HistoryOutlined />}
                                    onClick={() => onSelectSupplier(record)}
                                />
                            </Tooltip>
                        </Col>
                        <Col>
                            <Tooltip title="Copy">
                                <Button icon={<CopyOutlined />} onClick={() => onCopySupplier(record)} />
                            </Tooltip>
                        </Col>
                    </Row>
                )
            }
        }
    ]

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => onRegisterColumns(columns), [])

    return (
        <React.Fragment>
            <HotelSupplierForm />
        </React.Fragment>
    )
}

function HotelSupplierForm() {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const { suppliers, formState, onHideForm, onSave, onReload } = useContext(ManageSuppliersContext)

    const fields = [
        'name',
        'code',
        'source_id',
        'contract_id',
        'username',
        'password',
        'email',
        'client_id',
        'api_key',
        'shared_secret',
        'url',
        'business_type',
        'rate_type',
        'default_currency',
        'custom_config'
    ]

    const onChangeSupplierCode = code => {
        const supplier = suppliers.find(s => s.code === code)
        const sourceId = supplier
            ? supplier.source_id
            : Math.random()
                  .toString(36)
                  .substring(2, 15)

        form.setFieldsValue({
            source_id: sourceId
        })
    }

    const onSubmit = () => {
        form.validateFields().then(fieldsValue => {
            setLoading(true)

            onSave(fieldsValue)
                .then(() => {
                    setLoading(false)
                    onHideForm()
                    onReload()
                })
                .catch(() => {
                    setLoading(false)
                })
        })
    }

    const onPaste = () => {}

    useEffect(() => {
        form.resetFields()

        if (formState.action === 'edit') {
            const fieldValues = _.pick(formState.payload, fields)
            fieldValues.custom_config = safeObjectToString(fieldValues.custom_config)
            form.setFieldsValue(fieldValues)
        }

        if (formState.action === 'create') {
            const maxContractId = _.chain(suppliers)
                .map(s => parseInt(s.contract_id))
                .max()
                .value()

            const nextContractId = maxContractId + 1
            form.setFieldsValue({
                contract_id: nextContractId.toString()
            })
        }
    }, [formState, suppliers])

    return (
        <FormLayout
            type="supplier"
            action={formState.action}
            open={formState.action === 'create' || formState.action === 'edit'}
            loading={loading}
            onCloseForm={onHideForm}
            onSubmit={onSubmit}
            onPaste={onPaste}>
            <Form form={form} colon={false} layout="vertical">
                <Row gutter={16}>
                    <Col md={12}>
                        <Form.Item
                            label="Code"
                            name="code"
                            validateTrigger="onBlur"
                            hasFeedback
                            required
                            rules={[
                                {
                                    required: true,
                                    message: 'Supplier code is required'
                                }
                            ]}>
                            <AutoComplete
                                showSearch
                                disabled={formState.action === 'edit'}
                                filterOption={(input, option) => RegExp('.*' + input, 'gi').test(option.label)}
                                options={_.chain(suppliers)
                                    .unionBy(s => s.code)
                                    .map(s => ({
                                        label: s.code,
                                        value: s.code
                                    }))
                                    .value()}
                                onChange={onChangeSupplierCode}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item
                            label="Name"
                            name="name"
                            validateTrigger="onBlur"
                            hasFeedback
                            required
                            rules={[
                                {
                                    required: true,
                                    message: 'Name is required'
                                }
                            ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col md={12}>
                        <Form.Item label="Source" name="source_id" hasFeedback required>
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label="Contract" name="contract_id" hasFeedback required>
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col md={12}>
                        <Form.Item
                            label="Username"
                            name="username"
                            validateTrigger="onBlur"
                            hasFeedback
                            required
                            rules={[
                                {
                                    required: true,
                                    message: 'Username is required'
                                }
                            ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item
                            label="Password"
                            name="password"
                            validateTrigger="onBlur"
                            hasFeedback
                            required
                            rules={[
                                {
                                    required: true,
                                    message: 'Password is required'
                                }
                            ]}>
                            <Input.Password />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col md={12}>
                        <Form.Item
                            label="Url"
                            name="url"
                            validateTrigger="onBlur"
                            hasFeedback
                            required
                            rules={[
                                {
                                    required: true,
                                    message: 'URL is required'
                                }
                            ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label="Default Currency" name="default_currency">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col md={12}>
                        <Form.Item label="Client Id" name="client_id">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item
                            label="Email"
                            name="email"
                            validateTrigger="onBlur"
                            hasFeedback
                            required
                            rules={[
                                {
                                    required: true,
                                    message: 'Email is required'
                                }
                            ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col md={12}>
                        <Form.Item label="Shared Secret" name="shared_secret">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label="API Key" name="api_key">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col md={12}>
                        <Form.Item
                            label="Rate Type"
                            name="rate_type"
                            validateTrigger="onBlur"
                            hasFeedback
                            required
                            rules={[
                                {
                                    required: true,
                                    message: 'Rate type is required!'
                                }
                            ]}>
                            <Select
                                showSearch
                                filterOption={(input, option) => RegExp('.*' + input, 'gi').test(option.value)}
                                options={[{ value: 'Combined' }, { value: 'Package' }, { value: 'Standalone' }]}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label="Business Type" name="business_type">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col md={12}>
                        <Form.Item
                            label="Custom Config"
                            name="custom_config"
                            validateTrigger="onBlur"
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (value && !isObject(value)) {
                                            return Promise.reject('Invalid object')
                                        }

                                        return Promise.resolve()
                                    }
                                }
                            ]}>
                            <Input.TextArea
                                rows={6}
                                onChange={e =>
                                    form.setFieldsValue({
                                        custom_config: !e.target.value ? null : e.target.value
                                    })
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </FormLayout>
    )
}
