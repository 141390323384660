import React, { useContext, useRef, useState } from 'react'
import { Table, Space, Button, Form, Input, Modal } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { CSVLink } from 'react-csv'
import _ from 'lodash'
import { ExporterContext } from './Exporter'

export default function PreviewStep() {
    const { data, selectedFields, onBack } = useContext(ExporterContext)

    const [isOpenEditFilenameModal, setIsOpenEditFilenameModal] = useState(false)
    const [form] = Form.useForm()
    const csvLinkRef = useRef(null)

    const dataDownload = _.chain(data)
        .map(item => {
            return _.chain(selectedFields)
                .map(field => [field.label, item[field.name]])
                .fromPairs()
                .value()
        })
        .value()

    const columns = selectedFields.map(field => ({
        key: field.name,
        dataIndex: field.name,
        title: field.label,
        align: 'center',
        ellipsis: true,
        width: 50
    }))

    const handleCloseEditFileNameModal = () => {
        setIsOpenEditFilenameModal(false)
    }

    const onFinish = values => {
        if (csvLinkRef.current) {
            csvLinkRef.current.link.setAttribute('download', `${values.filename}.csv`)
            csvLinkRef.current.link.click()
            handleCloseEditFileNameModal()
        }
    }

    return (
        <div className="h-700">
            <CSVLink ref={csvLinkRef} className="d-none" data={dataDownload} />
            <div className="my-2 d-flex justify-content-end align-items-center">
                <Space size="middle">
                    <Button className="mr-4" key="back" type="danger" onClick={onBack}>
                        Back
                    </Button>
                    <Button type="primary" icon={<DownloadOutlined />} onClick={() => setIsOpenEditFilenameModal(true)}>
                        Download
                    </Button>
                </Space>
            </div>
            <Table
                rowKey={r => r.id}
                bordered
                columns={columns}
                dataSource={data}
                pagination={true}
                scroll={{ x: 'max-content' }}
            />
            <Modal
                forceRender
                title="Edit Filename"
                open={isOpenEditFilenameModal}
                onCancel={handleCloseEditFileNameModal}
                okText="Ok"
                onOk={() => form.submit()}>
                <Form form={form} component={false} colon={false} onFinish={onFinish}>
                    <Form.Item
                        label="Filename"
                        name="filename"
                        initialValue="data"
                        required
                        validateTrigger="onBlur"
                        rules={[
                            {
                                required: true,
                                message: 'Filename is required'
                            }
                        ]}>
                        <Input placeholder="Enter your filename" />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}
