import React, { useState, useEffect, useMemo } from 'react'
import { Steps } from 'antd'
import _ from 'lodash'
import SelectFieldStep from './SelectFieldStep'
import ReorderAndRenameFieldStep from './ReorderAndRenameFieldStep'
import PreviewStep from './PreviewStep'

export const ExporterContext = React.createContext()

export default function Exporter({ resource, data, fields, children }) {
    const [step, setStep] = useState(0)
    const [exportFields, setExportFields] = useState([])
    const [selectedFieldKeys, setSelectedFieldKeys] = useState([])
    const [selectedFields, setSelectedFields] = useState([])

    const fieldGroups = useMemo(
        () => [
            {
                label: 'ALL',
                value: 'ALL',
                fields: exportFields
            }
        ],
        [exportFields]
    )

    useEffect(() => {
        setExportFields(
            _.chain(fields)
                .uniqBy(f => f.name)
                .map(field => ({
                    key: field.name,
                    label: field.name,
                    ...field
                }))
                .value()
        )
    }, [fields])

    useEffect(() => {
        setSelectedFields(prev =>
            _.chain(selectedFieldKeys)
                .map(fieldKey => {
                    const selectedField = _.find(prev, f => f.key === fieldKey)
                    if (!selectedField) {
                        const exportField = _.find(exportFields, f => f.key === fieldKey)
                        return exportField
                    }

                    return selectedField
                })
                .value()
        )
    }, [selectedFieldKeys, exportFields])

    const onSelectField = fieldKeys => {
        setSelectedFieldKeys(fieldKeys)
    }

    const onUnSelectField = fieldKeys => {
        setSelectedFieldKeys(prev => _.filter(prev, f => !fieldKeys.includes(f)))
    }

    const onReorderSelectedField = fieldKeys => {
        setSelectedFieldKeys(fieldKeys)
    }

    const onSetFieldLabel = (record, payload) => {
        setSelectedFields(prev => prev.map(field => (field.key !== record.key ? field : _.assign(field, payload))))
    }

    const onBack = () => {
        if (step > 0) {
            setStep(step - 1)
        }
    }

    const onNext = () => {
        if (step < steps.length - 1) {
            setStep(step + 1)
        }
    }

    const onStep = index => {
        if (index <= step) {
            setStep(index)
        }
    }

    const steps = [
        {
            index: 0,
            title: 'Select Field',
            body: <SelectFieldStep />
        },
        {
            index: 1,
            title: 'Reorder and Rename Field',
            body: <ReorderAndRenameFieldStep />
        },
        {
            index: 2,
            title: 'Preview',
            body: <PreviewStep />
        }
    ]

    return (
        <ExporterContext.Provider
            value={{
                resource,
                key: `views-${resource}-export`,
                data,
                step,
                exportFields,
                fieldGroups,
                selectedFields,
                selectedFieldKeys,
                onBack,
                onNext,
                onStep,
                onSelectField,
                onUnSelectField,
                onReorderSelectedField,
                onSetFieldLabel
            }}>
            <Steps size="small" current={step}>
                {steps.map(s => (
                    <Steps.Step
                        key={s.index}
                        style={{ cursor: 'pointer' }}
                        title={s.title}
                        onClick={() => onStep(s.index)}
                    />
                ))}
            </Steps>
            <div className="w-100 mt-3">{steps[step].body}</div>
            {children}
        </ExporterContext.Provider>
    )
}
