export default [
    'Agoda',
    'EPS',
    'MyTour',
    'TBO',
    'Marriott',
    'HMS',
    'APItude',
    'MGJarvis',
    'DOTW',
    'FIT',
    'Juniper',
    'Miki',
    'Traveloka'
]
