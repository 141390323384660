import React from 'react'
import { Form, Spin, Row, Col, Space, Select, Popover, Tooltip, DatePicker, Button, Input } from 'antd'
import { CalendarOutlined, SearchOutlined, DownloadOutlined } from '@ant-design/icons'
import moment from 'moment'
import { LOG_LOKI_APP_OPTIONS, LOG_LOKI_ENV_OPTIONS } from 'constant'

export default function LogForm({ loading, form, onSearch, onToggleZipFolderNameModal }) {
    const onFilterOption = (input, option) => RegExp('.*' + input, 'gi').test(option.label)

    const onSubmit = async () => {
        form.validateFields().then(fieldValues => {
            onSearch(fieldValues)
        })
    }

    return (
        <Spin spinning={loading}>
            <Form form={form} colon={false}>
                <Row gutter={[0, 8]}>
                    <Col sm={24} md={24} lg={24} xl={12}>
                        <Space className="d-flex justify-content-between justify-content-xl-start align-items-center">
                            <Tooltip title="App">
                                <Form.Item
                                    name="app"
                                    required
                                    hasFeedback
                                    validateTrigger="onBlur"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'App is required'
                                        }
                                    ]}>
                                    <Select
                                        style={{ width: 200 }}
                                        showSearch
                                        filterOption={onFilterOption}
                                        options={LOG_LOKI_APP_OPTIONS}
                                        placeholder="App"
                                    />
                                </Form.Item>
                            </Tooltip>
                            <Tooltip title="Env">
                                <Form.Item
                                    name="env"
                                    required
                                    hasFeedback
                                    validateTrigger="onBlur"
                                    rules={[{ required: true, message: 'Env is required' }]}>
                                    <Select
                                        style={{ width: 200 }}
                                        showSearch
                                        filterOption={onFilterOption}
                                        options={LOG_LOKI_ENV_OPTIONS}
                                        placeholder="Env"
                                    />
                                </Form.Item>
                            </Tooltip>
                            <Tooltip title="Calendar">
                                <Form.Item name="dates">
                                    <Popover
                                        trigger="hover"
                                        placement="bottom"
                                        content={
                                            <DatePicker.RangePicker
                                                showTime={false}
                                                format="YYYY-MM-DD"
                                                onChange={([from, to]) => {
                                                    form.setFieldValue('dates', [from, to])
                                                }}
                                                placement="bottomLeft"
                                                disabledDate={current => current > moment().endOf('day')}
                                                defaultValue={[moment(), moment()]}
                                                ranges={{
                                                    Today: [moment(), moment()],
                                                    'This Week': [moment().startOf('week'), moment().endOf('week')],
                                                    'Last Week': [
                                                        moment()
                                                            .startOf('week')
                                                            .subtract(7, 'days'),
                                                        moment()
                                                            .startOf('week')
                                                            .subtract(1, 'day')
                                                    ]
                                                }}
                                            />
                                        }>
                                        <Button icon={<CalendarOutlined />} />
                                    </Popover>
                                </Form.Item>
                            </Tooltip>
                        </Space>
                    </Col>
                    <Col sm={24} md={24} lg={24} xl={12}>
                        <Form.Item
                            name="request_id"
                            required
                            hasFeedback
                            validateTrigger="onBlur"
                            rules={[{ required: true, message: 'Request Id is required' }]}>
                            <Input
                                className="w-100"
                                allowClear
                                size="middle"
                                placeholder="Search"
                                prefix={<SearchOutlined />}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[0, 8]}>
                    <Space size="middle" className="d-flex justify-content-start align-items-center">
                        <Button type="primary" disabled={loading} icon={<SearchOutlined />} onClick={onSubmit}>
                            Search
                        </Button>
                        <Button type="info" icon={<DownloadOutlined />} onClick={onToggleZipFolderNameModal}>
                            Download
                        </Button>
                    </Space>
                </Row>
            </Form>
        </Spin>
    )
}
