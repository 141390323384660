import React, { useRef } from 'react'
import { Row, Col, Select, Typography } from 'antd'
import { BOOKING_STATUS_FILTER } from 'constant'

export default function FilterState({ product, selectedStates, onChangeFilter }) {
    const ref = useRef(null)

    console.log('States: ', selectedStates)

    const onClickTitle = () => {
        if (ref && ref.current) {
            ref.current.focus()
        }
    }

    const onFilterOption = (query, option) => RegExp('.*' + query, 'gi').test(option.label)

    return (
        <Row gutter={[8, 8]}>
            <Col span={8}>
                <Typography.Text strong ellipsis onClick={onClickTitle}>
                    Status:
                </Typography.Text>
            </Col>
            <Col span={16}>
                <Select
                    ref={ref}
                    className="w-100 select-overflow text-center"
                    placeholder="Choose Status"
                    showSearch
                    allowClear
                    mode="multiple"
                    value={selectedStates}
                    filterOption={onFilterOption}
                    onChange={onChangeFilter}
                    options={BOOKING_STATUS_FILTER[product].map(value => ({
                        value,
                        label: value
                    }))}
                />
            </Col>
        </Row>
    )
}
