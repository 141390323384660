import React, { useEffect, useState } from 'react'
import { Tag, Row, Col, Button, Modal, Space, Radio, Tabs, Tooltip, Table } from 'antd'
import { LeftCircleOutlined } from '@ant-design/icons'
import * as services from '../services'
import AirlinePreferences from 'components/AirlinePreferences'

function AirlinePreferencesInclude({ form, onChangeForm }) {
    return (
        <Tabs defaultActiveKey={'1'}>
            <Tabs.TabPane tab={'Airline'} key={'1'}>
                <AirlinePreferences
                    type={'airline'}
                    field={'airline_include'}
                    form={form}
                    onChangeForm={onChangeForm}
                />
            </Tabs.TabPane>
            <Tabs.TabPane tab={'Operating Carrier'} key={'2'}>
                <AirlinePreferences
                    type={'operating_carrier'}
                    field={'operating_carrier_include'}
                    form={form}
                    onChangeForm={onChangeForm}
                />
            </Tabs.TabPane>
        </Tabs>
    )
}

function AirlinePreferencesExclude({ form, onChangeForm }) {
    return (
        <Tabs defaultActiveKey={'1'}>
            <Tabs.TabPane tab={'Airline'} key={'1'}>
                <AirlinePreferences
                    type={'airline'}
                    field={'airline_exclude'}
                    form={form}
                    onChangeForm={onChangeForm}
                />
            </Tabs.TabPane>
        </Tabs>
    )
}

function AirlinePreferencesEdit({ form, onChangeForm }) {
    return (
        <React.Fragment>
            <Row>
                <Col flex={1}>
                    <Radio.Group
                        options={[
                            { label: 'Exclude', value: 'exclude' },
                            { label: 'Include', value: 'include' }
                        ]}
                        value={form.preference_type}
                        onChange={({ target: { value } }) => onChangeForm({ preference_type: value })}
                    />
                </Col>
            </Row>
            <Row className="my-1">
                <Col flex={1}>
                    {form.preference_type === 'exclude' ? (
                        <AirlinePreferencesExclude form={form} onChangeForm={onChangeForm} />
                    ) : (
                        <AirlinePreferencesInclude form={form} onChangeForm={onChangeForm} />
                    )}
                </Col>
            </Row>
        </React.Fragment>
    )
}

function AirlinePreferencesModalHeader({ step, onGotoEditStep }) {
    return step === 'preview' ? (
        <Space className="align-items-center">
            <Tooltip title="Back">
                <LeftCircleOutlined role="button" className="mr-1 fs-5" onClick={onGotoEditStep} />
            </Tooltip>
            <span> Airline Preferences - Preview</span>
        </Space>
    ) : (
        <p>Airline Preferences - Edit</p>
    )
}

function AirlinePreferencesModalFooter({ step, onClose, onClear, onSave, onGotoPreviewStep }) {
    return step === 'preview' ? (
        <Space>
            <Button key="Back" type="dashed" onClick={onClose}>
                Close
            </Button>
            <Button key="clear" type="danger" onClick={onClear}>
                Remove
            </Button>
            <Button key="save" type="primary" onClick={onSave}>
                Save
            </Button>
        </Space>
    ) : (
        <Space>
            <Button key="Back" type="dashed" onClick={onClose}>
                Close
            </Button>
            <Button key="Next" type="primary" onClick={onGotoPreviewStep}>
                Next
            </Button>
        </Space>
    )
}

function AirlinePreferencesPreview({ form }) {
    const [preferenceType, setPreferenceType] = useState(form.preference_type)

    const renderCell = (isAirline, field, record) => {
        if (!record[field].length) return null

        const isExceedTag = record[field].length > 5

        const tags = (
            <Row gutter={[8, 8]}>
                {record[field].map(code => (
                    <Col key={code}>
                        <Tag color={isAirline ? 'orange' : 'purple'}>{code}</Tag>
                    </Col>
                ))}
                {isExceedTag && (
                    <Col key="__empty__">
                        <Tag color={isAirline ? 'orange' : 'purple'}>...</Tag>
                    </Col>
                )}
            </Row>
        )

        return !isExceedTag ? (
            tags
        ) : (
            <Tooltip
                title={record[field].join(',')}
                overlayInnerStyle={{
                    width: '250px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}>
                {tags}
            </Tooltip>
        )
    }

    const columns =
        preferenceType === 'exclude'
            ? [
                  {
                      key: 'airline_exclude',
                      dataIndex: 'airline_exclude',
                      title: 'Airline',
                      width: '100%',
                      ellipsis: true,
                      render: (_, record) => renderCell(true, 'airline_exclude', record)
                  }
              ]
            : [
                  {
                      key: 'airline_include',
                      dataIndex: 'airline_include',
                      title: 'Airline',
                      width: '50%',
                      ellipsis: true,
                      render: (_, record) => renderCell(true, 'airline_include', record)
                  },
                  {
                      key: 'operating_carrier_include',
                      dataIndex: 'operating_carrier_include',
                      title: 'Operating Carrier',
                      width: '50%',
                      ellipsis: true,
                      render: (_, record) => renderCell(false, 'operating_carrier_include', record)
                  }
              ]

    return (
        <React.Fragment>
            <Row>
                <Col flex={1}>
                    <Radio.Group
                        options={[
                            { label: 'Exclude', value: 'exclude' },
                            { label: 'Include', value: 'include' }
                        ]}
                        value={preferenceType}
                        onChange={e => setPreferenceType(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="my-1">
                <Col flex={1}>
                    <Table rowKey={Math.random()} bordered columns={columns} dataSource={[form]} />
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default function AirlinePreferencesModal({ isOpen, product, userId, record, onClose, onUpdate }) {
    const [form, setForm] = useState({})
    const [step, setStep] = useState('edit')

    useEffect(() => {
        if (record) {
            setForm({
                preference_type: record.preference_type || 'exclude',
                airline_exclude: record.airline_exclude ? record.airline_exclude.split(',') : [],
                airline_include: record.airline_include ? record.airline_include.split(',') : [],
                operating_carrier_include: record.operating_carrier_include
                    ? record.operating_carrier_include.split(',')
                    : [],
                operating_carrier_exclude: record.operating_carrier_exclude
                    ? record.operating_carrier_exclude.split(',')
                    : []
            })
        }
    }, [record])

    const onClear = () => {
        const updatedFieldsValue = {
            preference_type: null,
            airline_include: null,
            airline_exclude: null,
            operating_carrier_include: null
        }

        services.suppliers
            .adjustConfigUserSupplier({
                product,
                userId,
                supplierId: record.id,
                data: updatedFieldsValue
            })
            .then(() => onUpdate(record, updatedFieldsValue))
            .finally(() => {
                onHide()
            })
    }

    const onSave = () => {
        const preferenceType = form.preference_type

        const payload = {
            preference_type: preferenceType
        }

        if (preferenceType === 'include') {
            payload.airline_include = form.airline_include.join(',')
            payload.operating_carrier_include = form.operating_carrier_include.join(',')
        } else if (preferenceType === 'exclude') {
            payload.airline_exclude = form.airline_exclude.join(',')
            // updatedFieldsValue.operating_carrier_exclude = form.operating_carrier_exclude.join(',')
        }

        services.suppliers
            .adjustConfigUserSupplier({
                product,
                userId,
                supplierId: record.id,
                data: payload
            })
            .then(() => onUpdate(record, payload))
            .finally(() => {
                onHide()
            })
    }

    const onChangeForm = payload => {
        setForm({ ...form, ...payload })
    }

    const onGotoPreviewStep = () => {
        setStep('preview')
    }

    const onGotoEditStep = () => {
        setStep('edit')
    }

    const onHide = () => {
        onClose()
    }

    return (
        <Modal
            destroyOnClose
            open={isOpen}
            title={<AirlinePreferencesModalHeader step={step} onGotoEditStep={onGotoEditStep} />}
            onCancel={onHide}
            footer={
                <AirlinePreferencesModalFooter
                    step={step}
                    onClose={onHide}
                    onClear={onClear}
                    onSave={onSave}
                    onGotoPreviewStep={onGotoPreviewStep}
                />
            }>
            {step === 'edit' ? (
                <AirlinePreferencesEdit form={form} onChangeForm={onChangeForm} />
            ) : (
                <AirlinePreferencesPreview form={form} />
            )}
        </Modal>
    )
}
